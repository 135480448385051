import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import FavoriteHeart from '../common/favorite-heart';
import LikeThumb from '../common/like-thumb';
import DislikeThumb from '../common/dislike-thumb';

import { userDataActions } from '../../actions/scene/userDataActions';

function SceneUserData(props){

    const { getUserData } = props;

    useEffect(()=>{
        getUserData(props.sceneId)
    },[]);
    
    const favoriteClick =() =>{
        if(props.favorite.enabled) props.removeFavoriteScene(props.sceneId);
        else props.addFavoriteScene(props.sceneId);
    }

    const likeClick =() =>{
        if(props.like.enabled) props.likeScene(props.sceneId, 'none',true);
        else props.likeScene(props.sceneId, 'like', false);
    }

    const dislikeClick =() =>{
        if(props.dislike.enabled) props.likeScene(props.sceneId, 'none', true);
        else props.likeScene(props.sceneId, 'dislike', false);
    }

    return <div className="d-flex">
        <FavoriteHeart fill={props.favorite.enabled} clickEvent={favoriteClick}/>
        <LikeThumb fill={props.like.enabled} clickEvent={likeClick}/>
        <DislikeThumb fill={props.dislike.enabled} clickEvent={dislikeClick}/>
    </div> ;
}

const mapStateToProps = (state) =>{
    return {
        favorite: state.scenes.userData.favorite,
        like: state.scenes.userData.like,
        dislike: state.scenes.userData.dislike
    }
  }

const mapDispatchToProps = (dispatch) =>{
    return{
        getUserData : (id) => dispatch(userDataActions.getUserData(id)),
        addFavoriteScene: (id)=>dispatch(userDataActions.addFavoriteScene(id)),
        removeFavoriteScene: (id)=>dispatch(userDataActions.removeFavoriteScene(id)),
        likeScene: (id, value, boolVal) => dispatch(userDataActions.addLikeScene(id, value, boolVal)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SceneUserData);
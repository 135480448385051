import axios from 'axios';
import constants from '../../const.js';

const SITE_REQ = "SITE_REQ";
const ALL_SITE_SUC = "ALL_SITE_SUC";
const ALL_MAINSITE_SUC = "ALL_MAINSITE_SUC";
const SITE_ERR = "SITE_ERR";

const site_REQ = () =>{
    return{
        type: SITE_REQ
    };
};
const allSite_SUC = (allSite) =>{
    return{
        type: ALL_SITE_SUC,
        allSite: allSite
    };
}
const allMainSite_SUC = (allMainSite) =>{
    return{
        type: ALL_MAINSITE_SUC,
        allMainSite: allMainSite
    };
}
const site_ERR = (error) => {
    return{
        type: SITE_ERR,
        error: error
    };
};
const getAllSite = () => {
    return dispatch =>{
        dispatch(site_REQ());
        return axios.get(`${constants.DATA_API.SITE_URL}`)
        .then(res =>dispatch(allSite_SUC(res.data)))
        .catch(error=> dispatch(site_ERR(error)))
    }
}

const getAllMainSite = () => {
    return dispatch =>{
        dispatch(site_REQ());
        return axios.get(`${constants.DATA1_API_URL}/scenes/main-sites`)
        .then(res =>dispatch(allMainSite_SUC(res.data)))
        .catch(error=> dispatch(site_ERR(error)))
    }
}


const insertSite = (name, shortName, mainSite) =>{
    return dispatch =>{
        dispatch(site_REQ());
        return axios.post(`${constants.DATA1_API_URL}/scenes/sites`, {
            name: name,
            shortName: shortName,
            mainSite: mainSite,
            description: ''
        })
        .catch(err=>dispatch(site_ERR(err)));
    };
};

const updateSite = (sceneId, siteId) =>{
    return dispatch =>{
        dispatch(site_REQ());
        return axios.post(`${constants.DATA_API.SCENE_URL}/${sceneId}/site/${siteId}`)
        .catch(err=>dispatch(site_ERR(err)));
    };
};

export const siteActions = {
    SITE_REQ,
    ALL_SITE_SUC,
    ALL_MAINSITE_SUC,
    SITE_ERR,
    getAllMainSite,
    getAllSite,
    insertSite,
    updateSite,
};

import React, { useState, useEffect } from "react";
import { Card, Col, Row, CardDeck, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";

import constants from "../../const";
import { helper } from "../common/helper";
import PlaylistCard from "../scenes/playlist-card.js";
import SceneCard from '../scenes/scene-card';

export default function ScenesGalleryViewer(props) {

  const [playlistsCount, setPlaylistsCount] = useState(0);


  useEffect(() => {
 
  }, []);

  var scenes = props.scenes;
  var sceneCardElement = [];
        var i = 0;
        if (Array.isArray(scenes)) {
            var colSize = 1;
            for (var col = 0; col < Math.ceil(scenes.length / colSize); col++) {
                var cardDeck = [];
                for (var row = 0; row < colSize; row++) {
                    if (i < scenes.length) {
                        if (scenes[i] !== null)
                            cardDeck.push(<SceneCard key={`${row}-${col}`} style={{ width: "200px" }} scene={scenes[i]} roles={props.perms} settings={props.settings} />);
                        i++;
                    }
                }
                sceneCardElement.push(<CardDeck key={col} style={{ padding: "10px" }}>{cardDeck}</CardDeck>);
            }
        }

  return (
    <Container fluid>
        <Row className="justify-content-center p-2">
            <h1>Scenes {props.scenes?.length}</h1>
        </Row>
        {console.log(props.scenes)}
       <Row className="justify-content-center">
        {sceneCardElement}
       </Row>
    </Container>
  );
}

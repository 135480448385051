import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Col, InputGroup, Modal, Button, Table } from 'react-bootstrap';

import { siteActions } from '../../actions/scene/siteActions';
import constants from '../../const';
import { helper } from '../common/helper.js'


function NewSite(props) {
    var isMobile = window.innerWidth <= constants.mobileSize;
    const [show, setShow] = useState(false);
    const [sites, setSites] = useState([]);
    const [mainSites, setMainSites] = useState([]);
    const [siteInfo, setSiteInfo] = useState({
        name: 'Title',
        shortName: 'a'
    });

    const [table, setTable] = useState(null);
    const [order, setOrder] = useState("asc");
    const [isVisible, setIsVisble] = useState(false);

    const refresh = () => {
        props.getAllMainSite().then((data) => {
            setMainSites(data.allMainSite)
        })
        
        props.getAllSite().then((data) => {
            setSites(data.allSite);
            var tab = <tbody>
                {data.allSite.map(site => (
                    <tr key={site.id}>
                        <td onClick={() => openModal(site)}>{site.id}</td>
                        <td onClick={() => openModal(site)}>{site.name}</td>
                        <td onClick={() => openModal(site)}>{site.shortName}</td>
                        <td onClick={() => openModal(site)}>{mainSites[site.mainSite]}</td>
                    </tr>
                ))}
            </tbody>
            setTable(tab)
        })
    }
    useEffect(() => {
       refresh()
        // eslint-disable-next-line
    }, []);

    const handleCancel = () => {
        setShow(false);
        setIsVisble(false)
    }

    const handleShow = () => {
        setShow(true)
    };

    const updateSite = () => {
        props.insertSite(siteInfo.name, siteInfo.shortName, siteInfo.mainSite)
        setIsVisble(false)
        refresh()
    }

    const openModal = (site) => {
        setSiteInfo(site)
        setIsVisble(true)

    }

    const sortTags = (key) => {
        var tmpArr = sites;
        if (order == "asc") {
            setOrder("desc")
        }
        else { setOrder("asc") }
        var arr = tmpArr.sort(helper.sortObjects(key, order));
        setSites(arr);
        var tab = <tbody>
            {arr.map(site => (
                <tr key={site.id}>
                    <td onClick={() => openModal(site)}>{site.id}</td>
                    <td onClick={() => openModal(site)}>{site.name}</td>
                    <td onClick={() => openModal(site)}>{site.shortName}</td>
                    <td onClick={() => openModal(site)}>{mainSites[site.mainSite]}</td>
                </tr>
            ))}
        </tbody>
        setTable(tab)
    }

    function getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
      }

    var mainSiteSelect = React.forwardRef(() => (
        <select className="custom-select custom-select-sm" value={mainSites[siteInfo.mainSite]} onChange={(e) => setSiteInfo({ ...siteInfo, mainSite: parseInt(e.target.value) })} data-live-search="true">
            <option defaultValue={"DEFAULT"}>Select Main Site</option>
            {Object.values(mainSites).map(ms => <option key={ms} value={getKeyByValue(mainSites, ms)}>{ms}</option>)}
        </select>));

    return <>
        <Button variant="info" size={`${isMobile ? 'sm' : 'md'}`} onClick={handleShow}>Site</Button>
        <Modal size="lg" show={show} onHide={handleCancel} style={{ backgroundColor: 'hsla(0, 0%, 0%, 0.9)' }}>
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>View Sites</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row>
                        <Form.Group as={Col} xs="3">
                            <Button className="mr-auto" variant="secondary" size={`${isMobile ? 'sm' : 'md'}`} onClick={()=>openModal({})}>Add Site</Button>
                        </Form.Group>
                        <Form.Group as={Col} xs="3">
                            <Button className="mr-auto" variant="secondary" size={`${isMobile ? 'sm' : 'md'}`} onClick={refresh}>Refresh</Button>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th onClick={() => sortTags("id")}>#</th>
                                    <th onClick={() => sortTags("name")}>Name</th>
                                    <th onClick={() => sortTags("shortName")}>Short Name</th>
                                    <th onClick={() => sortTags("mainSite")}>Main Site</th>
                                </tr>
                            </thead>
                            {table}
                        </Table>

                    </Form.Row>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={handleCancel}>Close</Button>
                </Modal.Footer>
            </Form>
            <Modal size="lg" show={show && isVisible} onHide={() => setIsVisble(false)} style={{ backgroundColor: 'hsla(0, 0%, 0%, 0.9)' }}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Site</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row>
                        <Form.Group as={Col} md="6" controlId="validationTitle">
                            <Form.Label srOnly>Title</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Title</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control required type="text" placeholder="Title" defaultValue={siteInfo.name} onChange={(e) => setSiteInfo({ ...siteInfo, name: e.target.value })} />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Please choose a valid Title</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="4" controlId="validationShortName">
                            <Form.Label srOnly>Short Name</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Short Name</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control required type="text" placeholder="shortname" defaultValue={siteInfo.shortName} onChange={(e) => setSiteInfo({ ...siteInfo, shortName: e.target.value })} />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Please choose a valid Title</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} xs="6" controlId="validationMainSite">
                            <Form.Label srOnly>Main Site</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Main Site</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control as={mainSiteSelect} required custom />
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={updateSite}>Save</Button>
                </Modal.Footer>
            </Modal>
        </Modal>
    </>;
}

const mapStateToProps = (state) => {
    return {
        allSite: state.scenes.site.allSite,
        allMainSite: state.scenes.site.allMainSite
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllSite: () => dispatch(siteActions.getAllSite()),
        getAllMainSite: () => dispatch(siteActions.getAllMainSite()),
        insertSite: (name, shortname, mainSite) => dispatch(siteActions.insertSite(name, shortname, mainSite))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewSite);
import React, { useState, useEffect } from "react";
import axios from "axios";

import { helper } from "../common/helper";
import SimpleGallery from "./SimpleGallery";
import SpinnerLoad from "../common/spinner";
import constants from "../../const";

import "photoswipe/dist/photoswipe.css";


function ImageGalleryView(props) {
  var token = `?token=${localStorage.getItem("token").slice(7)}`;

  const [gallery, setGallery] = useState({contents:[] ,children:{}
  });
  const [readyToLoad, setReadyToLoad] = useState(false);
  const [galleryHTML, setGalleryHTMl] = useState(null);

  function isFolder(file) {
    return file.key.endsWith('/')
  }
  
  const grouper = (files, root) =>{
    const fileTree = {
      contents: [],
      children: {}
    }
  
    files.map((file) =>{
      file.relativeKey = (file.newKey || file.key).substr(root.length)
      let currentFolder = fileTree
      const folders = file.relativeKey.split('/')
      folders.map((folder, folderIndex) =>{
        if(folderIndex == folders.length -1 && isFolder(file)){
          for (const key in file){
            currentFolder[key] = file[key]
          }
        }
        if(folder == ''){
          return
        }
        const isAFile = (!isFolder(file) && (folderIndex === folders.length - 1))
        if (isAFile) {
          currentFolder.contents.push({
            ...file,
            keyDerived: true
          })
        } else {
          if (folder in currentFolder.children === false) {
            currentFolder.children[folder] = {
              contents: [],
              children: {}
            }
          }
          currentFolder = currentFolder.children[folder]
        }
        })
      })
      function addAllChildren(level, prefix) {
        if (prefix !== '') {
          prefix += '/'
        }
        let files = []
        for (const folder in level.children) {
          files.push({
            ...level.children[folder],
            contents: undefined,
            keyDerived: true,
            key: root + prefix + folder + '/',
            relativeKey: prefix + folder + '/',
            children: addAllChildren(level.children[folder], prefix + folder),
            size: 0
          })
        }
        files = files.concat(level.contents)
        return files
      }
    
      files = addAllChildren(fileTree, '')
      return fileTree
  }

  const imageURL = (path) => {
    return `${constants.CONTENT_DOMAIN}download/sus-media/galleries${path}${token}`;
  };

  const fetchGallery = (id) => {
    if (localStorage.getItem("token") != null) {
      axios
        .get(`${constants.CONTENT_DOMAIN}files/galleries/${id}${token}`)
        .then(async (res) => {
          var fileTree = grouper(res.data, '/'+id)
          setGallery(fileTree)
          setReadyToLoad(true)

        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    fetchGallery(props.id);
  }, []);

  useEffect(() => {
    const fetchDimension = async () => {
      if (readyToLoad){
        helper.loadGalleryTree(gallery, imageURL)
        setGalleryHTMl(<SimpleGallery galleryID={"psp-gallery"} album={props.id} images={gallery} params={props.params}/>)
      }
    };

    if (gallery != null) {
      fetchDimension().catch(console.error);
    }
  },[gallery, readyToLoad, galleryHTML]);


  return (
    <>
      {readyToLoad ? (galleryHTML) : <SpinnerLoad />}
    </>
  );
}

export default ImageGalleryView;

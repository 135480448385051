import axios from 'axios';
import React, { useState } from 'react';
import constants from '../../const.js'

const ForgotPassword = () => {

    /**local email input state */
    const [email, setEmail] = useState({
        valid: true,
        email: ''
    });
    /**local password state */
    const [pass, setPass] = useState({
        valid: true,
        pass: ''
    })

    const submitEmail = async (ema) => {
        const request = await axios.post(`${constants.USER_API_URL}/auth/resetToken`,{email:ema});
        return request;
    }

    const submit = () =>{
        submitEmail(email.email);
    }

    return <div className="container">
        <div className="row justify-content-center" style={{ marginTop: "50px" }}>
            <div className="col-5">
                Enter Email
                <div className="form-group">
                    <input placeholder="Email" type="email" onChange={(e) => { setEmail({ ...email, email: e.target.value }) }} className="form-control" id="inputEmail" />
                </div>
                {/* {errMsgHTML} */}
                <button onClick={submit} className="btn btn-primary" style={{ margin: "10px" }}>Send link</button>
            </div>
        </div>
    </div>
}

export default ForgotPassword;
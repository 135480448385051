import React, { useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { helper } from '../common/helper.js';

function FilterActor(props){

    const history = useHistory();

    const url = (key, item) =>{
        if(key === "sexe")
            return `${props.baseUrl}&sexe=${item.key}&sort=${props.params.sort}&direction=${props.params.direction}`;
        if(key === "sort")
            return `${props.baseUrl}&sexe=${props.params.sexe}&sort=${item.key}&direction=${props.params.direction}`;
        if(key === "direction")
            return `${props.baseUrl}&sexe=${props.params.sexe}&sort=${props.params.sort}&direction=${item.key}`;
    }
    useEffect(()=>{}, []);

    const paramURL = (tag) =>{
        return `/actors?${tag}&size=${props.params.size}&sort=${props.params.sort}&direction=${props.params.direction}`;
    }

    const onTagChange =(e)=>{
        var temp = props.tags.selected.map(a=>a.id);
        temp.push(e.target.value);
        history.push(paramURL(helper.query(temp,"tag")));
        props.tag(temp)
    }

    const onRemoveTag =(index) =>{
        var temp = props.tags.selected.map(a=>a.id);
        var i = temp.indexOf(index);
        temp.splice(i, 1);
        history.push(paramURL(helper.query(temp,"tag")));
        props.tag(temp);
    }


    return (
        <div className="row p-3">
            {props.filters.map(filter=>
                <div key= {filter.key} className="list-group col-3">
                    <div className="list-group-item list-group-item-action list-group-item-dark active">{filter.title}</div>
                    {filter.items.map(item =>
                    <Link key={item.key} to={url(filter.key, item)} onClick={(e)=>props.filterRefresh(filter.key, item)} className={`list-group-item list-group-item-action ${item.key===filter.selected ? "list-group-item-light active":""}`}>{item.value}</Link>
                        )}
                </div>
            )}
            {!props.isFetching && props.tags!= null ? 
            <div className="col-3">
                <label>Tags</label>
                <select className="custom-select custom-select-sm" onChange={onTagChange}>
                    <option selected>Select a tag</option>
                    {props.tags.available.map(tag=>
                    <option key={tag.id} value={tag.id}>{tag.name}</option>
                    )}
                </select>
                
                <div className="p-2">
                    {props.tags.selected.map((tag)=>
                        <Link to={"#"} key={tag.id} className="badge badge-dark m-1" onClick={()=> {onRemoveTag(tag.id)}}>{tag.name}</Link>
                    )}
                </div>
            </div>: <div className="col-3"> 
            <Spinner animation="border" variant="danger" size="bg" />
            </div>
            }
    </div>
    );
}

const mapStateToProps = (state) =>{
    return {
        isFetching: state.actorCardReducers.isFetching.count,
        tags: state.actorCardReducers.tags
    }
  }

export default connect(mapStateToProps)(FilterActor);
import React from 'react';
import { Link } from "react-router-dom";

function ItemDisplay(props){
    const sizes = props.sizes;
    return (<ul className="list-group list-group-horizontal">{sizes.map(size => 
        <Link style={{width:"60px"}} key={size} 
        onClick={()=>{ props.sizeParam(size)}}
        className={`list-group-item list-group-item-action ${parseInt(props.size) === size ? "list-group-item-light active":""}`} 
        to={`${props.baseUrl}${size}`}>{size}</Link>)}</ul>);
}

export default ItemDisplay;
import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from "axios";
import { connect } from 'react-redux';
import { Form, Col, InputGroup, Modal, Button, Row } from 'react-bootstrap';
import Select from "react-dropdown-select";
import styled from "styled-components";
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from "@mui/icons-material/Edit";


import { UserContext } from '../../context/UserContext';
import NewActor from '../actors/new-actor-popup';
import NewMovie from '../scenes/new-movie-popup.js';
import NewSceneTag from '../scenes/new-scene-tag';
import { statusActions } from '../../actions/scene/statusActions';
import { siteActions } from '../../actions/scene/siteActions';
import { sceneActions } from '../../actions/scene/sceneActions';
import { actorActions } from '../../actions/scene/actorActions';
import { movieActions } from '../../actions/movie/movieActions';
import constants, { TAGS } from '../../const.js';
import SpinnerLoad from '../common/spinner';

import { getActors, insertSceneActor, removeSceneActor, getTags, getPositions } from '../../controller/sceneController';
import { helper } from '../common/helper';
import CustomContentAndDropdown from '../common/custom-content-and-dropdown.js';
import NewScenePosition from './new-scene-position.js';
import PositionAdder from './position-adder.js';
import { uploadFileActions } from '../../actions/scene/fileDirectoryActions.js';

function EditScene(props) {

    const { userState, setUserState } = useContext(UserContext);
    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [scene, setScene] = useState({
        site: 0,
        title: "",
        date: "2019-01-01",
        description: "",
        minutes: 0,
        seconds: 0,
        status: 0,
        movie: 0
    })
    const [actors, setActors] = useState({ original: [], selectedActor: [] });
    const [editActor, setEditActor] = useState({
        editActor: false,
        saveActor: false,
        selectedActor: []
    })
    const [tags, setTags] = useState({ original: [], selectedTag: [] });
    const [movies, setMovies] = useState([]);
    const [site, setSite] = useState([]);
    const [editTag, setEditTag] = useState({
        editTag: false,
        saveTag: false,
        selectedTag: []
    })
    const [scenePositions, setScenePositions] = useState([]);
    const [positionLoc, setPositionLoc] = useState(0);
    const [reloadVideo, setReloadVideo] = useState(true);
    const [videoTime, setVideoTime] = useState(0);
    const vidRef = useRef(null)

    const setActorsCall = (data) => {
        setActors({ ...actors, original: data, selectedActor: data })
    }

    const setTagsCall = (data) => {
        setTags({ ...tags, original: data, selectedTag: data })
    }

    const demoOptions = props.allTag.map((option) => ({
        ...option,
        label: option.name,
        value: option.id
      }));

    const category = ["Scene", "Role", "Location", "Object", "Outfit"];
    var isMobile = window.innerWidth <= constants.mobileSize;

    const token = `?token=${localStorage.getItem('token').slice(7)}`;

    const keydownHandler = (e) => {
        // if (e.keyCode === 13 && e.shiftKey) {
        //     addTag();
        // }
        // if (e.keyCode === 83 && e.shiftKey) {
        //     var btn = document.getElementById("saveBTN")
        //     btn.click();
        // }

        // if (e.keyCode === 67 && e.shiftKey) {
        //     handleCancel()
        // }

        // if(e.keyCode === 80 && e.shiftKey){
        //     playVideo()
        // }

        // if(e.keyCode === 219 && e.shiftKey){
        //     //rewind
        // }

        // if(e.keyCode === 221 && e.shiftKey){
        //     forwardVideo(15)
        //     e.stopPropagation()
        // }
    }

    const fetchScenePositions = () =>{
        axios.get(`${constants.DATA1_API_URL}/scene/${parseInt(props.sceneId)}/positions`).then((res)=>{
            setScenePositions(res.data)
        })
      }

    const refreshData = () => {
        props.getAllSite();
        props.getAllActor();
        props.getAllTags();
        props.getAllMovies();
        props.getAllStatus();
    }

    useEffect(() => {
        
        if (props.sceneView) {
            fetchScenePositions()
            getActors(props.sceneId, setActorsCall);
            getTags(props.sceneId, setTagsCall);
            var d = new dayjs(props.scene.date);
            setScene({
                site: props.scene.siteId,
                title: props.scene.title,
                date: d.format('YYYY-MM-DD'),
                description: props.scene.description,
                minutes: props.scene.minutes,
                seconds: props.scene.seconds,
                status: props.scene.status,
                movie: props.scene.movie
            })
            setMovies([props.movie])
            setSite([props.site])
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!props.sceneView) {
            var d = new dayjs(props.scene.date);
            setScene({
                site: props.scene.siteId,
                title: props.scene.title,
                date: d.format('YYYY-MM-DD'),
                description: props.scene.description,
                minutes: props.scene.minutes,
                seconds: props.scene.seconds,
                status: props.scene.status,
                movie: props.scene.movie
            })
        }
    }, [props.scene]);


    const handleCancel = () => {
        setShow(false);
        setPositionLoc(0);
    }

    useEffect(() => {
        if (movies.length != 0)
            setScene({ ...scene, movie: movies[0].id })
    }, [movies]);

    useEffect(() => {
        if (site.length != 0)
            setScene({ ...scene, site: site[0].id })
    }, [site])

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);

        props.updateScene(props.scene.id, scene);
        if (actors.original != actors.selectedActor) props.updateActor(props.scene.id, actors.selectedActor);
    }
    const handleShow = () => {
        if (!props.sceneView) {
            getActors(props.sceneId, setActorsCall)
            getTags(props.sceneId, setTagsCall);
            props.getScene(parseInt(props.sceneId));
        }
        setShow(true)
    };

    const addTag = () => {
        var temp = editTag.selectedTag;
        temp.forEach(element => {
            props.insertSceneTag(props.scene.id, element.id).then(() => {
                refreshTag();
            });
        });

        setEditTag({ ...editTag, selectedTag: [] })
    }

    const refreshTag = () => {
        props.getSceneTag(props.scene.id).then(a => {
            setTagsCall(a.tags)
        })
    }

    const removeTag = (e) => {
        props.deleteSceneTag(props.scene.id, e.target.getAttribute('value')).then(
            () => { refreshTag() }
        )
    }

    const resetTags = () => {
        props.deleteAllSceneTag(props.scene.id).then(
            () => { refreshTag() }
        )
    }

    const addActor = () => {
        var temp = editActor.selectedActor;
        temp.forEach(element => {
            insertSceneActor(props.scene.id, element.id).then(()=>{
                refreshActor();
            })
        });

        setEditActor({ ...editActor, selectedActor: [] })
    }

    const refreshActor = () => {
        getActors(props.scene.id, setActorsCall)
    }

    const removeActor = (e) => {
        console.log(e)
        console.log(e.target)
        removeSceneActor(props.scene.id,  e.target.getAttribute('value')).then(
            () => {refreshActor()}
        )
    }

    const jumpVideo = (value) =>{
        vidRef.currentTime = value;
        var vid = document.getElementById(`video-${props.sceneId}`);
        vid.currentTime = value;
    }

    const customActorRenderer = ({ item, props, state, methods }) => (
        <StyledOption>
            <img src={`${constants.CONTENT_DOMAIN}download/sus-media/actors/${String(item.id).padStart(4, '0')}/model-medium.webp${token}`} style={{ width: "30px", height: "41px" }} />
            {" "}{item.name}
            <span onClick={() => methods.removeItem(null, item)}>&times;</span>
        </StyledOption>
    );

    const getCurrentTime = () => {
        var vid = document.getElementById(`video-${props.sceneId}`);
        if (vid != null)
            setVideoTime(vid.currentTime);
    };


    const statusSelect = React.forwardRef(() => (
        <select className="custom-select custom-select-sm" value={scene.status} onChange={(e) => setScene({ ...scene, status: parseInt(e.target.value) })} data-live-search="true">
            <option defaultValue={"DEFAULT"}>Select Status</option>
            {props.allStatus.map(status => <option key={status.id} value={status.id}>{status.name} | {status.description}</option>)}
        </select>));

    const movieSelect =
        <Select style={{ minWidth: "300px" }} options={props.allMovie} placeholder="select Movie" labelField="title" valueField="id"
            color="#000000" searchable="true" clearable="true" searchBy="title" sortBy="title" dropdownHeight="250px" dropdownPosition="auto"
            values={[props.movie]}
            onChange={(e) => { setMovies(e) }}
        />;

    return <>
        {props.sceneView ? 
        <Button variant="danger" size="sm" onClick={handleShow}>Edit Scene</Button> :
        <EditIcon sx={{fontSize:"25px"}}  onClick={handleShow}/>
            // <Button variant="dark" className="badge badge-pill" style={{borderRadius:"30px", fontWeight:"bold"}}  onClick={handleShow}><EditIcon sx={{fontSize:"20px"}}/></Button>
            }

        <Modal id={`editSceneModal${props.sceneId}`} size="xl" show={show} onHide={handleCancel} style={{ backgroundColor: 'hsla(0, 0%, 0%, 0.9)', left: `${positionLoc}%` }}>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                {document.addEventListener('keydown', keydownHandler)}
                <Modal.Header closeButton>
                    <Modal.Title>Edit Scene {props.sceneId} Information</Modal.Title>
                </Modal.Header>
                {!props.isFetching ? <Modal.Body>
                    {isMobile ?
                        <Form.Row>
                            <Form.Group as={Col} xs="3" >
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label="Mobile"
                                    checked={userState.mobileEdit}
                                    onChange={() => {
                                        if (userState.mobileEdit) setUserState({ ...userState, mobileEdit: false })
                                        else setUserState({ ...userState, mobileEdit: true })
                                    }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} xs="6" controlId="validationStatus">
                                <Form.Label srOnly>Status</Form.Label>
                                <InputGroup size="sm">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Status</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control as={statusSelect} required custom />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} xs="3">
                                <Button className="mr-auto" variant="secondary" onClick={()=>refreshData()} size={`${isMobile ? 'sm' : 'md'}`}>Refresh</Button>
                            </Form.Group>

                        </Form.Row> : null}

                    <Form.Row>
                        <Form.Group as={Col} md="6" controlId="validationTitle">
                            <Form.Label srOnly>Title</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Title</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control required type="text" placeholder="Title" defaultValue={props.scene.title} onChange={(e) => setScene({ ...scene, title: e.target.value })} />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Please choose a valid Title</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        {!userState.mobileEdit ? <>
                            <Form.Group as={Col} md="3" controlId="validationMinute">
                                <Form.Label srOnly>Length</Form.Label>
                                <InputGroup size="sm">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Length</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control type="text" placeholder="Minutes" defaultValue={props.scene.minutes} onChange={(e) => setScene({ ...scene, minutes: e.target.value })} />
                                    <Form.Control type="text" placeholder="Seconds" defaultValue={props.scene.seconds} onChange={(e) => setScene({ ...scene, seconds: e.target.value })} />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="3" controlId="validationStatus">
                                <Form.Label srOnly>Status</Form.Label>
                                <InputGroup size="sm">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Status</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control as={statusSelect} required custom />
                                </InputGroup>
                            </Form.Group></> : null}
                    </Form.Row>
                        <Form.Row>
                        {!userState.mobileEdit ? 
                            <Form.Group as={Col} md="6" controlId="validationSite">
                                <Form.Label srOnly>Site</Form.Label>
                                <InputGroup size="sm">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Site</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Select style={{ minWidth: "300px" }} options={props.allSite} placeholder="+ click to select Site" labelField="name" valueField="id"
                                        color="#000000" searchable="true" sortBy="name" clearable="true" searchBy="name" dropdownHeight="250px" dropdownPosition="auto"
                                        values={[props.site]} onChange={e => setSite(e)} />
                                </InputGroup>
                            </Form.Group>
                            : null}

                            <Form.Group as={Col} md="6" controlId="validationDate">
                                <Form.Label srOnly>Date</Form.Label>
                                <InputGroup size="sm">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Date (YYYY-MM-DD)</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control type="text" placeholder="date" defaultValue={new dayjs(props.scene.date).add(1, 'day').format('YYYY-MM-DD')} onChange={(e) => 
                                            setScene({ ...scene, date: e.target.value })
                                            } />
                                    <Form.Control.Feedback type="invalid">Please choose a valid date</Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Form.Row>
                        {!userState.mobileEdit ? 
                        <Form.Row>
                            <Form.Group as={Col} md="12" controlId="validationMovie">
                                <Form.Label srOnly>Movie</Form.Label>
                                <InputGroup size="sm">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Movie</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    {!props.isFetchingActor ? movieSelect : <div></div>}
                                </InputGroup>
                            </Form.Group>
                        </Form.Row>: null}
                    <Form.Row> 
                        <Form.Group as={Col} md="12" controlId="validationActors">
                            <Form.Label srOnly>Actors</Form.Label>
                            <Row style={{ padding: "10px", marginTop: "10px" }}>
                                <Select style={{ minWidth: "300px" }} multi options={props.allActor} placeholder="+ click to add actor" values={editActor.selectedActor} labelField="name" valueField="id"
                                    color="#000000" searchable="true" sortBy="name" clearable="true" searchBy="name" dropdownHeight="250px" dropdownPosition="auto"
                                    dropdownHandleRenderer={({ state }) => (<span>{state.dropdown ? '–' : '+'}</span>)}
                                    onChange={e => setEditActor({ ...editActor, selectedActor: e })} />
                                <button type="button" className={`btn btn-sm btn-light`} onClick={addActor}>Add</button>
                                <button type="button" className={`btn btn-sm btn-light`} onClick={refreshActor} >Refresh</button>
                            </Row>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Row className='m-0'>
                            {actors.selectedActor.map(actor=>{
                                return <Col>
                                <Row style={{ height: "150px", width: "120px", backgroundImage:`url(${constants.CONTENT_DOMAIN}download/sus-media/actors/${String(actor.id).padStart(4, '0')}/model-medium.webp${token})`, backgroundSize:'contain' }}>
                                    <span className="badge badge-dark" style={{'position':'absolute', 'top':'2px', 'zindex':'0'}} value={actor.id} onClick={removeActor}><CloseIcon value={actor.id} fontSize='small'/></span>
                                    </Row>
                                <Row><span value={actor.id} style={{'fontSize':'12px'}}>{actor.name}</span></Row>
                            </Col>
                            })}
                        </Row>
                    </Form.Row>
                   
                    <Form.Row>
                        <Form.Group as={Col} md="12" controlId="validationTags">
                            <Form.Label srOnly>Tags</Form.Label>
                            <Row style={{ padding: "10px", marginTop: "10px" }}>
                                <Select style={{ minWidth: "300px" }} multi options={props.allTag} placeholder="+ click to add tag" values={editTag.selectedTag} labelField="name" valueField="id"
                                    color="#000000" searchable="true" sortBy="num" clearable="true" searchBy="name"
                                    dropdownHeight="250px" dropdownPosition="auto"
                                    dropdownHandleRenderer={({ state }) => (<span>{state.dropdown ? '–' : '+'}</span>)}
                                    onChange={e => setEditTag({ ...editTag, selectedTag: e })} />
                                <button type="button" className={`btn btn-sm btn-light`} onClick={addTag}>Add</button>
                                <button type="button" className={`btn btn-sm btn-light`} onClick={refreshTag} >Refresh</button>
                            </Row>
                            {category.map((cat, idx) => (
                                <Row key={`${cat}${idx}`}>
                                    <Col xs={3} sm={3} md={2}>{cat}</Col>
                                    <Col>
                                        <Row>
                                            {helper.findTag(TAGS.SCENECATEGORY, cat, tags.selectedTag).map((tag) =>
                                            (<div key={tag.id} className="m-1 badge badge-secondary">
                                                <span> <a value={tag.id} style={{ color: "white" }} onClick={removeTag}>{tag.name}</a> </span>
                                                {cat == 'Outfit' ? <span><img src={`${constants.CONTENT_DOMAIN}download/sus-media/bodyLocation/${tag.bodyLocation}.png${token}`} style={{ height: '20px' }}></img></span> : null}

                                            </div>))}
                                        </Row>
                                    </Col>
                                </Row>))}
                        </Form.Group>
                    </Form.Row>
                    {!userState.mobileEdit?<Form.Row>
                            <Row className='m-0'>
                                {helper.findTag(TAGS.SCENECATEGORY, "Outfit", tags.original).map(tag=>{return <Col>
                                <img src={`${constants.CONTENT_DOMAIN}download/sus-media/tags/${tag.id}.webp${token}`} style={{ width: "75px", height: "75px" }}></img>
                                <span style={{'fontSize':'12px'}}>{tag.name}</span>
                </Col>})}
                            </Row>
                    </Form.Row>:null}
                    {!userState.mobileEdit ?
                        <Form.Row >
                            <Form.Group as={Col} md="12" controlId="validationDescription">
                                <Form.Label srOnly>Description</Form.Label>
                                <InputGroup size="sm">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Description</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control style={{ height: "100px" }} as="textarea" aria-label="With textarea" defaultValue={props.scene.description} onChange={(e) => setScene({ ...scene, description: e.target.value })} />
                                </InputGroup>
                            </Form.Group>
                        </Form.Row>
                        : null}
                    <Form.Row >
                        <Form.Group as={Col} md="12">
                        <div className={`btn btn-sm btn-light`} onClick={()=>{
                            if(reloadVideo)
                                setReloadVideo(false);
                            else
                                setReloadVideo(true)}} >Reload Video - {props.scene.videoUrl}</div> 
                        <Button className={`btn btn-sm btn-light`} onClick={()=>props.renameVideo(props.sceneId)}>Rename</Button>
                        <a href={`H:/sus-media/scenes/${props.sceneId}`} >Files</a>
                            
                                
                            {reloadVideo?
                                <video ref={vidRef} id={`video-${props.sceneId}`} controlsList="nodownload" playsInline={true} onTimeUpdate={getCurrentTime}
                                poster={`${constants.CONTENT_DOMAIN}${props.scene.posterUrl}${token}`} controls style={{ width: "100%", height: `${isMobile ? '250px' : '400px'}` }} >
                                <source src={`${constants.CONTENT_DOMAIN}${props.scene.videoUrl}${token}`} type="video/mp4"/>
                            </video>
                            :null
                        
                           }
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Button className={`btn btn-sm btn-dark`}>{Math.round(videoTime)}</Button>
                    </Form.Row>
                    <Form.Row>
                   <PositionAdder sceneId={props.sceneId} jumpVideo={jumpVideo} scenePositions={scenePositions} duration={props.scene.minutes * 60 + props.scene.seconds} setPositionLoc={setPositionLoc} isMobile={isMobile}/>
                    </Form.Row>
                </Modal.Body>
                    : <SpinnerLoad />}
                <Modal.Footer>
                    <div className="mr-auto" style={{ display: "flex" }}>
                        <div style={{ marginRight: "10px" }}><NewActor /></div>
                        <div style={{ marginRight: "10px" }}><NewMovie /></div>
                        <div style={{ marginRight: "5px" }}><NewSceneTag /></div>
                        <div style={{ marginRight: "10px" }}><NewScenePosition /></div>
                    </div>
                    {!isMobile ? <div className="mr-auto"> <Button className="mr-auto" variant="secondary" onClick={refreshData} size={`${isMobile ? 'sm' : 'md'}`}>Refresh</Button></div> : null}
                    <Button variant="secondary" onClick={resetTags} size={`${isMobile ? 'sm' : 'md'}`}>Reset</Button>
                    <Button variant="secondary" onClick={handleCancel} size={`${isMobile ? 'sm' : 'md'}`}>Cancel</Button>
                    <Button id="saveBTN" onClick={handleSubmit} size={`${isMobile ? 'sm' : 'md'}`}>Save</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    </>;
}

const StyledOption = styled.span`
padding: 3px 10px;
color: #555;
border-radius: 3px;
margin: 3px;
cursor: pointer;
display: inline-flex;
flex-direction: row;
border: 1px solid #555;
transition: all 1s ease-in;

span {
  display: none;
  transition: all 1s ease-in;
}

a {
  margin: 0 5px;
}

:hover {
  background: #f2f2f2;

  span {
    display: inline;
    margin: 0 0 0 5px;
    color: red;
  }
}
`;

const mapStateToProps = (state) => {
    return {
        isFetching: state.sceneReducers.isFetching,
        scene: state.sceneReducers.scene.scene.scene,
        movie: state.sceneReducers.scene.movie,
        site: state.sceneReducers.scene.site,
        tags: state.sceneReducers.scene.scene.tags,

        allStatus: state.scenes.status.allStatus,
        allSite: state.scenes.site.allSite,
        allTag: state.sceneReducers.tags,
        allMovie: state.movies.movies.movies,
        allActor: state.scenes.actor.allActor,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getScene: (id) => dispatch(sceneActions.getOnlyScene(id)),
        getAllSite: () => dispatch(siteActions.getAllSite()),
        getAllActor: () => dispatch(actorActions.getAllActor()),
        getAllTags: () => dispatch(sceneActions.getSceneTags()),
        getAllMovies: () => dispatch(movieActions.getAllMovies()),
        getAllStatus: () => dispatch(statusActions.getAllStatus()),

        updateScene: (sceneId, scene) => dispatch(statusActions.updateScene(sceneId, scene)),
        updateActor: (scene, actor) => dispatch(actorActions.updateActor(scene, actor)),

        insertSceneTag: (sceneId, tagId) => dispatch(sceneActions.insertSceneTag(sceneId, tagId)),
        deleteSceneTag: (sceneId, tagId) => dispatch(sceneActions.deleteSceneTag(sceneId, tagId)),
        deleteAllSceneTag: (sceneId) => dispatch(sceneActions.deleteAllSceneTag(sceneId)),
        getSceneTag: (id) => dispatch(sceneActions.getSceneTag(id)),
        renameVideo: (id) => dispatch(uploadFileActions.renameVideo(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditScene);
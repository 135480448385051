import React, { useState, useCallback, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Spinner } from 'react-bootstrap';
import ReactCrop from 'react-image-crop';
import "react-image-crop/dist/ReactCrop.css";
import FileBrowser, { Icons } from 'react-keyed-file-browser';
import SpinnerLoad from './spinner';

import { uploadFileActions } from '../../actions/scene/fileDirectoryActions';

import '../../../node_modules/react-keyed-file-browser/dist/react-keyed-file-browser.css';


const pixelRatio = window.devicePixelRatio || 1;

// We resize the canvas down when saving on retina devices otherwise the image
// will be double or triple the preview size.
function getResizedCanvas(canvas, newWidth, newHeight) {
  const tmpCanvas = document.createElement("canvas");
  tmpCanvas.width = newWidth;
  tmpCanvas.height = newHeight;

  const ctx = tmpCanvas.getContext("2d");
  ctx.drawImage(
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    0,
    0,
    newWidth,
    newHeight
  );

  return tmpCanvas;
}

const getImageDimension = async (file) => {
  return new Promise(function (resolved, rejected) {
    var i = new Image()
    i.onload = function () {
      resolved({ w: i.width, h: i.height })
    };
    i.src = file
  })
}

function UploadTagImage(props) {

  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "px", width: 6000, aspect: 1.00 });
  const [completedCrop, setCompletedCrop] = useState(null);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", async () => {

        var dim = await getImageDimension(reader.result);
        var dist = (dim.h - dim.w) / 2;

        if (dim.w < dim.h) {
          var cvss = document.createElement("canvas");
          var cvs = cvss.getContext("2d");
          cvss.height = dim.h;
          cvss.width = dim.w + (2 * dist);
          cvs.fillRect(0, 0, dist, dim.h);
          cvs.fillRect(dist + dim.w, 0, dist, dim.h);

          var image = new Image();
          image.src = reader.result;
          cvs.drawImage(image, dist, 0);
          setUpImg(cvss.toDataURL())
        } else {
          setUpImg(reader.result);
        }


      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };


  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);


  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = Math.ceil(crop.width * pixelRatio);
    canvas.height = Math.ceil(crop.height * pixelRatio);

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  function dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    props.uploadTagFile(croppedImage, props.tagId);
    // props.setFile(croppedImage);
    setCompletedCrop(croppedImage);
  }


  function generateDownload(previewCanvas, crop) {
    if (!crop || !previewCanvas) {
      return;
    }

    const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height);

    const reader = new FileReader();
    canvas.toBlob(blob => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        dataURLtoFile(reader.result, props.tagId)
      }
    });
  }

  const handleSave = () => {
    generateDownload(previewCanvasRef.current, completedCrop);
  };

  return <>
        <div>
          <div><input type="file" accept="image/*" onChange={onSelectFile} /></div>
          <ReactCrop src={upImg} onImageLoaded={onLoad} crop={crop}
            onChange={(c) => setCrop(c)} onComplete={(c) => setCompletedCrop(c)} />
          <div>
            <canvas ref={previewCanvasRef}
              style={{ width: Math.ceil(completedCrop?.width ?? 0), height: Math.ceil(completedCrop?.height ?? 0) }} />
          </div>
        </div>

        <div className="mr-auto" style={{ display: "flex" }}>
          {props.isUploading ? <SpinnerLoad /> : <div />}
        </div>
        <div>
        <Button variant="primary" type="button" onClick={handleSave}
          disabled={!completedCrop?.width || !completedCrop?.height} >
          Upload
        </Button>
        </div>
  </>;
}

const mapStateToProps = (state) => {
  return {
    isUploading: state.scenes.uploadFile.isUploading,
    files: state.scenes.uploadFile.files
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    uploadTagFile: (file, id) => dispatch(uploadFileActions.uploadTagFile(file, id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadTagImage);
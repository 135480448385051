import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Col, InputGroup, Modal, Button, Table, Row } from 'react-bootstrap';
import Select from "react-dropdown-select";

import { sceneActions } from '../../actions/scene/sceneActions';
import constants from '../../const';
import { helper } from '../common/helper.js'


function NewScenePosition(props) {

    var isMobile = window.innerWidth <= constants.mobileSize;

    const [positions, setPositions] = useState([]);
    const [positionInfo, setPositionInfo] = useState({
        id: 1,
        name: "",
        FullName:"",
        alias: "",
        control: {
            id: 15,
            name: ""
        },
        entry: {
            id: 2,
            name: ""
        },
        category: {
            id: 22,
            name: ""
        },
        tags: []
    });
    const [editTag, setEditTag] = useState({
        editTag: false,
        saveTag: false,
        selectedTag: []
    })

    const [table, setTable] = useState(null);
    const [order, setOrder] = useState("asc");
    const [showEditor, setShowEditor] = useState(false);
    const [editorMode, setEditorMode] = useState("Edit");
    const [showList, setShowList] = useState(false);
    

    var token = `?token=${localStorage.getItem('token').slice(7)}`;


    const refresh = () => {
        props.getAllPositionTags();

        props.getAllPositions().then((data)=>{
            setPositions(data.positions)
            var tab = <tbody>
            {data.positions.map(pos => (
                <tr key={pos.id}>
                    <td onClick={() => openModal(pos)}>{pos.id}</td>
                    <td onClick={() => openModal(pos)}>{pos.name}</td>
                    <td onClick={() => openModal(pos)}><img alt={pos.name} src={`${constants.CONTENT_DOMAIN}download/sus-media/positions/${pos.id}.png${token}`} style={{ height: "200px", width: "350px" }} /></td>
                    <td onClick={() => openModal(pos)}>{pos.control.name}</td>
                    <td onClick={() => openModal(pos)}>{pos.entry.name}</td>
                    <td onClick={() => openModal(pos)}>{pos.category.name}</td>
                    <td onClick={() => openModal(pos)}>{pos.tags.map(t =>(<Button key={t.id} variant={t.category===3?`info`:`secondary`} className="badge badge-pill">{t.name}</Button> ))}</td>
                    <td onClick={() => openModal(pos)}><span style={{ display:"-webkit-box", overflow: "hidden" , WebkitBoxOrient:"vertical", WebkitLineClamp: 8}}>{pos.description}</span></td>
                </tr>
            ))}
        </tbody>
        setTable(tab)
        })
    }

    useEffect(() => {
        if(showList)
            refresh()
        // eslint-disable-next-line
    }, [showList]);

    const handleCancel = () => {
        setShowEditor(false)
        // setPositionInfo({
        //     id: 1,
        //     name: "",
        //     control: {
        //         id: 15,
        //         name: ""
        //     },
        //     entry: {
        //         id: 2,
        //         name: ""
        //     },
        //     category: {
        //         id: 22,
        //         name: ""
        //     },
        //     tags: []
        // });
    }

    const handleShow = () => {
        setShowList(true)
    };

    //todo
    const updateTag = () => {
        if (editorMode === "Edit") {
            props.updatePosition(positionInfo.id, 
                { 
                    name: positionInfo.FullName,
                    control: parseInt(positionInfo.control.id),
                    entry: parseInt(positionInfo.entry.id),
                    category: parseInt(positionInfo.category.id), 
                    alias: positionInfo.alias,
                    description: positionInfo.description.replace(/"/g, '\\"').replace(/'/g,"\\'")
                })
        }
        else {
            props.insertPosition({ 
                name: positionInfo.FullName,
                control: parseInt(positionInfo.control.id),
                entry: parseInt(positionInfo.entry.id),
                category: parseInt(positionInfo.category.id), 
                alias: positionInfo.alias,
                description: positionInfo.description.replace(/"/g, '\\"').replace(/'/g,"\\'")
            })
        }
        setShowEditor(false)
        refresh()
    }

    const addTag = () =>{
        var temp = editTag.selectedTag;
        temp.forEach(element =>{
            props.insertPositionTag(positionInfo.id, element.id).then(()=>{
                props.getAllPositions()
            })
        })
    }

    const removeTag = (e) =>{
        props.deletePositionTag(positionInfo.id, e.target.getAttribute('value')).then(()=>{
            props.getAllPositions()
        })
    }

    const openModal = (position) => {
        setEditorMode("Edit")
        setPositionInfo(position)
        setShowEditor(true)
    }

    const sortPositions = (key) => {
        var tmpArr = positions;
        if (order === "asc") {
            setOrder("desc")
        }
        else { setOrder("asc") }
        var arr = []
        if (key!=="name"&&key!=="id"){
            arr = tmpArr.sort(helper.sortSubObjects(key, "name", order));
        }
        else{
            console.log(key)
            arr = tmpArr.sort(helper.sortObjects(key, order));
        }
        setPositions(arr);
        var tab = <tbody>
            {arr.map(pos => (
                <tr key={pos.id}>
                    <td onClick={() => openModal(pos)}>{pos.id}</td>
                    <td onClick={() => openModal(pos)}>{pos.name}</td>
                    <td onClick={() => openModal(pos)}><img alt={pos.name} src={`${constants.CONTENT_DOMAIN}download/sus-media/positions/${pos.id}.png${token}`} style={{ height: "200px", width: "350px" }} /></td>
                    <td onClick={() => openModal(pos)}>{pos.control.name}</td>
                    <td onClick={() => openModal(pos)}>{pos.entry.name}</td>
                    <td onClick={() => openModal(pos)}>{pos.category.name}</td>
                    <td onClick={() => openModal(pos)}>{pos.tags.map(t =>(<Button key={t.id} variant={t.category===3?`info`:`secondary`} className="badge badge-pill">{t.name}</Button> ))}</td>
                    <td onClick={() => openModal(pos)}><span style={{ display:"-webkit-box", overflow: "hidden" , WebkitBoxOrient:"vertical", WebkitLineClamp: 8}}>{pos.description}</span></td>
                </tr>
            ))}
        </tbody>
        setTable(tab)
    }

    const addPosition = () => {
        setEditorMode("Add");
        setShowEditor(true)
    }



    var controlSelect = React.forwardRef(() => (
        <select className="custom-select custom-select-sm" value={positionInfo.control.id} onChange={(e) => 
        setPositionInfo({ ...positionInfo, control: props.allPositionTags.find(x => x.id = parseInt(e.target.value)) })}>
            <option defaultValue={"DEFAULT"}>Select Control</option>
            {props.allPositionTags.filter(x => x.category===5).map(i => <option key={i.id} value={i.id}>{i.name}</option>)}
        </select>))

    var entrySelect = React.forwardRef(() => (
        <select className="custom-select custom-select-sm" value={positionInfo.entry.id} onChange={(e) => 
        setPositionInfo({ ...positionInfo, entry: props.allPositionTags.find(x => x.id === parseInt(e.target.value)) })}>
           <option defaultValue={"DEFAULT"}>Select Entry</option>
            {props.allPositionTags.filter(x => x.category===4).map(i => <option key={i.id} value={i.id}>{i.name}</option>)}
        </select>))

    var categorySelect = React.forwardRef(() => (
    <select className="custom-select custom-select-sm" value={positionInfo.category.id} onChange={(e) =>
        setPositionInfo({ ...positionInfo, category: props.allPositionTags.find(x => x.id === parseInt(e.target.value)) })}>
        <option defaultValue={"DEFAULT"}>Select Category</option>
        {props.allPositionTags.filter(x => x.category===7).map(i => <option key={i.id} value={i.id}>{i.name}</option>)}
    </select>));


    return <>
        <Button variant="info" size={`${isMobile ? 'sm' : 'md'}`} onClick={handleShow}>Position</Button>
        <Modal size="xl" show={showList} onHide={e => setShowList(false)} style={{ backgroundColor: 'hsla(0, 0%, 0%, 0.9)' }}>   
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>View Positions</Modal.Title>
                        <Form.Group as={Col} xs="3" md="4">
                            <Button className="mr-auto" variant="secondary" size={`${isMobile ? 'sm' : 'md'}`} onClick={addPosition}>Add Position</Button>
                        </Form.Group>
                        <Form.Group as={Col} xs="3" md="4" className="d-flex justify-content-center">
                            <Button variant="secondary" size={`${isMobile ? 'sm' : 'md'}`} onClick={()=>refresh()}>Refresh</Button>
                        </Form.Group>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row >
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th onClick={() => sortPositions("id")}>#</th>
                                    <th onClick={() => sortPositions("name")}>Name</th>
                                    <th>Preview</th>
                                    <th onClick={() => sortPositions("control")}>Control</th>
                                    <th onClick={() => sortPositions("entry")}>Entry</th>
                                    <th onClick={() => sortPositions("category")}>Category</th>
                                    <th>Tags</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            {table}
                        </Table>

                    </Form.Row>
                </Modal.Body>
                <Modal.Footer closeButton>
                        <Form.Group as={Col} xs="3" md="4" className="d-flex justify-content-center">
                            <Button className="mr-auto" variant="secondary" size={`${isMobile ? 'sm' : 'md'}`} onClick={addPosition}>Add Position</Button>
                            <Button variant="secondary" size={`${isMobile ? 'sm' : 'md'}`} onClick={()=>refresh()}>Refresh</Button>
                        </Form.Group>
                </Modal.Footer>
            </Form>
            <Modal size="md" show={showList && showEditor} onHide={handleCancel} style={{ backgroundColor: 'hsla(0, 0%, 0%, 0.9)' }}>
                <Modal.Header closeButton>
                    <Modal.Title>{editorMode} Positions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row>
                        <Form.Group as={Col} controlId="validationTitle">
                            <Form.Label srOnly>Name</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Name</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control required type="text" placeholder="Name" defaultValue={positionInfo.FullName} onChange={(e) => setPositionInfo({ ...positionInfo, FullName: e.target.value })} />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Please choose a valid name</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="validationAlias">
                            <Form.Label srOnly>Alias</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Alias</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control required type="text" placeholder="Alias" defaultValue={positionInfo.alias} onChange={(e) => setPositionInfo({ ...positionInfo, alias: e.target.value })} />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Please choose a valid alias</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="validationControl">
                            <Form.Label srOnly>Control</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Control</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control as={controlSelect} required custom />
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="validationEntry">
                            <Form.Label srOnly>Entry</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Entry</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control as={entrySelect} required custom />
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="validationCategory">
                            <Form.Label srOnly>Category</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Category</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control as={categorySelect} required custom />
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="validationTags">
                            <Form.Label srOnly>Tags</Form.Label>
                            <Row style={{ padding: "10px", marginTop: "10px" }}>
                                <Select style={{ minWidth: "300px" }} multi options={props.allPositionTags} placeholder="+ click to add tag" values={editTag.selectedTag} labelField="name" valueField="id"
                                    color="#000000" searchable="true" sortBy="name" clearable="true" searchBy="name"
                                    dropdownHeight="250px" dropdownPosition="auto"
                                    dropdownHandleRenderer={({ state }) => (<span>{state.dropdown ? '–' : '+'}</span>)}
                                    onChange={e => setEditTag({ ...editTag, selectedTag: e })} />
                                <button type="button" className={`btn btn-sm btn-light`} onClick={addTag}>Add</button>
                                <button type="button" className={`btn btn-sm btn-light`} onClick={props.getAllPositionTags} >Refresh</button>
                            </Row>
                            <Row>
                                <Col>
                                    <>{positionInfo.tags.map((tag, idx) => (
                                        <div key={tag.id} className="m-1 badge badge-secondary">
                                            <span> <a value={tag.id} style={{ color: "white" }} onClick={removeTag}>{tag.name}</a> </span>
                                        </div>
                                    ))}</>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="validationDescription">
                            <Form.Label srOnly>Description</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                        <InputGroup.Text>Description</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control style={{ height: "100px" }} as="textarea" aria-label="With textarea" defaultValue={positionInfo.description} 
                                    onChange={(e) => setPositionInfo({ ...positionInfo, description: e.target.value })} />
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    
      
                        <Form.Row>
                            <img alt={positionInfo.name} src={`${constants.CONTENT_DOMAIN}download/sus-media/positions/${positionInfo.id}.png${token}`} style={{width: "450px" }} />
                        </Form.Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={updateTag}>Save</Button>
                </Modal.Footer>
            </Modal>
        </Modal>
    </>;
}

const mapStateToProps = (state) => {
    return {
        allPositionTags: state.sceneReducers.positionTags,
        allPositions: state.sceneReducers.positions
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllPositionTags: () => dispatch(sceneActions.getPositionTags()),
        getAllPositions: () =>  dispatch(sceneActions.getPositions()),
        updatePosition: (id, body) => dispatch(sceneActions.updatePosition(id, body)),
        insertPosition: (body) => dispatch(sceneActions.insertPosition(body)),
        insertPositionTag: (id, tagId) => dispatch(sceneActions.insertPositionTag(id, tagId)),
        deletePositionTag: (id, tagId) => dispatch(sceneActions.deletePositionTag(id, tagId))


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewScenePosition);
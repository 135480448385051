import React from 'react';
import { Spinner, Container, Row } from 'react-bootstrap';

function SpinnerLoad(props){
    return (
        <Container>
            <Row className="justify-content-center">
                <Spinner animation="border" variant="danger" size="bg" />
            </Row>
        </Container>
    );
}

export default SpinnerLoad;
import React, { useState, useEffect } from "react";
import { Card, Col, Row, CardDeck, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";

import constants from "../../const";
import { helper } from "../common/helper";
import PlaylistCard from "../scenes/playlist-card.js";
import ScenesGalleryViewer from "./scenes-gallery-viewer.js";

export default function PlaylistView(props) {
  const [playlists, setPlaylists] = useState([]);
  const [scenes, setScenes] = useState([]);
  const [playlistsCount, setPlaylistsCount] = useState(0);

  const fetchPlaylist = (playlistId) => {
    var token = "";
    if (localStorage.getItem("token") != null) {
      token = localStorage.getItem("token");

      axios
        .get(`${constants.USER_API.SCENE_PLAYLIST_URL}/pid/${playlistId}`, {
          headers: { Authorization: `${localStorage.getItem("token")}` },
        })
        .then((res) => {
          setPlaylists(res.data);
          setPlaylistsCount(res.data.scenes.length);
          formatScenes(res.data.scenes);
        });
    }
  };

  useEffect(() => {
    fetchPlaylist(props.match.params.id);
  }, []);


  const formatScenes = (plScenes) =>{
    var sclist = [];
    for (var s in plScenes){
      sclist.push(plScenes[s].scene)
    }
    setScenes(sclist)
  }


  var playlistCardElement = [];

  var i = 0;
  if (Array.isArray(playlists)) {
    var colSize = 1;
    for (var col = 0; col < Math.ceil(playlists.length / colSize); col++) {
      var cardDeck = [];
      for (var row = 0; row < colSize; row++) {
        if (i < playlists.length) {
          if (playlists[i] !== null)
            cardDeck.push(
              <PlaylistCard
                key={`${row}-${col}`}
                style={{ width: "200px" }}
                playlist={playlists[i]}
                roles={props.perms}
              />
            );
          i++;
        }
      }
      playlistCardElement.push(
        <CardDeck key={col} style={{ padding: "10px" }}>
          {cardDeck}
        </CardDeck>
      );
    }
  }

  return (
    <Container fluid>
        <Row className="justify-content-center p-2">
            <h1>Playlist - {playlists.title}</h1>
        </Row>
       <Row>
          <ScenesGalleryViewer scenes={scenes} settings={{
                                "menu":{
                                    addToFavorite: true,
                                    removeFromPlaylist: true
                                }
                         }}/>
       </Row>
    </Container>
  );
}

import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Form, Col, Modal, Button, Table } from "react-bootstrap";
import HistoryIcon from '@mui/icons-material/History';

import constants from "../../const";
import { helper } from "../common/helper.js";


function SceneHistory(props) {
  var isMobile = window.innerWidth <= constants.mobileSize;

  const [showModal, setShowModal] = useState(false);
  const [order, setOrder] = useState("asc");
  const [sceneHistory, setSceneHistory] = useState([])

  const fetchSceneHistory = (sceneid) => {
    var token = '';
    if (localStorage.getItem('token') != null) {
        token = localStorage.getItem('token');
        axios.get(`${constants.USER_API.USER_API_URL}/admin/history/scene/${sceneid}`, {
            headers: { 'Authorization': `${token}` }
        }).then(
            res => {
                setSceneHistory(res.data);
            }
        ).catch(err => {
            console.log(err);
        });
    }
}

  const refresh = () => {
    fetchSceneHistory(props.sceneId)
  };

  useEffect(() => {
    if (showModal) refresh();
    // eslint-disable-next-line
  }, [showModal]);

  const handleShow = () => {
    setShowModal(true);
  };

  const sortter = (key, arr, setter) => {
    var tmpArr = arr;
    if (order == "asc") {
        setOrder("desc");
    }
    else {
        setOrder("asc");
    }
    setter(tmpArr.sort(helper.sortObjects(key, order)));
}

  return (
    <>
    <HistoryIcon sx={{fontSize:"25px"}} onClick={handleShow}/>
      {/* <Button variant="dark" className="badge badge-pill" style={{borderRadius:"30px", fontWeight:"bold"}} size={`${isMobile ? "sm" : "md"}`} onClick={handleShow}><HistoryIcon/></Button> */}
      <Modal
        size="lg"
        show={showModal}
        onHide={(e) => setShowModal(false)}
        style={{ backgroundColor: "hsla(0, 0%, 0%, 0.9)" }}
      >
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Scene History</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Row>
              <Form.Group as={Col} xs="3" md="4">
                <Button
                  variant="secondary"
                  size={`${isMobile ? "sm" : "md"}`}
                  onClick={() => refresh()}
                >
                  Refresh
                </Button>
              </Form.Group>
            </Form.Row>
            <Form.Row>
            <Table striped bordered hover size="sm" className='table-responsive'>
                            <thead>
                                <tr>
                                    <th onClick={() => sortter("username", sceneHistory, setSceneHistory)}>Username</th>
                                    <th onClick={() => sortter("time", sceneHistory, setSceneHistory)}>Time</th>
                                    <th onClick={() => sortter("status", sceneHistory, setSceneHistory)}>Status</th>
                                    <th onClick={() => sortter("download", sceneHistory, setSceneHistory)}>Download</th>
                                    <th onClick={() => sortter("timestamp", sceneHistory, setSceneHistory)}>Timestamp</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sceneHistory.map(item => (
                                    <tr key={`${item.timestamp}`}>
                                        <td>{item.username}</td>
                                        <td><Button className="badge badge-pill">{item.totalTime}</Button> 
                                        {item.time.map(t =>(<Button key={t.time} variant="secondary" className="badge badge-pill">{t.min}:{t.sec}</Button> ))}</td>
                                        <td><Button className="badge badge-pill" variant="danger">{item.status}</Button></td>
                                        <td><Button className="badge badge-pill" variant="danger">{item.download}</Button></td>
                                        <td>{item.timestamp}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
            </Form.Row>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
}


export default SceneHistory;

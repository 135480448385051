import React, { useState } from "react";
import { Card, CardDeck, Row, Button, Modal, Container } from "react-bootstrap";

import constants from "../../const";

export default function PositionChooser(props) {
    var token = "";
	if (localStorage.getItem("token") != null) {
		token = localStorage.getItem("token");
	}
	var tokenUrl = `?token=${token.slice(7)}`;
    var imageURL = `${constants.CONTENT_DOMAIN}download/sus-media/positions`;
    var positionCategories = [];
    var colSize = 1;

	const [showModal, setShowModal] = useState(false);
    const categories = props.positionTagList.filter(x => x.category===7)

	const handleShow = () =>{

    setShowModal(true);
    if (!props.isMobile){
        props.setPositionLoc(-10);
    }


    }
	const handleCancel = () => {

        setShowModal(false);
        props.setPositionLoc(0);
    }

	const selectPositiion = (e) => {
        var pos = props.currentPosition;
        var temp = props.positionList.find(x => x.id == e.target.getAttribute('value'))
        pos.position = temp.id
        pos.name = temp.name
        props.select(pos)
        handleCancel()
    };

    if (Array.isArray(categories)){
        // for (var cat = 0; cat < categories.length; cat++){
            var catArray = props.positionList.filter(pos => pos.category.id === categories[0].id)
            catArray.sort((a,b)=>a.order - b.order);
            var i = 0;
            // if(catArray[i].category.id==59){
            var positionCards = [];
            for (var col = 0;col < Math.ceil(catArray.length / colSize);col++) {
                var cardDeck = [];
               
                for (var row = 0; row < colSize; row++) {
                    if(i < catArray.length){

                        if(catArray[i] !== null){
                            cardDeck.push(
                                <Card key={catArray[i].id}>
                                    <div style={{height: "83px", width: "125px", opacity: '1'}} >
                                        <img src={`${imageURL}/${catArray[i].id}.png${tokenUrl}`}
                                            alt={`${catArray[i].id}.png`}
                                            style={{ height: "83px", width: "125px", opacity: '1' }} 
                                            value={catArray[i].id} onClick={selectPositiion}/>
                                    </div>
                                    <div className="scene-card-link auto">
                                        <div className="m-1 card-title h5" style={{ fontSize: "12px" }} value={catArray[i].id} onClick={selectPositiion}>
                                            {catArray[i].name}
                                        </div>
                                    </div>
                                </Card>
                            );
                            i++;
                        }
                    }
                }
                positionCards.push(
                    <CardDeck key={col} style={{ padding: "5px" }}>
                        {cardDeck}
                    </CardDeck>
                );
            }
            
            positionCategories.push(<>
            <Container>
                {/* <Row className="justify-content"><h1>{categories[cat].name} ({catArray.length})</h1></Row> */}
                <Row className="justify-content-center">{positionCards}</Row>
            </Container>
            </>)
        // }
    }

	return (
		<>
			<Button className={`btn btn-sm btn-dark`} onClick={()=>handleShow()}>{props.currentPosition.name}</Button>
			<Modal size="lg" show={showModal} style={{ backgroundColor: "hsla(0, 0%, 0%, 0)", opacity: '0.90', left:`${props.isMobile?"0%":"30%"}`}} onHide={()=>handleCancel()}>
				<Modal.Header closeButton>
					<Modal.Title>Choose Position</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>{positionCategories}</Container>
				</Modal.Body>
			</Modal>
		</>
	);
}

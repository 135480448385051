import { sceneCardActions } from '../../actions/scene/sceneCardActions.js';

const INITIAL_STATE = {
  fetching: {
    scene: false,
    count: false,
    filter: false,
  },
  isEditing: false,
  selectedScene: [],
  error: '',
  openFilter: true,
  sceneFilters: {
    search: '',
    size: 20,
    site: 0,
    page: 0,
    sort: '',
    direction: '',
    models: [],
    tags: [],
    company: 0
  },
  scenes: [{
    "scene": {
      "id": 1,
      "sceneId": "0001",
      "title": "Title",
      "date": "2011-11-06T00:00:00",
      "rating": 70,
      "siteId": 1
    },
    "site": {
      "siteId": 1,
      "shortName": "mi",
      "name": "Site"
    },
    "movie": {
      "id": 1,
      "title": "mi",
      "scenesNumber": "Site"
    },
    "actors": [
      {
        "id": 1,
        "name": "Model"
      }
    ]
  }],
  count: 0,
  sites: {
    available: [],
    selected: null
  },
  models: {
    available: [],
    selected: []
  },
  tags: {
    available: [],
    selected: []
  },
  movies: {
    available: [],
    selected: []
  },
  studio:{
    available: [],
    selected: []
  }
}

const sceneCardReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case sceneCardActions.SCENE_CARD_REQUEST:
      return {
        ...state,
        fetching:{...state.fetching, scene: true }
      };
    case sceneCardActions.SCENE_CARD_SUCCESS:
      return {
        ...state,
        fetching:{...state.fetching, scene: false },
        scenes: action.scenes,
      }

    case sceneCardActions.SCENE_FILTER_REQ:
      return {
        ...state,
        fetching:{...state.fetching, filter: true }
      };
    case sceneCardActions.SCENE_FILTER_SUC:
      return {
        ...state,
        fetching:{...state.fetching, filter: false },
        sites: action.filters.sites,
        movies: action.filters.movies,
        models: action.filters.models,
        tags: action.filters.tags,
        studio: action.filters.studio
      }
    case sceneCardActions.SCENE_CARD_FAILURE:
      return {
        ...state,
        fetching:{...state.fetching, scene: false },
        error: action.error
      }

    case sceneCardActions.SCENE_COUNT_REQUEST:
      return {
        ...state,
        fetching:{...state.fetching, count: true }
      }
    case sceneCardActions.SCENE_COUNT_SUCCESS:
      return {
        ...state,
        fetching:{...state.fetching, count: false },
        count: action.count.total,
      }

    case "OPEN_FILTER":
      return {
        ...state,
        openFilter: action.open
      }
    case "SEARCH":
      return {
        ...state,
        sceneFilters: {
          ...state.sceneFilters,
          search: action.value
        }
      }
    
    case sceneCardActions.SCENE_EDIT_SUCCESS:
        return{
          ...state,
          isEditing: action.isEditing
        }
    case sceneCardActions.SCENE_SELECTION:
      return{
        ...state,
        selectedScene: action.selectedScene,
      }
    
    case sceneCardActions.CLEAR_SELECTED_SCENE:
      return{
        ...state,
        selectedScene: []
      }
    

    default:
      return state;
  }
};

export default sceneCardReducers;
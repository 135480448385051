import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Col, InputGroup, Modal, Button } from 'react-bootstrap';
import Select from "react-dropdown-select";

import { siteActions } from '../../actions/scene/siteActions';
import { movieActions } from '../../actions/movie/movieActions';
import constants from '../../const';

function NewMovie(props) {
    var isMobile = window.innerWidth <= constants.mobileSize;
    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [firstTime, setFirstTime] = useState(true);
    const [movie, setMovie] = useState({
        site: 0,
        title: "",
        date: "2019-01-01",
        description: "",
        sceneNumber: 0
    })
    const [table, setTable] = useState(null);
    const [order, setOrder] = useState("asc");
    const [isVisible, setIsVisble] = useState(false);


    const refreshData = () => {
        props.getAllSite();
    }
    const handleCancel = () => setShow(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        props.postMovie(movie);
    }
    const handleShow = () => {
        setShow(true)
        if (firstTime) {
            refreshData();
            setFirstTime(false);
        }
    };

    return <>
        <Button variant="info" size={`${isMobile ? 'sm' : 'md'}`} onClick={handleShow}>Movie</Button>
        <Modal size="lg" show={show} onHide={handleCancel} style={{backgroundColor:'hsla(0, 0%, 0%, 0.9)'}}>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>New Movie Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row>
                        <Form.Group as={Col} md="6" controlId="validationTitle">
                            <Form.Label htmlFor="inlineFormInputGroup" srOnly>Title</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Title</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control required type="text" placeholder="Title" defaultValue={movie.title} onChange={(e) => setMovie({ ...movie, title: e.target.value })} />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Please choose a valid Title</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationMinute">
                            <Form.Label htmlFor="inlineFormInputGroup" srOnly>Number of Scene</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Number of Scenes</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="text" placeholder="Minutes" defaultValue={movie.sceneNumber} onChange={(e) => setMovie({ ...movie, sceneNumber: e.target.value })} />
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="6" controlId="validationSite">
                            <Form.Label htmlFor="inlineFormInputGroup" srOnly>Site</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Site</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Select style={{ minWidth: "300px" }} options={props.allSite} placeholder="+ click to select Site" labelField="name" valueField="id"
                                    color="#000000" searchable="true" sortBy="name" clearable="true" searchBy="name" dropdownHeight="250px" dropdownPosition="auto"
                                    values={[]} onChange={e => setMovie({ ...movie, site: e[0].id })} />
                            </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                            <Form.Label htmlFor="inlineFormInputGroup" srOnly>Date</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Date (YYYY-MM-DD)</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="text" placeholder="date" defaultValue={movie.date} onChange={(e) => setMovie({ ...movie, date: e.target.value })} />
                            </InputGroup>
                        </Form.Group>

                    </Form.Row>
                    <Form.Row >
                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                            <Form.Label htmlFor="inlineFormInputGroup" srOnly>Description</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Description</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control style={{ height: "100px" }} as="textarea" aria-label="With textarea" defaultValue={movie.description} onChange={(e) => setMovie({ ...movie, description: e.target.value })} />
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>

                </Modal.Body>
                <Modal.Footer>
                    <div className="mr-auto"> <Button className="mr-auto" variant="secondary" onClick={refreshData}>Refresh</Button></div>
                    <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleSubmit}>Save</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    </>;
}

const mapStateToProps = (state) => {
    return {
        allSite: state.scenes.site.allSite,
        allMovie: state.movies.movies,
        error: state.scenes.status.error
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllSite: () => dispatch(siteActions.getAllSite()),
        postMovie: (movie) => dispatch(movieActions.postMovie(movie)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(NewMovie);
import axios from 'axios';
import constants from '../../const.js';

const MOVIE_REQ = "MOVIE_REQ";
const ALL_MOVIE_SUC = "MOVIE_SUC";
const MOVIE_ERR = "MOVIE_ERR";
const POST_MOVIE = "POST_MOVIE";

const movie_REQ = () => {
    return {
        type: MOVIE_REQ
    };
};


const allMovie_SUC = (allMovies) => {
    return {
        type: ALL_MOVIE_SUC,
        movies: allMovies
    };
}
const postMovie_SUC = (movie) => {
    return {
        type: POST_MOVIE,
        movie: movie
    };
}
const movie_ERR = (error) => {
    return {
        type: MOVIE_ERR,
        error: error
    };
};

const getAllMovies = () => {
    return dispatch =>{
        dispatch(movie_REQ());
        return axios.get(constants.DATA_API.MOVIE_URL)
        .then(res =>dispatch(allMovie_SUC(res.data)))
        .catch(error=> dispatch(movie_ERR(error)))
    }
}
const updateMovie = (movieID, movie) =>{
    return dispatch =>{
        // dispatch(movie_REQ());
        return axios.put(`${constants.DATA_API.MOVIE_URL}/${movieID}`,movie)
        .catch(err=>dispatch(movie_ERR(err)));
    };
};

const postMovie = (movie) =>{
    return dispatch =>{
        dispatch(movie_REQ());
        return axios.post(`${constants.DATA1_API_URL}/scenes/movies`,movie)
        .then(res=>{
            dispatch(postMovie_SUC(res.data))
        })
        .catch(err=>dispatch(movie_ERR(err)));
    };
};

export const movieActions = {
    MOVIE_REQ,
    ALL_MOVIE_SUC,
    MOVIE_ERR,
    POST_MOVIE,
    getAllMovies,
    updateMovie,
    postMovie
};


import React, { useState, useEffect, useContext } from 'react';
import "./Row.css";
import constants from '../../const.js';
import { TvContext } from '../../context/TvContext';

function Card({ show, openInfo }) {

    return <div style={{ width: "300px" }}>
        <img key={show.CID}
         onClick={() => openInfo(show)}
            className={`row__poster row__posterLarge`}
            src={`${constants.POSTER_BASE}/${show.poster_path}`}
            alt={show.name} />
        <div>
            <h1 style={{ fontSize: "14px" }}>{show.name}</h1>
        </div>
    </div>

}


const SeasonSelector = ({ seasons }) => {

    const { tvState, setTvState } = useContext(TvContext);

    const selectSeason = (season) => {
        setTvState({
            ...tvState,
            show: {
                ...tvState.show,
                showSeason: true,
                selectedSeason: season
            }
        })
    }

    var seasonRow = [];
    var i = 0;
    for (var col = 0; col < seasons.length; col++) {
        if (seasons[i].season_number != 0) {
            seasonRow.push(<Card show={seasons[i]} openInfo={selectSeason} key={i} />);
        }
        i++;

    }



    return <>
        <div className="row_show">
            <div className="row__posters">

                {seasonRow}
                {/* {seasons.map((season) => (<>{season.season_number!=0?
                    <div key={season.id} className="row__poster" style={{ maxheight: '300px', width: '100%' }} onClick={()=>selectSeason(season)}>
                        <img  className="poster" src={`${constants.POSTER_BASE}/${season.poster_path}`} />
                        <div className="season_name">{season.name}</div>
                    </div>:<></>}</>
                ))
                } */}
            </div>
        </div>
    </>
}

export default SeasonSelector;
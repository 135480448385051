import axios from 'axios';
import constants from '../../const.js';

const SCENE_HIST_REQUEST = "SCENE_HIST_REQUEST";
const SCENE_HIST_SUCCESS = "SCENE_HIST_SUCCESS";
const SCENE_HIST_FAILURE = "SCENE_HIST_FAILURE";

const sceneCardRequest = () =>{
    return{
        type: SCENE_HIST_REQUEST
    };
};

const sceneCardSuccess = (scenes) =>{
    return{
        type: SCENE_HIST_SUCCESS,
        scenes: scenes.scenes,
        // sites: scenes.sites,
        // models: scenes.models,
        // tags: scenes.tags,
        count: scenes.count
    };
};

const sceneCardFailure = (error) =>{
    return{
        type: SCENE_HIST_FAILURE,
        error
    };
};

const setOpenFilter = (val) => {
    return{
        type: "OPEN_FILTER",
        open: val
    }
}

const setSceneFilter =  (type, val) =>{
    return{
        type: type,
        value: val
    }
}

const getScenes = () =>{    
    return dispatch =>{
        dispatch(sceneCardRequest());
        return axios.get(constants.USER_API.SCENE_HISTORY_URL,{
            headers:{ 'Authorization' : `${localStorage.getItem('token')}` }
        })
        .then(res => {
            dispatch(sceneCardSuccess(res.data));
        })
        .catch(error=> dispatch(sceneCardFailure(error)))
    };
};

export const userHistSceneActions = {
    SCENE_HIST_REQUEST,
    SCENE_HIST_SUCCESS,
    SCENE_HIST_FAILURE,
    getScenes,
    setOpenFilter,
    setSceneFilter
};
import axios from 'axios';
import constants from '../../const.js';

const SIGNUP_REQUEST = "SIGNUP_REQUEST";
const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
const SIGNUP_FAILURE = "SIGNUP_FAILURE";

const signupRequest = () =>{
    return{
        type: SIGNUP_REQUEST
    };
};

const signupSuccess = (token, user) => {
    return{
        type: SIGNUP_SUCCESS,
        token: token,
        user: user
    }
}

const signupFailure = (error) => {
    return{
        type: SIGNUP_FAILURE,
        error: error
    }
}

const signup = (firstname, lastname, username, email, password) => {
    return dispatch =>{
        dispatch(signupRequest());
        return axios.post(constants.USER_API.SIGNUP_URL, {
            firstname: firstname,
            lastname: lastname,
            username: username,
            email: email,
            password: password
        })
        .then(res => {
            localStorage.setItem('token', res.data.token)
            dispatch(signupSuccess(res.data.token, res.data));
        })
        .catch(error=> dispatch(signupFailure(error.response.data.msg)));
    };
};

export const signupActions ={
    SIGNUP_REQUEST,
    SIGNUP_SUCCESS,
    SIGNUP_FAILURE,
    signup
}
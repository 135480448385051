import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Form, Col, InputGroup, Modal, Button, Row, Table } from "react-bootstrap";
import axios from "axios";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Select from "react-dropdown-select";

import { siteActions } from "../../actions/scene/siteActions";
import constants from "../../const";
import { helper } from "../common/helper.js";

export default function NewPlaylist(props) {
  var isMobile = window.innerWidth <= constants.mobileSize;
  var token = localStorage.getItem("token");
  const [show, setShow] = useState(false);
  const [privacy, setPrivacy] = useState([]);
  const [playlistInfo, setPlaylistInfo] = useState({
    title: "",
    tags: [],
    privacy: 1,
  });

  const fetchPrivacyTypeAPI = () => {
    if (token != null) {
      axios
        .get(`${constants.USER_API.SCENE_PLAYLIST_URL}/privacy`, {
          headers: { Authorization: `${token}` },
        })
        .then((res) => {
          setPrivacy(res.data);
        });
    }
  };

  const createPlaylistAPI = () => {
    if (token != null) {
      axios
        .post(
          `${constants.USER_API.SCENE_PLAYLIST_URL}`,
          playlistInfo,
          {
            headers: { Authorization: `${token}` },
          }
        )
        .then((res) => {
          setPrivacy(res.data);
        });
    }
  };

  useEffect(() => {
    fetchPrivacyTypeAPI();
    // eslint-disable-next-line
  }, []);

  const handleCancel = () => {
    setShow(false);
  };

  const handleSave = () => {
    createPlaylistAPI()
    setShow(false);

  };

  const handleShow = () => {
    setShow(true);
  };

  var privacySelect = React.forwardRef(() => (
    <select
      className="custom-select custom-select-sm"
      value={playlistInfo.privacy}
      onChange={(e) =>
        setPlaylistInfo({ ...playlistInfo, privacy: parseInt(e.target.value) })
      }
    >
      {privacy.map((pv) => (
        <option key={pv.privacy_id} value={pv.privacy_id}>
          {pv.privacy_name}
        </option>
      ))}
    </select>
  ));

  return (
    <>
      <Fab
        size="small"
        color="primary"
        aria-label="add"
        style={{ position: "fixed", bottom: "30px", right: "30px" }}
      >
        <AddIcon onClick={handleShow} />
      </Fab>
      <Modal
        size="lg"
        show={show}
        onHide={handleCancel}
        style={{ backgroundColor: "hsla(0, 0%, 0%, 0.9)" }}
      >
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Create a new Playlist</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Row>
              <Form.Group as={Col} md="10" controlId="validationTitle">
                <Form.Label srOnly>Title</Form.Label>
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Title</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    required
                    type="text"
                    placeholder=""
                    defaultValue={playlistInfo.title}
                    onChange={(e) =>
                      setPlaylistInfo({
                        ...playlistInfo,
                        title: e.target.value,
                      })
                    }
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please choose a valid Title
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xs="4" controlId="validationPrivacy">
                <Form.Label srOnly>Privacy</Form.Label>
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Privacy</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control as={privacySelect} required custom />
                </InputGroup>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="4" controlId="validationTags">
                <Form.Label srOnly>Tags</Form.Label>
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Tags</InputGroup.Text>
                    <Select style={{ minWidth: "260px" }} multi placeholder="+ add tag" values={[]}
                                    color="#000000" searchable="true"  clearable="true" 
                                    dropdownHandleRenderer={({ state }) => (<span>{state.dropdown ? '–' : '+'}</span>)} 
                                    onChange={e=> setPlaylistInfo({...playlistInfo, tags: [...playlistInfo.tags, e[e.length-1].value]})} create="true"/>
                  </InputGroup.Prepend>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please choose a valid Title
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Form.Row>
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleSave}>Save</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

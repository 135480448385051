import React, { useEffect, useState, useRef, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';

import constants from '../../const.js';
import queryString from 'query-string';
import { Redirect } from 'react-router';
import useWindowSize from '../common/useWindowSize';
import axios from 'axios';
import SpinnerLoad from '../common/spinner.js';
import { TvContext } from '../../context/TvContext.js';
import Session from '../chat/Session.js';
import { nullLiteralTypeAnnotation } from '@babel/types';
import ContentRequest from './ContentRequest.js';
import fullScreenIcon from './full-screen-icon-27.jpg';


const MediaPlayer = (props) => {

    const video = useRef(null);
    const group = useRef(null);
    const history = useHistory();

    const { tvState, setTvState } = useContext(TvContext);

    const [episode, setEpisode] = useState(null);
    const [episodeInfo, setEpisodeInfo] = useState(null);
    const [showInfo, setShowInfo] = useState(true);
    const [showTime, setShowTime] = useState(null);
    const [showAutoPlay, setShowAutoPlay] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);
    const [counter, setCounter] = useState(15);

    var params = queryString.parse(props.location.search);
    const size = useWindowSize();
    var token = ''
    if (localStorage.getItem('token') != null) {
        token = `?token=${localStorage.getItem('token').slice(7)}`;
    }

    const fetchEpisode = async (tv_id, season_number, episode_number) => {
        const request = await axios.get(`${constants.MOVIE_DB_API}/tv/${tv_id}/season/${season_number}/episode/${episode_number}${constants.MOVIE_DB_KEY}`);
        setEpisode(request.data);
        return request;
    }
    const fetchEpisodeInfo = async (tv_id, season_number, episode_number) => {
        const request = await axios.get(`${constants.TRAKT_API_URL}/shows/${tv_id}/s/${season_number}/e/${episode_number}`);
        setEpisodeInfo(request.data);
        if(request.data.credit == null){
            let data = request.data;
            data.credit = request.data.duration-10;
            setEpisodeInfo(data)
        }
        return request;
    }
    const fetchUserShowProgress = async () => {
        await axios.get(`${constants.USER_API.USER_API_URL}/shows/${params.tid}/season/${params.season}/episode/${params.episode}`,
            {
                headers: { 'Authorization': `${localStorage.getItem('token')}` }
            }).then(data => setShowTime(data.data)).catch(err => setShowTime({ time: 0 }));
    }

    const updateUserShowProgress = async () => {
        var vid = document.getElementById("video");
        await axios.post(`${constants.USER_API.USER_API_URL}/shows/${params.tid}/season/${params.season}/episode/${params.episode}`, { time: vid.currentTime },
            {
                headers: { 'Authorization': `${localStorage.getItem('token')}` }
            });
    }

    const fetchMovieProgress = async () => {
        await axios.get(`${constants.USER_API.USER_API_URL}/movies/${params.mid}`,
            {
                headers: { 'Authorization': `${localStorage.getItem('token')}` }
            }).then(data => setShowTime(data.data)).catch(err => setShowTime({ time: 0 }));
    }

    const updateUserMovieProgress = async () => {
        var vid = document.getElementById("video");
        await axios.post(`${constants.USER_API.USER_API_URL}/movies/${params.mid}`, { time: vid.currentTime },
            {
                headers: { 'Authorization': `${localStorage.getItem('token')}` }
            });
    }

    const onPlay = () => {
        if (params.type === 's') {
            updateUserShowProgress();
        }
        else {
            updateUserMovieProgress()
        }
        setShowInfo(false);
        setTvState({
            ...tvState,
            media: {
                ...tvState.media,
                playing: true,
                time: video.current.currentTime
            }
        })
    }

    const onPause = () => {
        if (params.type == 's') {
            updateUserShowProgress();
        }
        setShowInfo(true);
        setTvState({
            ...tvState,
            media: {
                ...tvState.media,
                playing: false,
                time: video.current.currentTime
            }
        })
    }

    const goFullScreen = () => {
        if (fullScreen) {
            closeFullScreen()
            setFullScreen(false)
        }
        else {
            openFullScreen()
            setFullScreen(true);
        }

    }
    const openFullScreen = () => {
        var elem = document.documentElement;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
        }
    }
    const closeFullScreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
            document.msExitFullscreen();
        }
    }

    const timeUpdate = () => {
        if (video.current?.currentTime > episodeInfo.credit) {
            setShowInfo(true);
            setShowAutoPlay(true);
            setTvState({
                ...tvState,
                media: {
                    ...tvState.media,
                    playing: false,
                    time: video.current.currentTime
                }
            })
        }
        else {
            setShowAutoPlay(false);
        }
    }

    useEffect(()=>{
        if(counter <= 0){
            history.push(`/player?type=s&show=${params.show}&season=${episodeInfo.nextSeason}&episode=${episodeInfo.nextEpisode}&tid=${params.tid}`)
            window.location.reload(true)
        }
    },[counter])

    useEffect(() => {
        setCounter(10)
    }, [showAutoPlay])


    useEffect(() => {
        if (showAutoPlay) {
            const timer =
                counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
            return () => {
                clearInterval(timer);
            }
        }
    }, [showAutoPlay, counter])

    useEffect(() => {
        if (token != '' && params.type === 's') {
            fetchEpisode(params.tid, params.season, params.episode);
            fetchEpisodeInfo(params.tid, params.season, params.episode);
            fetchUserShowProgress();
            return () => {
                updateUserShowProgress();
            }
        }
        else if (token != '' && params.type === 'm') {
            fetchMovieProgress();
            return () => {
                updateUserMovieProgress();
            }
        }
    }, [])

    // useEffect(() => {
    //     if (token != '' && params.type === 's') {
    //         fetchEpisode(params.tid, params.season, params.episode);
    //         fetchEpisodeInfo(params.tid, params.season, params.episode);
    //         fetchUserShowProgress();
    //         return () => {
    //             updateUserShowProgress();
    //         }
    //     }
    // }, [params.episode])

    return (
        <div style={{ height: showInfo ? size.height - 56 : size.height, backgroundColor: '#111' }}>
            {token == '' ? <Redirect to='/login' /> : null}
            {showTime != null && episodeInfo != nullLiteralTypeAnnotation ?
                <div style={{ display: 'flex', backgroundColor: 'black', width: "100%", height: "100%" }} ref={group}>

                    <div style={{ position: 'relative', width: tvState.groupChat.active ? group.current?.offsetWidth - 250 : '100%' }}>

                        <video ref={video} controlsList="nodownload" id="video" onTimeUpdate={timeUpdate}
                            playsInline={true}
                            onPlaying={() => onPlay()} onPause={() => onPause()} controls style={{ width: "100%", height: "100%" }} preload="metadata">
                            {params.type == 's' ?
                                <source src={`${constants.CONTENT_DOMAIN}Shows/${params.show}/S${params.season.padStart(2, "0")}/${params.show}.S${params.season.padStart(2, "0")}E${params.episode.padStart(2, "0")}.mp4${token}#t=${showTime.time}`} /> :
                                <source src={`${constants.CONTENT_DOMAIN}Movies/${params.movie}.mp4${token}#t=${showTime.time}`} />}
                            {params.type == 's' ?
                                <track src={`${constants.CONTENT_DOMAIN}Shows/${params.show}/S${params.season.padStart(2, "0")}/sub/${params.show}.S${params.season.padStart(2, "0")}E${params.episode.padStart(2, "0")}.vtt${token}`} kind="subtitles" srcLang="en" label="English" /> :
                                <track src={`${constants.CONTENT_DOMAIN}Movies/${params.movie}/2_English.vtt${token}`} kind="subtitles" srcLang="en" label="English" />}
                        </video>


                        {episode != null && episodeInfo != null && showInfo ?
                            <div style={styleTitle}>
                                <h1>{episodeInfo?.title}</h1>
                                <h2>S{params.season} : E{params.episode}</h2>
                                <h3> {episode.name}</h3>
                            </div> : null}

                        {episode != null && episodeInfo != null && showInfo && !showAutoPlay ?
                            <div style={stylePlayButton}>
                                {episodeInfo.nextEpisode == 0 ? null : <Link to={`/player?type=s&show=${params.show}&season=${episodeInfo.nextSeason}&episode=${episodeInfo.nextEpisode}&tid=${params.tid}`}><button className="banner__button">NEXT <h3>S{episodeInfo.nextSeason} : E{episodeInfo.nextEpisode}</h3></button></Link>}
                            </div> : null}
                        {episode != null && episodeInfo != null && showInfo && showAutoPlay ?
                            <div style={styleAutoPlay}>
                                {episodeInfo.nextEpisode == 0 ? null : <Link to={`/player?type=s&show=${params.show}&season=${episodeInfo.nextSeason}&episode=${episodeInfo.nextEpisode}&tid=${params.tid}`}>
                                    <button className="autoplay_button">Next Up in 
                                    <h1>{counter}</h1>
                                    <h3>S{episodeInfo.nextSeason}:E{episodeInfo.nextEpisode}</h3>
                                       </button></Link>}
                            </div> : null}

                        {params.type == 's' && showInfo ?
                            <div style={styleDownload}>
                                <a href={`${constants.CONTENT_DOMAIN}download/Shows/${params.show}/S${params.season.padStart(2, "0")}/${params.show}.S${params.season.padStart(2, "0")}E${params.episode.padStart(2, "0")}.mp4${token}`}><button className="banner__button"><h3>Download</h3></button></a>
                            </div> : null}
                        {params.type == 'm' && showInfo ?
                            <div style={styleDownload}>
                                <a href={`${constants.CONTENT_DOMAIN}download/Movies/${params.movie}.mp4${token}`}><button className="banner__button"><h3>Download</h3></button></a>
                            </div> : null
                        }
                        {showInfo ?
                            <div style={styleFullScreen}>
                                <button className="banner__button" onClick={() => goFullScreen()}><img src={fullScreenIcon} style={{ height: "30px", width: "30px" }} /></button>
                            </div> : null}

                    </div>
                    <ContentRequest />
                    {/* <Session showInfo={showInfo} player={true} height={size.height} info={params} video={video} /> */}
                </div>
                : <SpinnerLoad />}
        </div>)
}

const styleTitle = {
    position: 'absolute',
    color: 'white',
    top: '20px',
    left: '30px',
    fontSize: '30px',
    fontWeight: 'bold',
}

const styleAutoPlay = {
    position: 'absolute',
    color: 'white',
    right: '50%',
    left: '50%',
    bottom: '10%',
    fontWeight: 'bold',
}

const stylePlayButton = {
    position: 'absolute',
    color: 'white',
    top: '80px',
    right: '50px',
    fontWeight: 'bold',
}
const styleDownload = {
    position: 'absolute',
    color: 'white',
    top: '20px',
    right: '50px',
    fontWeight: 'bold',
}
const styleFullScreen = {
    position: 'absolute',
    color: 'white',
    bottom: '100px',
    right: '50px',
    fontWeight: 'bold',
}


export default MediaPlayer;

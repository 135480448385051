
import { sceneActions } from '../../actions/scene/sceneActions.js';

const INITIAL_STATE = {
  isFetching: false,
  error: '',
  isFetchingUserInfo: false,
  userInfo: {
    time: 0
  },
  scene:
  {
    scene: {
      scene: {
        id: 0,
        sceneId: "0000",
        title: "title",
        date: "2009-08-03T00:00:00",
        rating: 87,
        siteId: 37,
        description: "description as",
        videoURL: "",
        posterURL: "",
        status: 7,
        vrType: "STEREO_180_LR"
      },
      tags: [{
        tagId: 12,
        tag: "Kitchen",
        category: "Location",
        location: "NA"
      }]
    },
    site: {
      siteId: 37,
      shortName: "rws",
      name: "site"
    },
    actors: [],
    movie: {
      id: 0,
      title: "None"
    },
  },
  isFetchingSceneTag: false,
  sceneTag: [
    {
      tagId: 12,
      tag: "Kitchen",
      category: "Location",
      location: "NA"
    }],
  isFetchingTags: false,
  tags: [{
    id: 1,
    name: "Outdoors",
    category: "Location",
    bodyLocation: "NA",
    color: "NA",
    model: "NA",
    model_id: "NA"
  }],
  isFetchingPositions: false,
  positions:[
    {
      id: 1,
      name: "Cowgirl",
      control: {
        id: 15,
        name: "Woman"
      },
      entry: {
        id: 15,
        name: "Woman"
      },
      category: {
        id: 15,
        name: "Woman"
      },
      tags: []
    }
  ],
  isFetchingPositionTags: false,
  positionsTags:[
    {id: 1,
    name: "Breast Access"}],
  isFetchingColors: false,
  colors:[{
    id: 1,
    color_name: "black"
  }],
  isFetchingStatuses: false,
  statuses: [{
    id: 1,
    name: "IPP"
  }],
  relatedScenes: {
    isFetching: true,
    models: [],
    site: [],
    movie: []
  }
}

const sceneReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case sceneActions.SCENE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: ''
      };
    case sceneActions.SCENE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        scene: action.scene,
        error: ''
      }
    case sceneActions.SCENE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    case sceneActions.COLOR_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: ''
      };
    case sceneActions.COLOR_SUCCESS:
      return{
        ...state,
        isFetchingColors: false,
        colors: action.colors,
        error: ''
      }
    case sceneActions.COLOR_FAILURE:
      return{
        ...state,
        isFetchingColors: false,
        error: action.error
      }
    case sceneActions.SCENETAG_REQUEST:
      return {
        ...state,
        isFetchingTags: true
      };
    case sceneActions.SCENETAG_SUCCESS:
      return {
        ...state,
        isFetchingTags: false,
        tags: action.tags
      }
    case sceneActions.SCENETAG_FAILURE:
      return {
        ...state,
        isFetchingTags: false,
        error: action.error
      }
    case sceneActions.ONESCENETAG_REQUEST:
      return {
        ...state,
        isFetchingSceneTag: true
      }
    case sceneActions.ONESCENETAG_SUCCESS:
      return {
        ...state,
        isFetchingSceneTag: false,
        sceneTag: action.tags
      }
    case sceneActions.ONESCENETAG_FAILURE:
      return {
        ...state,
        isFetchingSceneTag: false,
        error: action.error
      }
    case sceneActions.REL_SCENE_CARD_REQUEST:
      return {
        ...state,
        relatedScenes: {
          ...state.relatedScenes,
          isFetching: true
        }
      };
    case "REL_SCENE_SUCCESS":
      return {
        ...state,
        relatedScenes: {
          ...state.relatedScenes,
          isFetching: false
        }
      };
    case sceneActions.REL_SCENE_CARD_SUCCESS:
      return {
        ...state,
        relatedScenes: {
          ...state.relatedScenes,
          isFetching: true,
          models: [...state.relatedScenes.models, action.models],
        },
      }

    case sceneActions.REL_SITE_SCENE_CARD_SUCCESS:
      return {
        ...state,
        relatedScenes: {
          ...state.relatedScenes,
          isFetching: true,
          site: action.site
        },
      }

    case sceneActions.REL_MOVIE_SCENE_CARD_SUCCESS:
      return {
        ...state,
        relatedScenes: {
          ...state.relatedScenes,
          isFetching: true,
          movie: action.movie
        },
      }
    case sceneActions.REL_SCENE_CARD_RESET:
      return {
        ...state,
        relatedScenes: {
          isFetching: true,
          models: [],
          site: [],
          movie: []
        },
      }
    case sceneActions.REL_SCENE_CARD_FAILURE:
      return {
        ...state,
        relatedScenes: {
          ...state.relatedScenes,
          isFetching: true,
          error: action.error
        }
      }
    case sceneActions.POSITION_TAG_REQUEST:
        return {
          ...state,
          isFetchingPositionTags: true,
          error: ''
        };
    case sceneActions.POSITION_TAG_SUCCESS:
        return{
          ...state,
          isFetchingPositionTags: false,
          positionTags: action.positionTags,
          error: ''
        }
    case sceneActions.POSITION_TAG_FAILURE:
        return{
          ...state,
          isFetchingPositionTags: false,
          error: action.error
        }
    case sceneActions.POSITION_REQUEST:
          return {
            ...state,
            isFetchingPosition: true,
            error: ''
          };
    case sceneActions.POSITION_SUCCESS:
          return{
            ...state,
            isFetchingPosition: false,
            positions: action.positions,
            error: ''
          }
    case sceneActions.POSITION_FAILURE:
          return{
            ...state,
            isFetchingPosition: false,
            error: action.error
          }
    default:
      return state;
  }
};

export default sceneReducers;
import React, { useState, useContext } from 'react';
import "./ContentInfo.css";
import { Modal } from 'react-bootstrap';
import constants from '../../const.js';
import { Link } from 'react-router-dom';
import SeasonSelector from './SeasonSelector';
import EpisodeSelector from './EpisodeSelector';
import { TvContext } from '../../context/TvContext';


const MovieInfo = (props) => {

    // const {tvState, setTvState}= useContext(TvContext);
    const [show, setShow] = useState(props.show)


    const closeModal = () => {
        setShow(false)
        props.close();
    }
    return <>
        <Modal show={show} onHide={closeModal} size="lg">
            <div style={{
                backgroundImage: `url(${constants.BACKDROP_BASE}${props.data.info.backdrop_path})`, backgroundPosition: 'center',
                backgroundSize: '100% 100%', height: '400px'
            }}>
                <div className="title_background">
                    <h5 className="title_font">{props.data.info.title}</h5>
                </div>
                <div style={{position: 'absolute', top: '350px', left: '40px'}}>
                    <Link style={{ color: 'inherit', textDecoration: 'inherit' }} to={{
                        pathname: '/player',
                        search: `?type=m&movie=${props.data.location}&mid=${props.data.CID}`,
                    }}> <button className="banner__button">PLAY</button></Link>
                </div>

            </div>
            <div className="info_panel">

                <div className="info_year">
                    <div className="last_year"> {props.data.info.release_date.slice(0, -6)}</div>
                    {/* <div className="duration">{props.data.info.number_of_seasons} Seasons</div> */}
                </div>
                <div className="info_description">{props.data.info.overview}</div>


                <div className="info_tag">
                    {/* actor:sa */}

                </div>
            </div>
            <div className="season_selector">
                {/* <h3>Play</h3> */}
                {/* <SeasonSelector seasons={props.data.info.seasons}/> */}
            </div>
            {/* <EpisodeSelector/> */}

            {/* <Modal.Header closeButton className="dark"
            style={{backgroundImage:`url(${constants.POSTER_BASE}/${props.data.info.backdrop_path})`,
            backgroundPosition: 'center',
            backgroundSize:'100% 100%'}}>
                <Modal.Title>{props.data.info.name}</Modal.Title>
                <img src={`${constants.POSTER_BASE}/${props.data.info.backdrop_path}`} width="500"/>
            </Modal.Header> */}
            {/* <Modal.Body closeButon className="dark">

            </Modal.Body> */}
        </Modal>
    </>
}

export default MovieInfo
import { userDataActions } from '../../actions/scene/userDataActions.js';

const INITIAL_STATE = {
    isFetching: false,
    favorite:{
        isFetching: false,
        enabled: false
    },
    like:{
        isFetching: false,
        enabled: false
    },
    dislike:{
        isFetching: false,
        enabled: false
    },
    blasted:{
        isFetching: false,
        enabled: false
    },
    history:{
        isFetching: false,
        time: 0,
    },
    error:''
}

const userDataReducers = (state = INITIAL_STATE, action) =>{
    switch(action.type){
        case userDataActions.SCENE_U_INFO_REQ:
            return {
                ...state,
                favorite:{...state.favorite,
                isFetching: true }
            };
        case userDataActions.SCENE_U_INFO_SUC:
            return{
                ...state,
                isFetching:false,
                favorite:{...state.favorite,
                    enabled: action.res.favorite},
                like:{...state.like,
                    enabled: action.res.like},
                dislike:{...state.dislike,
                    enabled: action.res.dislike},
                history:{...state.history,
                    time: action.res.time}    
            }
        case userDataActions.SCENE_U_INFO_ERR:
            return{
                ...state,
                isFetching: false,
                error: action.error
            }


        case userDataActions.FAV_REQ:
            return{
                ...state,
                isFetching: true,
                favorite:{...state.favorite,
                    isFetching: true},
            }          
        case userDataActions.FAV_SUC:
            return{
                ...state,
                isFetching: false,
                favorite:{...state.favorite,
                    isFetching: false,
                    enabled: action.enabled},
            }      
        case userDataActions.FAV_ERR:
            return{
                ...state,
                isFetching: false,
                favorite:{...state.favorite,
                    isFetching: false},
                error: action.error
            }

        case userDataActions.HIST_REQ:
            return{
                ...state,
                isFetching: true,
                history:{...state.history,
                    isFetching: true
                }
            }          
        case userDataActions.HIST_SUC:
            return{
                ...state,
                isFetching: false,
                history:{...state.history,
                    isFetching: false
                },
            }      
        case userDataActions.HIST_ERR:
            return{
                ...state,
                isFetching: false,
                history:{...state.history,
                    isFetching: false
                },
                error: action.error
            }
        case userDataActions.LIKE_REQ:
            return{
                ...state,
                isFetching: true,
                like:{...state.like,
                    isFetching: true},
            }          
        case userDataActions.LIKE_SUC:
            return{
                ...state,
                isFetching: false,
                like:{...state.like,
                    isFetching: false,
                    enabled: action.enabled},
            }  
        case userDataActions.DISLIKE_SUC:
            return{
                ...state,
                isFetching: false,
                dislike:{...state.dislike,
                    isFetching: false,
                    enabled: action.enabled},
            }          
        case userDataActions.LIKE_ERR:
            return{
                ...state,
                isFetching: false,
                like:{...state.like,
                    isFetching: false},
                error: action.error
            }
        default:
            return state;
    }
};

export default userDataReducers;

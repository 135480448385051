import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({component: Component, authenticated, perm, ...rest}) => {

    return (
      <> 
        {authenticated && perm ?   <Route {...rest} render= { props => <Component {...rest} {...props}/>} />: <></>}
        {/* {authenticated && !perm ?  <Route {...rest} render= { props => <Redirect to={{pathname: '/profile', state: {from: props.location}}} {...props}/>} /> : <></>} */}
        {!authenticated && !perm ? <Route {...rest} render= { props => <Redirect to={{pathname: '/login', state: {from: props.location}}} {...props}/>} /> : <></>}
        {!authenticated && perm ?  <Route {...rest} render= { props => <Redirect to={{pathname: '/login', state: {from: props.location}}} {...props}/>} /> : <></>}
        </>
    );
};

export default PrivateRoute;
import { actorTagActions } from '../../actions/actor/actorTagActions.js';

const INITIAL_STATE = {
    isFetching: false,
    isFetching_tagType: false,
    error: '',
    tags: [{
        "tagId": 5,
        "name": "Unknown",
        "tagType": "Unknown"
    }],
    tagTypes: []
}

const actorTagReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actorTagActions.ACTOR_TAGS_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case actorTagActions.ACTOR_TAGS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                tags: action.tags
            }
        case actorTagActions.ACTOR_TAGS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }


        case actorTagActions.ACTOR_TAG_TYPE_REQUEST:
            return {
                ...state,
                isFetching_tagType: true
            };
        case actorTagActions.ACTOR_TAG_TYPE_SUCCESS:
            return {
                ...state,
                isFetching_tagType: false,
                tagTypes: action.tagType
            }
        case actorTagActions.ACTOR_TAG_TYPE_FAILURE:
            return {
                ...state,
                isFetching_tagType: false,
                error: action.error
            }

        default:
            return state;
    }
};

export default actorTagReducers;
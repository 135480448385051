import React, { useContext, useState, useEffect } from 'react';
import { TvContext } from '../../context/TvContext';
import "./ContentInfo.css";
import constants from '../../const.js'
import axios from 'axios';
import { Link } from 'react-router-dom';


const EpisodeSelector = () => {

    const { tvState } = useContext(TvContext);
    const [episodes, setEpisodes] = useState(null);
    const [episodesProgress, setEpisodesProgress] = useState(null);
    const [files, setFiles] = useState(null);

    var url = "/player";

    var token = ''
    if (localStorage.getItem('token') != null) {
        token = `?token=${localStorage.getItem('token').slice(7)}`;
    }

    const fetchSeason = async (tv_id, season_number) => {
        await axios.get(`${constants.MOVIE_DB_API}/tv/${tv_id}/season/${season_number}${constants.MOVIE_DB_KEY}`).then(
            res => setEpisodes(res.data)
        ).catch(err => setEpisodes([]))
    }

    const fetchSeasonFiles = async (tv_id, season_number) => {
        await axios.get(`${constants.TRAKT_API_URL}/shows/${tv_id}/s/${season_number}`).then(
            res => setFiles(res.data)
        ).catch(err => setEpisodes([]))
    }

    const fetchSeasonProgress = async (tv_id, season_number) => {
        await axios.get(`${constants.USER_API.USER_API_URL}/shows/${tv_id}/season/${season_number}`, {
            headers: { 'Authorization': `${localStorage.getItem('token')}` }
        }).then(res => {
            setEpisodesProgress(res.data);
        }).catch(err => {
            setEpisodesProgress(null);
        });
    }

    function truncate(str, n) {
        return str?.length > n ? str.substr(0, n - 1) + "..." : str;
    }

    useEffect(() => {
        if (tvState.show.showSeason) {
            fetchSeason(tvState.show.selectedShowId, tvState.show.selectedSeason.season_number);
            fetchSeasonProgress(tvState.show.selectedShowId, tvState.show.selectedSeason.season_number);
            fetchSeasonFiles(tvState.show.selectedShowId, tvState.show.selectedSeason.season_number)
        }
        return () => {

        }
    }, [tvState.show])


    return (<>
        {tvState.show.showSeason ? <div>
            <div className="episode_selector_panel">
                <h6> {tvState.show.selectedSeason.name}</h6>
                <h6>{tvState.show.selectedSeason.episode_count} episodes</h6>
            </div>
            <div className="episode_list">
                {episodes !== null ? <>
                    {episodes.episodes.map(episode => (
                        <Link key={episode.id} to={{
                            pathname: url,
                            search: `?type=s&show=${tvState.show.selectedLocation}&season=${episodes.season_number}&episode=${episode.episode_number}&tid=${tvState.show.selectedShowId}`,
                        }} style={{ color: 'inherit', textDecoration: 'inherit' }}>
                            <div className="container episode_container">
                                <div className="row border-bottom border-top p-3">
                                    <div className="episode_number col-1 justify-content-center align-self-center">{episode.episode_number}</div>
                                    <div className="col-4 justify-content-center align-self-center">
                                        <img className="img-fluid" src={`${constants.POSTER_BASE}/${episode.still_path}`} />

                                        {episodesProgress != null ?
                                            <div className="progress" style={{ height: '5px' }}>
                                                <div className="progress-bar" role="progressbar" style={{ width: `${episodesProgress?.find(x => x.episode == episode.episode_number)?.progress}%` }}></div>
                                            </div>
                                            : null}
                                    </div>
                                    <div className="col">
                                        <div className="row">
                                            <div className="episode_title col pl-0"> {episode.name}</div>
                                            {files?.find(x => x.episode == episode.episode_number) == null ?
                                                <div className="col-4 text-right" style={{ color: 'red' }}> Coming Soon </div> : null}
                                            <div className="col-4 text-right"> {episode.air_date} </div>
                                        </div>
                                        <div className="row episode_desc"> {truncate(episode.overview, 200)}</div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    ))
                    }</> : null}
            </div>
        </div>
            : <></>}</>)
}

export default EpisodeSelector;
import axios from 'axios';
import constants from '../../const.js';
import { helper}  from '../../components/common/helper.js';


/**
 * Get all Actor Tags
 */
const ACTOR_TAGS_REQUEST = "ACTOR_TAGS_REQUEST";
const ACTOR_TAGS_SUCCESS = "ACTOR_TAGS_SUCCESS";
const ACTOR_TAGS_FAILURE = "ACTOR_TAGS_FAILURE";

const actorTagsRequest = () =>{
    return {
        type: ACTOR_TAGS_REQUEST
    };
}

const actorTagsSuccess = (tags) =>{
    return{
        type: ACTOR_TAGS_SUCCESS,
        tags: tags
    }
}

const actorTagsFailure = (error) =>{
    return{
        type: ACTOR_TAGS_FAILURE,
        error
    }
}

const getActorTags = () =>{
    return dispatch =>{
        dispatch(actorTagsRequest());
        return axios.get(`${constants.DATA_API.ACTOR_TAG_URL}`)
        .then(res =>{
            dispatch(actorTagsSuccess(res.data))})
        .catch(error=> dispatch(actorTagsFailure(error)))
    }
}


/**
 * Get all actorTag types
 */
const ACTOR_TAG_TYPE_REQUEST = "ACTOR_TAG_TYPE_REQUEST";
const ACTOR_TAG_TYPE_SUCCESS = "ACTOR_TAG_TYPE_SUCCESS";
const ACTOR_TAG_TYPE_FAILURE = "ACTOR_TAG_TYPE_FAILURE";

const actorTagTypeRequest = () =>{
    return {
        type: ACTOR_TAG_TYPE_REQUEST
    };
}

const actorTagTypeSuccess = (tagType) =>{
    return{
        type: ACTOR_TAG_TYPE_SUCCESS,
        tagType: tagType
    }
}

const actorTagTypeFailure = (error) =>{
    return{
        type: ACTOR_TAG_TYPE_FAILURE,
        error
    }
}

const getActorTagTypes = () =>{
    return dispatch =>{
        dispatch(actorTagTypeRequest());
        return axios.get(`${constants.DATA_API.ACTOR_TAG_TYPE_URL}`)
        .then(res =>{
            dispatch(actorTagTypeSuccess(res.data))})
        .catch(error=> dispatch(actorTagTypeFailure(error)))
    }
}

export const actorTagActions = {
    ACTOR_TAGS_REQUEST,
    ACTOR_TAGS_SUCCESS,
    ACTOR_TAGS_FAILURE,
    getActorTags,

    ACTOR_TAG_TYPE_REQUEST,
    ACTOR_TAG_TYPE_SUCCESS,
    ACTOR_TAG_TYPE_FAILURE,
    getActorTagTypes
};

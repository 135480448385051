import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { TvContext } from '../../context/TvContext';
import constants from '../../const.js';
import { Modal, Button, Table, Col, Row, Container, Form, InputGroup } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { helper } from '../common/helper';

const ContentRequest = (props) => {

    const { tvState, setTvState } = useContext(TvContext);
    const [requestInput, setRequestInput] = useState("");
    const [order, setOrder] = useState("asc");
    const [requests, setRequests] = useState([]);
    const [openReq, setOpenReq] = useState(false);
    const [req, setReq] = useState({ title: "" });
    const [success, setSuccess] = useState(false);

    const history = useHistory();

    let token = null;
    if (localStorage.getItem('token')) {
        token = localStorage.getItem('token').slice(7);
    }
    const send_request = async () => {
        var requestType = "";
        if (window.location.pathname.includes("scene")) {
            requestType = 1;
        }
        else if (window.location.pathname.includes("actor")) {
            requestType = 1;
        }
        else {
            requestType = 5
        }

        var config = {
            method: 'post',
            url: `${constants.USER_API_URL}/users/requestContent/${requestType}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: { content: `${requestInput}` }
        };
        await axios(config).then(res => {
            setTvState({
                ...tvState,
                contentRequest: {
                    ...tvState.contentRequest,
                    active: false
                }
            })
            get_request();
        });
    }

    const get_request = async () => {
        var requestType = "";
        console.log(window.location.href)
        if (window.location.href.includes("scene")) {
           
            requestType = 1;
        }
        else if (window.location.href.includes("actor")) {
            requestType = 1;
        }
        else {
            requestType = 5
        }

        var config = {
            method: 'get',
            url: `${constants.USER_API_URL}/users/requestContent/${requestType}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };
        await axios(config).then(res => {
            setRequests(res.data.data);
            setTvState({
                ...tvState,
                contentRequest: {
                    ...tvState.contentRequest,
                    active: false
                }
            })
        });
    }

    const hideModal = () => {
        setTvState({
            ...tvState,
            contentRequest: {
                ...tvState.contentRequest,
                active: false
            }
        })
    }

    const sortter = (key, arr, setter) => {
        var tmpArr = arr;
        if (order == "asc") {
            setOrder("desc");
        }
        else {
            setOrder("asc");
        }
        setter(tmpArr.sort(helper.sortObjects(key, order)));
    }

    const fufill = async () => {
        console.log(req);
        var config = {
            method: 'post',
            url: `${constants.USER_API_URL}/users/fufill/requestContent`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: req
        };
        await axios(config).then(res => {
            get_request();
            setSuccess(true);
        });
    }

    const openRequest = (item) => {
        setOpenReq(true);
        setReq(item);
        console.log(item)
    }

    useEffect(() => {
        get_request();
    }, [])

    return <>
        {/* {
        tvState.contentRequest.active ?
            <div style={{ position: 'absolute', width: '250px', height: '150px', background: '#343a40', color: 'white', right: '0px', padding: '10px' }}>
                <div className="create_room">
                    <h3>Request Content</h3>
                    <input className="request_input" onChange={(e) => setRequestInput(e.target.value)} />
                    <button className="join__button create" onClick={() => send_request()}>Send</button>
                    <ContentRequestStatus/>
                </div>
            </div> 
            : null} */}
        <Modal size="lg" show={tvState.contentRequest.active} onHide={hideModal}>
            <Modal.Header closeButton>
                <Modal.Title>Request Content</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col md={10}>
                            <input style={{ width: "100%" }} onChange={(e) => setRequestInput(e.target.value)}></input>
                        </Col>
                        <Col md={2}>
                            <Button onClick={() => send_request()}>Request</Button>
                        </Col>
                    </Row>
                </Container>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th onClick={() => sortter("title", requests, setRequests)}>Title</th>
                            <th onClick={() => sortter("requested_date", requests, setRequests)}>Requested Date</th>
                            <th onClick={() => sortter("status", requests, setRequests)}>Status</th>
                            <th onClick={() => sortter("content_type", requests, setRequests)}>Content Type</th>
                            <th onClick={() => sortter("content_id", requests, setRequests)}>Content ID</th>
                        </tr>
                    </thead>
                    <tbody>
                        {requests === [] ? null :
                            requests.map(item => (
                                <tr key={`${item.id}`}>
                                    <td>{item.content_id == null ? item.title : <Link to={item.link} onClick={() => {
                                        history.push(item.link)
                                        window.location.reload(false);
                                    }}>{item.title}</Link>}</td>
                                    <td onClick={() => openRequest(item)}>{item.requested_date.substring(0, item.requested_date.length - 8).replace('T', ' ')}</td>
                                    <td>{item.status == 1 ? 'Requested' : `${item.status == 2 ? 'InProgress' : 'Complete'}`}</td>
                                    <td>{item.contentType}</td>
                                    <td>{item.content_id}</td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </Modal.Body>
        </Modal>
        <Modal size="sm" show={openReq} onHide={() => setOpenReq(false)}>
            <Modal.Header closeButton >
                <Modal.Title>Request Content - {req.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Form.Group as={Col} controlId="validationTitle">
                            <Form.Label srOnly>Status</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Status</InputGroup.Text>
                                </InputGroup.Prepend>
                                <select className="custom-select custom-select-sm" value={req.status} onChange={(e) => setReq({ ...req, status: parseInt(e.target.value) })}>
                                    <option value={1}>Requested</option>
                                    <option value={2}>In Progress</option>
                                    <option value={3}>Complete</option>
                                </select>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="validationContentType">
                            <Form.Label srOnly>Content Type</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Content Type</InputGroup.Text>
                                </InputGroup.Prepend>
                                <select className="custom-select custom-select-sm" value={req.content_type} onChange={(e) => setReq({ ...req, content_type: parseInt(e.target.value) })}>
                                    <option value={1}>Adult Scene</option>
                                    <option value={2}>Adult Movie</option>
                                    <option value={3}>Adult Site</option>
                                    <option value={4}>Adult Model</option>
                                    <option value={5}>PG</option>
                                </select>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="validationContentID">
                            <Form.Label srOnly>Content ID</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Content ID</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control required type="text" placeholder="Content ID" value={req.content_id} onChange={(e) => setReq({ ...req, content_id: parseInt(e.target.value) })} />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={fufill}>Fufill</Button>
                {success?<div className="alert alert-success" role="alert">
                    This is a success alert—check it out!
                </div>:null}
            </Modal.Footer>
        </Modal>
    </>
}

export default ContentRequest;
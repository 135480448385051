import axios from 'axios';
import constants from '../../const.js';

const SCENE_FILE_SUC = "SCENE_FILE_SUC";
const UPLOAD_REQ = "UPLOAD_REQ";
const UPLOAD_SUC = "UPLOAD_SUC";
const UPLOAD_ERR = "UPLOAD_ERR";

const upload_REQ = () =>{
    return{
        type: UPLOAD_REQ
    };
};

const upload_SUC = (file) =>{
    return{
        type: UPLOAD_SUC,
        file: file
    };
};
const sceneFile_SUC = (files) =>{
    return{
        type: SCENE_FILE_SUC,
        files: files
    }
}

const upload_ERR = (error) =>{
    return{
        type: UPLOAD_ERR,
        error
    }
};

const uploadFile = (file, id) =>{    
    return dispatch =>{
        dispatch(upload_REQ());
        var token = localStorage.getItem('token').slice(7);
        var data = new FormData();
        data.append('preview', file);
        return axios.post(`${constants.CONTENT_DOMAIN}upload/${id}/preview?token=${token}`, data,{})
        .then(res=> dispatch(upload_SUC()))
        .catch(e=>dispatch(upload_ERR()));
    }
};

const uploadTagFile = (file, id) =>{    
    return dispatch =>{
        dispatch(upload_REQ());
        var token = localStorage.getItem('token').slice(7);
        var data = new FormData();
        data.append('preview', file);
        return axios.post(`${constants.CONTENT_DOMAIN}upload/${id}/tag?token=${token}`, data,{})
        .then(res=> dispatch(upload_SUC()))
        .catch(e=>dispatch(upload_ERR()));
    }
};

const uploadVideoFile = (file, id) =>{    
    return dispatch =>{
        dispatch(upload_REQ());
        var token = localStorage.getItem('token').slice(7);
        var data = new FormData();
        data.append('video', file);
        return axios.post(`${constants.CONTENT_DOMAIN}upload/${id}/video?token=${token}`, data,{})
        .then(res=> dispatch(upload_SUC()))
        .catch(e=>dispatch(upload_ERR()));
    }
};

const convertVideo = (id) =>{
    return distpatch =>{
        var token = localStorage.getItem('token').slice(7);
        return  axios.get(`${constants.CONTENT_DOMAIN}convert/scene/${id}?token=${token}`)
    }
}
const renameVideo = (id) =>{
    return distpatch =>{
        var token = localStorage.getItem('token').slice(7);
        return  axios.get(`${constants.CONTENT_DOMAIN}update/scene/${id}?token=${token}`)
    }
}

const getDir = (file, id) =>{    
    return dispatch =>{
        // dispatch(upload_REQ());
        var token = localStorage.getItem('token').slice(7);
        var data = new FormData();
        data.append('preview', file);
        return axios.get(`${constants.CONTENT_DOMAIN}files/scenes/${id}?path=${file}&token=${token}`, data,{})
        .then(res=> dispatch(sceneFile_SUC(res.data)))
        .catch(e=>dispatch(upload_ERR()));
    }
};


export const uploadFileActions = {
    UPLOAD_REQ,
    UPLOAD_SUC,
    UPLOAD_ERR,
    SCENE_FILE_SUC,
    uploadFile,
    uploadTagFile,
    uploadVideoFile,
    getDir,
    convertVideo,
    renameVideo
};

import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Button, Table, Modal, InputGroup, Form } from 'react-bootstrap';
import axios from 'axios';
import constants from '../../const.js';
import Select from "react-dropdown-select";
import queryString from 'query-string';
import { helper } from '../common/helper.js';

const CMSMovies = (props) => {

    const history = useHistory();
    const [isVisible, setIsVisble] = useState(false);
    const [order, setOrder] = useState("asc");
    const [table, setTable] = useState(null);
    const [validated, setValidated] = useState(false);

    //data logic
    const [movies, setMovies] = useState([]);
    const [series, setSeries] = useState([]);
    const [selectedMovie, setSelectedMovie] = useState(null);

    var link = props.link != null ? props.link : 'admin'

    var params = queryString.parse(props.location?.search);
    if (params.tab == null) {
        params.tab = 0
    }

    const fetchMovies = async () => {
        const request = await axios.get(`${constants.TRAKT_API_URL}/movies`);
        const requestSeries = await axios.get(`${constants.TRAKT_API_URL}/movies/series`);
        setMovies(request.data);
        setSeries(requestSeries.data);
        createTable(request.data);
        return request;
    }

    const openModal = (show) => {
        setIsVisble(true);
        setSelectedMovie(show);
    }
    const closeModal = () => {
        setIsVisble(false);
        setSelectedMovie(null);
    }

    const sortContent = (key) => {
        var tmpArr = movies;
        if (order == "asc") {
            setOrder("desc");
        }
        else {
            setOrder("asc");
        }
        setMovies(tmpArr.sort(helper.sortObjects(key, order)));
        createTable(movies);
    }

    const createTable = (arr) => {
        setTable(<tbody>
            {arr.map(movie => (
                <tr key={movie.CID}>
                    <td onClick={() => openModal(movie)}>{movie.slug}</td>
                    <td onClick={() => openModal(movie)}>{movie.seriesTitle}</td>
                    <td onClick={() => openModal(movie)}>{movie.info.title}</td>
                    <td onClick={() => openModal(movie)}>{movie.year}</td>
                    <td onClick={() => openModal(movie)}>{movie.status}</td>
                    <td onClick={() => openModal(movie)}>{movie.addedDate}</td>
                    <td onClick={() => openModal(movie)}>{movie.releaseDate}</td>
                    <td>
                        <div className="m-1 p-1 badge badge-secondary" key={"credits"} onClick={() => console.log(movie.UID)}>
                            <span> <a value={'asdf'} style={{ color: "white" }} >Credits</a></span>
                        </div></td>
                </tr>
            ))}
        </tbody>)
    }

    const saveMovie = (event) =>{
        const form = event.currentTarget;
        if(form.checkValidity() ==false){
            event.preventDefault();
            event.stopPropagation();
        }
        selectedMovie.series = selectedMovie.series =="" || selectedMovie.series == null? null: selectedMovie.series;
        axios.post(`${constants.TRAKT_API_URL}/movies`, selectedMovie,{
            headers:{'Authorization': `${localStorage.getItem('token')}`}
        }).then(res=>{
            console.log(res)
            console.log('posted successfully')
        })
        setValidated(true);
        console.log(selectedMovie)
    }

    useEffect(() => {
        fetchMovies();
    }, [])

    const seriesSelect = React.forwardRef(() => (
        <select className="custom-select custom-select-sm" value={selectedMovie?.series} onChange={(e) => setSelectedMovie({ ...selectedMovie, series: parseInt(e.target.value) })} data-live-search="true">
            <option defaultValue={"DEFAULT"}>Select Series</option>
            {series.map(serie => <option key={serie.sid} value={serie.sid}>{serie.title}</option>)}
        </select>));

    return <Container>
        <Row className="justify-content-center p-2">
            <h1>CMS Movies Panel</h1>
        </Row>
        <Row>
            <Col>
                <Row className="justify-content-center">
                    <Button onClick={() => { setIsVisble(true); setSelectedMovie({ title: "New Movie" }) }}>Add Movie</Button>
                </Row>

                <Row className="justify-content-center">
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th onClick={() => sortContent("slug")}>Slug</th>
                                <th onClick={() => sortContent("series")}>Series</th>
                                <th onClick={() => sortContent("title")}>Title</th>
                                <th onClick={() => sortContent("year")}>Year</th>
                                <th onClick={() => sortContent("status")}>Status</th>
                                <th onClick={() => sortContent("addedDate")}>Added</th>
                                <th onClick={() => sortContent("releaseDate")}>Released</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        {table}
                    </Table>
                </Row>
            </Col>
        </Row>
        {selectedMovie ?
            <Modal show={isVisible} onHide={() => closeModal()} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>{selectedMovie.title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                <Form noValidate validated={validated} onSubmit={saveMovie}>
                    <Form.Row>
                        <Form.Group as={Col} controlId="validationTitle">
                            <Form.Label srOnly>Title</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Title</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control required type="text" placeholder="Title" defaultValue={selectedMovie.title} onChange={(e) => setSelectedMovie({ ...selectedMovie, title: e.target.value })} />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Please choose a valid Title</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="validationLocation">
                            <Form.Label srOnly>Location</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Location</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control required type="text" placeholder="Location" defaultValue={selectedMovie.location} onChange={(e) => setSelectedMovie({ ...selectedMovie, location: e.target.value })} />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Please choose a valid Location</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="5" controlId="validationSeries">
                            <Form.Label srOnly>Series</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Series</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control as={seriesSelect} required custom />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="5" controlId="validationYear">
                            <Form.Label srOnly>Year</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Year</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control required type="number" placeholder="Year" defaultValue={selectedMovie.year} onChange={(e) => setSelectedMovie({ ...selectedMovie, year: e.target.value })} />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Please choose a valid Year</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="5" controlId="validationSlug">
                            <Form.Label srOnly>Slug</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Slug</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control required type="text" placeholder="Slug" defaultValue={selectedMovie.slug} onChange={(e) => setSelectedMovie({ ...selectedMovie, slug: e.target.value })} />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Please choose a valid Slug</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="5" controlId="validationRelease">
                            <Form.Label srOnly>Release</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Release</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control required type="text" placeholder="YYYY-MM-DD" defaultValue={selectedMovie.releaseDate} onChange={(e) => setSelectedMovie({ ...selectedMovie, releaseDate: e.target.value })} />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Please choose a valid Release Date</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {console.log(selectedMovie)}
                    <Button onClick={saveMovie}>Save</Button>
                </Modal.Footer>
            </Modal> : null}
    </Container>
}

export default CMSMovies
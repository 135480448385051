import { actorActions } from '../../actions/scene/actorActions.js';

const INITIAL_STATE ={
    isFetching: false,
    allActor: [{
      id: 1,
      name: ''}],
    actors:[],  
    error: ''
  }

const sceneActorReducers = (state = INITIAL_STATE, action) =>{
    switch(action.type){
    
      case actorActions.ACTOR_REQ:
        return{
              ...state,
              isFetching: true,
              actors:[]
          }
      case actorActions.ACTOR_SUC:
        return{
              ...state,
              isFetching: false,
              actors: action.actors
          }
      case actorActions.ALL_ACTOR_SUC:
        return{
              ...state,
              isFetching: false,
              allActor:action.allActor
          }
      case actorActions.ACTOR_ERR:
        return{
              ...state,
              isFetching: false,
              error: action.error
          }
        default:
            return state;
    }
};

export default sceneActorReducers;
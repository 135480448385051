
const TRAILER_CDN = 'https://sus-store.s3.us-east-2.amazonaws.com/'
// const TRAILER_CDN = '';
// const CONTENT_DOMAIN = 'https://66movies.com/';
// const CONTENT_DOMAIN = 'https://192.168.2.45/';
const CONTENT_DOMAIN = 'https://mov666.ddns.net/';
// const CONTENT_DOMAIN = "https://localhost/"


// const USER_API_URL = 'http://localhost:3000/prod';
// const USER_API_URL_OLD = 'https://juxapa3tal.execute-api.us-east-2.amazonaws.com/prod';
const USER_API_URL = 'https://3qf8cy7b9h.execute-api.us-east-2.amazonaws.com/prod';


// const DATA1_API_URL = 'http://localhost:3000/prod';
const DATA1_API_URL = 'https://wesw7j7egk.execute-api.us-east-2.amazonaws.com/prod';



// const TRAKT_API_URL = 'http://localhost:3000/prod';
const TRAKT_API_URL = 'https://gp4bunnen9.execute-api.us-east-2.amazonaws.com/prod';
const POSTER_BASE = "https://image.tmdb.org/t/p/w300";
const BACKDROP_BASE = "https://image.tmdb.org/t/p/w500"
const MOVIE_DB_API = "https://api.themoviedb.org/3";
const MOVIE_DB_KEY = "?api_key=e68b0b8285a1660733e9938e6b29a161";

const CHAT_SERVER_URL = "http://192.168.2.14"

// eslint-disable-next-line
const ClientID = "b486dc01ee83752d4db550f19e8f60538d30c81b046fe2507f4154df9f4c13ff"
const ClientSecret =  "f9bcd9a05440de937ef780bb0a3c9ec88e4005dc4ab4e02785956e8587874d91"



// const SCENE_URL = `${DATA_API_URL}/api/scene`;
// const SCENE_CARD_URL = `${DATA_API_URL}/api/scene/card`;
// const SCENE_TAG_URL = `${DATA_API_URL}/api/scene/tags`;

const ACTOR_URL = `${DATA1_API_URL}/actor`;
// const ACTOR_CARD_URL = `${DATA_API_URL}/api/actors/card`;
const ACTOR_TAG_URL = `${DATA1_API_URL}/actors/tags`;
const ACTOR_TAG_TYPE_URL = `${DATA1_API_URL}/actors/tagTypes`;

const MOVIE_URL = `${DATA1_API_URL}/scenes/movies`;
const SITE_URL = `${DATA1_API_URL}/scenes/sites`;
const STATUS_URL = `${DATA1_API_URL}/scenes/status`;

const DATA_API = {
    // SCENE_URL, 
    // SCENE_CARD_URL, 
    // SCENE_TAG_URL,
    ACTOR_URL, 
    // ACTOR_CARD_URL, 
    ACTOR_TAG_URL, ACTOR_TAG_TYPE_URL,
    MOVIE_URL, SITE_URL, STATUS_URL
}

const AUTH_URL = `${USER_API_URL}/auth/authenticated`;
const LOGIN_URL = `${USER_API_URL}/auth/login`;
const SIGNUP_URL = `${USER_API_URL}/auth/signup`;
const SCENE_USERINFO_URL = `${USER_API_URL}/scenes`;
const ADMIN_URL = `${USER_API_URL}/admin`;
const USERS_URL = `${USER_API_URL}/admin/users`;
const ROLES_URL = `${USER_API_URL}/admin/roles`;
const SCENE_FAVORITES_URL = `${USER_API_URL}/users/favorite/scenes`;
const SCENE_HISTORY_URL = `${USER_API_URL}/users/history/scenes`;
const SCENE_LIKE_URL = `${USER_API_URL}/users/like/scenes`;
const SCENE_PLAYLIST_URL = `${USER_API_URL}/playlist`;
const SCENE_SEARCH_HISTORY = `${USER_API_URL}/users/history/search/scenes`;
const ACTOR_SEARCH_HISTORY = `${USER_API_URL}/users/history/search/actors`;

const USER_API ={
    AUTH_URL, LOGIN_URL, SIGNUP_URL, SCENE_USERINFO_URL, 
    USERS_URL, ROLES_URL, ADMIN_URL,
    SCENE_FAVORITES_URL,
    SCENE_HISTORY_URL,
    SCENE_LIKE_URL,
    SCENE_PLAYLIST_URL,
    SCENE_SEARCH_HISTORY,
    ACTOR_SEARCH_HISTORY,
    USER_API_URL
}

const mobileSize = 800;

export const PERM ={
    NONE: 'NONE',
    READ: 'READ',
    WRITE: 'WRITE',
    ADMIN: 'ADMIN',
    ADULT: 'ADULT'
}

export const TAGS ={
    ACTORTAG: 'tagType',
    SCENECATEGORY: 'category'
}

const constants={
    DATA_API,
    DATA1_API_URL,
    USER_API,
    USER_API_URL,
    TRAKT_API_URL,
    CHAT_SERVER_URL,
    POSTER_BASE,
    BACKDROP_BASE,
    MOVIE_DB_API,
    MOVIE_DB_KEY,
    CONTENT_DOMAIN,
    mobileSize,
    TRAILER_CDN
};

export default constants;
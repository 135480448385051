import { combineReducers} from 'redux';

//Local imports
import sceneCardReducers from './scene/sceneCardReducers';
import sceneReducers from './scene/sceneReducers';

//actors
import actorCardReducers from './actor/actorCardReducers';
import actorReducers from './actor/actorReducers';
import actorTagReducers from './actor/actorTagReducers';

//scenesCard

//scenes
import uploadFileReducers from './scene/uploadFileReducers';
import userDataReducers from './scene/userDataReducers';
import statusReducers from './scene/statusReducers';
import siteReducers from './scene/siteReducers';
import sceneActorReducers from './scene/actorReducers';

//users
import userReducers from './user/userReducers';
import userFavSceneReducers from './user/userFavSceneReducer';
import userHistSceneReducers from './user/userHistSceneReducer';
import userLikeSceneReducers from './user/userLikeSceneReducers';
import permissionReducers from './user/permissionReducers';
import movieReducers from './movie/movieReducers';

const actors = combineReducers({
    actorInfo: actorReducers,
    actorTags: actorTagReducers
})

const movies = combineReducers({
    movies: movieReducers
})

const scenes = combineReducers({
    uploadFile : uploadFileReducers,
    userData: userDataReducers,
    status: statusReducers,
    site: siteReducers,
    actor: sceneActorReducers
})

const users = combineReducers({
    user: userReducers,
    permissions: permissionReducers,
    favorites: userFavSceneReducers,
    history: userHistSceneReducers,
    likes: userLikeSceneReducers
})

export const allReducers = combineReducers({
    sceneCardReducers,
    sceneReducers,
    actorCardReducers,

    scenes,
    actors,
    users,
    movies
});

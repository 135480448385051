import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Button, Table, Modal } from 'react-bootstrap';
import axios from 'axios';
import constants from '../../const.js';
import Select from "react-dropdown-select";
import queryString from 'query-string';

const CMSShows = (props) => {

    const history = useHistory();
    const [shows, setShows] = useState([]);
    const [selectedShow, setSelectedShow] = useState(null);

    const [roles, setRoles] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [isVisible, setIsVisble] = useState(false);
    const [selectedRoles, setSelectedRoles] = useState([]);

    var link = props.link != null ? props.link: 'admin'

    var params = queryString.parse(props.location?.search);
    if (params.tab == null) {
        params.tab = 0
    }

    const fetchShows = async () =>{
        const request = await axios.get(`${constants.TRAKT_API_URL}/shows`);
        setShows(request.data);
        return request;
    }

    const fetchRoles = () => {
        var token = '';
        if (localStorage.getItem('token') != null) {
            token = localStorage.getItem('token');
            axios.get(constants.USER_API.ROLES_URL, {
                headers: { 'Authorization': `${token}` }
            }).then(
                res => {
                    setRoles(res.data.data);
                }
            ).catch(err => {
                console.log(err);
            });
        }
    }

    const openModal = (show) => {
        setIsVisble(true);
        setSelectedShow(show);
    }
    const closeModal = () => {
        setIsVisble(false);
        setSelectedShow(null);
    }


    useEffect(() => {
        fetchShows();
    }, [])


    return <Container>
        <Row className="justify-content-center p-2">
            <h1>CMS Shoes Panel</h1>
            {console.log(shows)}
        </Row>
        <Row>
            <Col>
                <Row className="justify-content-center">
                    <Button>Add</Button>
                </Row>
              
                    <Row className="justify-content-center">
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Slug</th>
                                    <th>Title</th>
                                    <th>Status</th>
                                    <th>Joined</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {shows.map(show => (
                                    <tr key={show.CID}>
                                        <td onClick={() => openModal(show)}>{show.CID}</td>
                                        <td onClick={() => openModal(show)}>{show.slug}</td>
                                        <td onClick={() => openModal(show)}>{show.info.name}</td>
                                        <td onClick={() => openModal(show)}>{show.email}</td>
                                        <td onClick={() => openModal(show)}>{show.joined_date}</td>
                                        <td>
                                            <div className="m-1 p-1 badge badge-secondary" key={"actions"} onClick={() => console.log(show.UID)}>
                                                <span> <a value={'asdf'} style={{ color: "white" }}>Sync</a></span>
                                            </div>
                                            <div className="m-1 p-1 badge badge-secondary" key={"actions"} onClick={() => console.log(show.UID)}>
                                                <span> <a value={'asdf'} style={{ color: "white" }} >Credits</a></span>
                                            </div></td>
                                    </tr>
                                ))}
                                
                            </tbody>
                        </Table>
                    </Row> 
            </Col>
        </Row>
        {selectedShow?
        <Modal show={isVisible} onHide={() => closeModal()}>
            <Modal.Header closeButton>
                <Modal.Title>{selectedShow.info.name}</Modal.Title>
                {console.log(selectedShow)}
            </Modal.Header>

            <Modal.Body>
                {/* <h4>{selectedUser.username}</h4> */}
                <Select style={{ minWidth: "300px" }} multi required options={roles} placeholder="+ click to add actor" labelField="name" valueField="id"
                    color="#000000" searchable="true" sortBy="name" clearable="true" searchBy="name" dropdownHeight="250px" dropdownPosition="auto"
                    values={selectedRoles} onChange={e => setSelectedRoles(e)} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={console.log('a')}>Save</Button>
            </Modal.Footer>

        </Modal>:null}
    </Container>
}

export default CMSShows
import React, { useEffect, useState } from 'react';
import constants from '../../const';
import ImageAlbumCover from '../gallery/image-album-cover';
import axios from 'axios';

function ActorGallery(props) {

    const [galleries, setGalleries] = useState(null);
    var token = `?token=${localStorage.getItem('token').slice(7)}`;
    

    const fetchGalleries = () => {
        if (localStorage.getItem('token') != null) {
            axios.get(`${constants.CONTENT_DOMAIN}files/galleries${token}`).then(
                res => {
                    setGalleries(res.data);
                }
            ).catch(err => {
                console.log(err);
            });
        }
    }    
    
    useEffect(()=>{
        fetchGalleries();
        
    },[])
    
    useEffect(()=>{
        if(galleries!=null){
        }
    },[galleries])

  
        return (
            <>
            <div className="row">
                {galleries!=null?<>{
                    galleries.map(gal=>{
                        return <ImageAlbumCover src={`${constants.CONTENT_DOMAIN}download/sus-media/galleries/${gal.name}/preview.webp${token}`} link={`/gallery/${gal.name}`} key={gal.name} gal={gal.name} size={'large'}/>
                    })
                }</>:<></>}
            </div>
            </>
        )
}

export default ActorGallery;
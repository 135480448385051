import React, { useState, useEffect } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { connect } from 'react-redux';

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from '@mui/icons-material/Save';

import constants from "../../const";
import { helper } from "../common/helper";
import PositionChooser from "./position-chooser";

function PositionCard(props) {
    var token = "";
	if (localStorage.getItem("token") != null) {
		token = localStorage.getItem("token");
	}
	var tokenUrl = `?token=${token.slice(7)}`;
    var imageURL = `${constants.CONTENT_DOMAIN}download/sus-media/positions/`;

    const [editMode, setEditMode] = useState(false);
    const [scenePosition, setScenePosition] = useState({
        id: 1,
        scene: 1,
        position: 1,
        name: "PositionName",
        startTime: 300,
        endTime: 1000
    })

    const jump = () =>{
      props.jumpVideo(scenePosition.startTime)
      console.log("Jump")
    }

    useEffect(()=>{
        setScenePosition(props.position)
    },[props.position])

    const changeMode = () =>{
        if(editMode){
            setEditMode(false)
        }
        else{
            setEditMode(true)
        }
    }

    const selectPosition = (position)=>{
        setScenePosition(position)
    }

    const saveChange = () =>{
        setEditMode(false)
        props.update(scenePosition)
    }

    const deletePosition = () =>{
        props.remove(scenePosition)
    }

    

  const styleFrom = {
    position: "absolute",
    width: "150px",
    left: "2px",
    top: "1px",
    paddingRight: "4px",
    paddingBottom: "1px",
    color: "white",
    zindex: 20,
    overflow: "hidden",
    cursor: "default",
    fontSize: "12px",
    backgroundImage: "linear-gradient(to bottom, rgba(0,0,0, 0.8) 40%, rgba(0,0,0, 0))"
  };

  const styleAction = {
    position: "absolute",
    right: "1px",
    bottom: "1px",
    paddingRight: "2px",
    color: "white",
    zindex: 20,
    overflow: "hidden",
    cursor: "default",
    opacity: "1",
    fontSize: "12px",
  };

  const styleDate = {
    position: 'absolute',
    paddingLeft:'10px',
    top: '175px',
    height: "25px",
    width: "350px",
    color: "white",
    zindex: 20,
    overflow: "hidden",
    cursor: "default",
    opacity: '1',
    fontSize: '12px',
    backgroundImage: "linear-gradient(to bottom, rgba(0,0,0, 0), rgba(0,0,0, 0.8) 70%)"
}


  return (
    <Card>
      <div style={{ height: "100px", width: "150px"}}>
        <img onClick={jump} src={`${imageURL}${scenePosition.position}.png${tokenUrl}`} style={{ height: "100px", width: "150px" }}></img>
        {props.perms.some(e => e.role.id === 2) ? 
        <div style={styleAction}>
          <EditIcon color="action" fontSize="small" onClick={changeMode}/>
          {editMode?<SaveIcon color="action" fontSize="small" onClick={saveChange}/>:null}
        </div> :null}
        <div style={styleFrom} className="card-title h5">
          {!editMode? scenePosition.name:<PositionChooser currentPosition={scenePosition} positionList={props.positionList} positionTagList={props.positionTagList} select={selectPosition}/>}
          {editMode?<DeleteIcon color="secondary" fontSize="small"  onClick={deletePosition}/>:null}
        </div>
      </div>

      {props.perms.some(e => e.role.id === 2) ? 
      <div className="scene-card-link auto">
        <div className="m-1 card-title h5" style={{ fontSize: "12px" }}>
        {!editMode?`${scenePosition.startTime}:${scenePosition.endTime}`:<>
            <input size={"3"} defaultValue={scenePosition.startTime} onChange={(e)=>setScenePosition({...scenePosition, startTime:e.target.value})}/>
            <input size={"3"} defaultValue={scenePosition.endTime} onChange={(e)=>setScenePosition({...scenePosition, endTime:e.target.value})}/></>}
        </div>
      </div>: null }
    </Card>
  );


}

const mapStateToProps = (state) => {
  return {
      perms: state.users.permissions.perms
  }
}

const mapDispatchToProps = (dispatch) => {

}

export default connect(mapStateToProps, mapDispatchToProps)(PositionCard)
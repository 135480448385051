import React from 'react';
import { Row, Col } from 'react-bootstrap';

export default function ActorViewFeature(props) {
    var len = 0
    if(props.isArray){
        len = props.value.length;
    }
    return <>
        <Row>
            <Col><h6>{props.title}</h6></Col>
            {(props.isArray == null || props.isArray == false) ? <Col>{props.value}</Col> :
                <Col>{props.value.map((a, i) => (<>{len-1 == i ? a.name: (a.name+', ')}</>))}</Col>}
        </Row>
    </>;


}
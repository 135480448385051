import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import constants from "../../const";
import { helper } from "../common/helper";

export default function PlaylistCard(props) {
  var images = [];
  if (props.playlist.scenes) {
    for (var img in props.playlist.scenes) {
      if (img > 3) break;
      images.push(
        `https://sus-store.s3.us-east-2.amazonaws.com/sus-media/scenes/${helper.pad(
          props.playlist.scenes[img],
          4
        )}/preview.webp`
      );
    }
  }

  const styleTime = {
    position: "absolute",
    right: "10px",
    top: "170px",
    paddingRight: "4px",
    paddingBottom: "6px",
    color: "#fff",
    zindex: 20,
    overflow: "hidden",
    cursor: "default",
    opacity: "0.8",
  };
  const styleView = {
    position: "absolute",
    left: "5px",
    top: "5px",
    paddingRight: "4px",
    paddingBottom: "6px",
    color: "#fff",
    zindex: 20,
    overflow: "hidden",
    cursor: "default",
    opacity: "0.5",
  };

  const styleSite = {
    position: "absolute",
    right: "5px",
    top: "5px",
    paddingRight: "2px",
    color: "white",
    zindex: 20,
    overflow: "hidden",
    cursor: "default",
    opacity: "1",
    fontSize: "12px",
  };


  return (
    <Card style={{ width: "351px" }}>
      <Link
        style={{
          height: "200px",
          width: "350px",
        }}
        to={`/playlist/${props.playlist.id}`}>
        <img src={images[0]} style={{height: "100px", width: "175px"}}></img>
        <img src={images[1]} style={{height: "100px", width: "175px"}}></img>
        <img src={images[2]} style={{height: "100px", width: "175px"}}></img>
        <img src={images[3]} style={{height: "100px", width: "175px"}}></img>

        <div style={styleTime}>
          <span className="badge badge-dark">{`2020-01-20`}</span>
        </div>
        <div style={styleSite}>
                <div className="site" >
                    <a className="site-left-box site" >{
            props.playlist.scenes ? props.playlist.scenes.length : 0
          }</a>
                    <a className="site-right-box site">Scenes</a>
                </div>
            </div>
        <div style={styleView}>
          <span className="badge badge-dark">{`0 views`}</span>
        </div>
      </Link>

      <Link className="scene-card-link auto" to={`/playlist/${props.playlist.id}`}>
        <div className="m-2 card-title h5" style={{ fontSize: "14px" }}>
          {props.playlist.title}
        </div>
      </Link>
    </Card>
  );
}

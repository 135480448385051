import React, { useState, useEffect }from 'react';
import { connect } from 'react-redux';
import { Form, Col, InputGroup, Modal, Button } from 'react-bootstrap';

import { sceneActions } from '../../actions/scene/sceneActions';
import { statusActions } from '../../actions/scene/statusActions';
import { actorActions } from  '../../actions/actor/actorActions';
import constants from '../../const';

function NewActor(props){

    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [firstTime, setFirstTime] = useState(true);
    const [actor, setActor] = useState({
        name: "",
        sexe: "F",
        birthday: "",
        birthlocation:0,
        height: "",
        weight: "",
        description: "",
      })

    const [tags, setTags] = useState({original:[], selectedTag:[]});


    var isMobile = window.innerWidth <= constants.mobileSize;

    const refreshData = () =>{
        props.getAllStatus();
        props.getAllTags();
    }
    const handleCancel = () => setShow(false);

    const handleSubmit = (event) =>{
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
          }
        setValidated(true);

        props.postActor(actor);
    }
    const handleShow = () => {
        setShow(true)
        if(firstTime){
            refreshData();
            setFirstTime(false);
        }
    };

    const statusSelect = React.forwardRef(() => (
        <select className="custom-select custom-select-sm" value={actor.status} data-live-search="true">
                            <option defaultValue={"DEFAULT"}>Select Status</option>
                            {props.allStatus.map(status=> <option key={status.id} value={status.id}>{status.name}</option>)}
                        </select>));

     const sexeSelect = React.forwardRef(() => (
        <select className="custom-select custom-select-sm" value={actor.sexe} onChange={(e)=>setActor({...actor, sexe: e.target.value})} data-live-search="true">
                            <option key={"F"} value="F">Female</option>
                            <option key={"M"} value="M">Male</option>
                        </select>));

    // const tagSelect = 
    //     <Select multi options={props.allTag} placeholder="+ click to add tag" labelField="name" valueField="id" 
    //         color="#000000" searchable="true" sortBy="name" clearable="true" searchBy="name" dropdownHeight ="250px" dropdownPosition="auto"  values={tags.selectedTag} onChange={e => setTags({...tags, selectedTag: e})}/>;

    return <>
            <Button variant="info" size={`${isMobile ? 'sm' : 'md'}`} onClick={handleShow}>Actor</Button>
            <Modal size="lg" show={show} onHide={handleCancel}>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                    <Modal.Title>New Actor Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Row>
                            <Form.Group as={Col} md="5" controlId="validationName">
                                <Form.Label srOnly>Name</Form.Label>
                                <InputGroup size="sm">
                                    <InputGroup.Prepend>
                                    <InputGroup.Text>Name</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control required type="text" placeholder="Name" onChange={(e)=>setActor({...actor, name: e.target.value})}/>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">Please choose a valid name</Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="3" controlId="validationSexe">
                                <Form.Label srOnly>Sexe</Form.Label>
                                <InputGroup size="sm">
                                    <InputGroup.Prepend>
                                    <InputGroup.Text>Sexe</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control required as={sexeSelect} custom/>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="validationMeasurement">
                                <Form.Label srOnly>Measurement</Form.Label>
                                <InputGroup size="sm">
                                    <InputGroup.Prepend>
                                    <InputGroup.Text>Measurement</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control type="text" placeholder="" onChange={(e)=>setActor({...actor, measurement: e.target.value})}/>
                                    <Form.Control.Feedback type="invalid">Please choose a valid measurement</Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="5" controlId="validationBirthday">
                                <Form.Label srOnly>Birthday</Form.Label>
                                <InputGroup size="sm">
                                    <InputGroup.Prepend>
                                    <InputGroup.Text>Birthday</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control type="text" placeholder="YYYY-MM-DD" onChange={(e)=>setActor({...actor, birthday: e.target.value})}/>
                                    <Form.Control.Feedback type="invalid">Please choose a valid date</Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="3" controlId="validationHeight">
                                <Form.Label srOnly>Height</Form.Label>
                                <InputGroup size="sm">
                                    <InputGroup.Prepend>
                                    <InputGroup.Text>Height</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control type="text" placeholder="" onChange={(e)=>setActor({...actor, height: e.target.value})}/>
                                    <Form.Control.Feedback type="invalid">Please choose a valid height</Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="3" controlId="validationWeight">
                                <Form.Label srOnly>Weight</Form.Label>
                                <InputGroup size="sm">
                                    <InputGroup.Prepend>
                                    <InputGroup.Text>Weight</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control type="text" placeholder="" onChange={(e)=>setActor({...actor, weight: e.target.value})}/>
                                    <Form.Control.Feedback type="invalid">Please choose a valid weight</Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            
                            
                        </Form.Row>
                        <Form.Row>
                        <Form.Group as={Col} md="5" controlId="validationBirthLocation">
                                    <Form.Label srOnly>Birth Location</Form.Label>
                                    <InputGroup size="sm">
                                        <InputGroup.Prepend>
                                        <InputGroup.Text>Birth Location</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        {/* <Form.Control type="text" placeholder="date" defaultValue={scene.date} onChange={(e)=>setActor({...scene, date: e.target.value})}/> */}
                                    </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="3" controlId="validationStatus">
                                <Form.Label srOnly>Status</Form.Label>
                                <InputGroup size="sm">
                                    <InputGroup.Prepend>
                                    <InputGroup.Text>Status</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control as={statusSelect} required custom/>
                                </InputGroup>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="12" controlId="validationTag">
                                <Form.Label srOnly>Tags</Form.Label>
                                <InputGroup size="sm">
                                    <InputGroup.Prepend>
                                    <InputGroup.Text>Tags</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    {/* {tagSelect} */}
                                </InputGroup>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row >
                            <Form.Group as={Col} md="12" controlId="validationDescription">
                                <Form.Label srOnly>Description</Form.Label>
                                <InputGroup size="sm">
                                    <InputGroup.Prepend>
                                    <InputGroup.Text>Description</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control style={{height: "100px"}} as="textarea" aria-label="With textarea" onChange={(e)=>setActor({...actor, description: e.target.value})}/>
                                </InputGroup>
                            </Form.Group>
                        </Form.Row>
                            
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleSubmit}>Save</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>;
}

const mapStateToProps = (state) =>{
    return {
        scene: state.scenes.status.scene,
        allStatus: state.scenes.status.allStatus,
        allTag: state.sceneReducers.tags,
    }
  }

const mapDispatchToProps = (dispatch) =>{
    return{
        getAllTags: () => dispatch(sceneActions.getSceneTags()),
        getAllStatus: () =>dispatch(statusActions.getAllStatus()),
        postActor: (actor)=>dispatch(actorActions.createActor(actor)),
        // insertSceneTag: (sceneId, tagId) => dispatch(sceneActions.insertSceneTag(sceneId, tagId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewActor);
import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import constants from '../../const.js';

import Banner from './Banner.js';
import MovieRow from './MovieRow.js';
import Session from '../chat/Session.js';
import './Shows.css';
import ContentRequest from './ContentRequest.js';
import { helper } from '../common/helper.js';
import { Button } from 'react-bootstrap';
import ScrollButton from '../common/buttons/scroll-button.js';

function Movies(props) {

    const lib = useRef(null);
    const [movies, setMovies] = useState([]);
    const [series, setSeries] = useState([]);
    const [orgMovies, setOrgMovies] = useState([]);
    const [fetching, setFetching] = useState(true);

    async function fetchMovieData() {
        const request = await axios.get(`${constants.TRAKT_API_URL}/movies`);
        setMovies(request.data);
        setFetching(false);
        return request;
    }

    async function fetchSeries() {
        const request = await axios.get(`${constants.TRAKT_API_URL}/movies/series`);
        setSeries(request.data);
        return request;
    }

    const findSeries = (serieId, arr) => {
        var tag = [];
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].series === serieId) tag.push(arr[i]);
        }
        return tag;
    }

    const findLatest = (arr) => {
        var latestArr = [];
        latestArr = arr.filter(n => n.addedDate != null);
        latestArr = latestArr.sort(helper.sortObjects("addedDate", "desc"))
        return latestArr;
    }

    const organizeMovie = () => {
        var org = [];
        org.push({ title: "New to 66Movies", data: findLatest(movies), scrollable: true })
        org.push({ title: "Movies", data: findSeries("", movies), scrollable: false },)
        series.map(serie => {
            var data = findSeries(serie.sid.toString(), movies)
            if (data.length > 0)
                org.push({ title: serie.title, data: data, scrollable: false })
        })
        setOrgMovies(org);
    }

    useEffect(() => {
        fetchSeries();
        fetchMovieData();

    }, []);

    useEffect(() => {
        if (!fetching) {
            organizeMovie()
        }

    }, [fetching])

    return (
        <div>
            <ContentRequest />
            {/* <ScrollButton /> */}
            {/* <Session player={false} video={null} /> */}
            <div ref={lib} className="shows fill-screen">
                {/* <div className="row justify-content-end m-5"> */}
                    
                {/* </div> */}

                {orgMovies.map(serie => <MovieRow scrollable={serie.scrollable} title={serie.title} isLargeRow data={serie.data} key={serie.title} />)}

            </div>

        </div>
    );
}

const mapStateToProps = function (state) {
    return {
        authorized: state.users.user.authorized,
    }
}

export default connect(mapStateToProps)(Movies);
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { CardDeck, Container, Row, Col, Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";

import { userFavSceneActions } from '../../actions/user/userFavSceneActions.js'
import { userHistSceneActions } from '../../actions/user/userHistSceneActions.js'
import { userLikeSceneActions } from '../../actions/user/userLikeSceneActions.js';
import { loginActions } from '../../actions/user/loginActions.js';

import SpinnerLoad from '../common/spinner.js';
import SceneCard from '../scenes/scene-card';
import constants from '../../const.js';
import Admin from './admin.js';
import Playlists from '../scenes/playlists.js';


function Profile(props) {

    const { getFavScenes, getHistScenes, getLikeScenes, getPlaylist } = props;
    const TABS = {
        ACCOUNT: 'ACCOUNT',
        FAVORITE_SCENE: 'FAVORITE_SCENE',
        LIKED_SCENE: 'LIKED_SCENE',
        HISTORY_SCENE: 'HISTORY_SCENE',
        FAVORITE_ACTOR: 'FAVORITE_ACTOR',
        LIKED_ACTOR: 'LIKED_ACTOR',
        HISTORY_ACTOR: 'HISTORY_ACTOR',
        HISTORY_SHOW: 'HISTORY_SHOW',
        PLAYLIST_SCENE: 'PLAYLIST_SCENE',
        PUBLIC_PLAYLIST: 'PUBLIC_PLAYLIST'
    }
    const [tab, setTab] = useState(TABS.ACCOUNT);
    const [playlist, setPlaylist] = useState({})
    const history = useHistory();
    
    useEffect(()=>{
        if (props.match.params.id =="account") setTab(TABS.ACCOUNT)
        else if (props.match.params.id =="playlistScene") setTab(TABS.PLAYLIST_SCENE)
        else if (props.match.params.id =="favoriteScene") setTab(TABS.FAVORITE_SCENE)
        else if (props.match.params.id =="historyScene") setTab(TABS.HISTORY_SCENE)
        else if (props.match.params.id =="likedScene") setTab(TABS.LIKED_SCENE)
        else if (props.match.params.id =="playlistScene") setTab(TABS.PLAYLIST_SCENE)

    },[props.match.params.id])
    

    var isMobile = window.innerWidth <= constants.mobileSize;
    window.addEventListener('resize', e => isMobile = window.innerWidth <= constants.mobileSize);


    useEffect(() => {
        getFavScenes();
        getLikeScenes();
        getHistScenes();
        // eslint-disable-next-line
    }, []);

    useEffect(()=>{
        if(tab == TABS.HISTORY_SCENE && props.historyScenes.scenes.length == 0){
            getHistScenes()
        }
        if(tab == TABS.LIKED_SCENE && props.likedScenes.scenes.length == 0){
            getLikeScenes()
        }
        if(tab == TABS.FAVORITE_SCENE && props.favoriteScenes.scenes.length == 0){
            getFavScenes()
        }
    },[tab])

    if (!props.isFetching) {
        // SCENES
        var scenes = [];
        switch (tab) {
            case TABS.FAVORITE_SCENE:
                scenes = props.favoriteScenes.scenes;
                break;
            case TABS.LIKED_SCENE:
                scenes = props.likedScenes.scenes;
                break;
            case TABS.HISTORY_SCENE:
                if (!props.historyScenes.isFetching)
                    scenes = props.historyScenes.scenes
                break;
        }
        var sceneCardElement = [];
        var i = 0;
        if (Array.isArray(scenes)) {
            var colSize = 1;
            for (var col = 0; col < Math.ceil(scenes.length / colSize); col++) {
                var cardDeck = [];
                for (var row = 0; row < colSize; row++) {
                    if (i < scenes.length) {
                        if (scenes[i] !== null)
                            cardDeck.push(<SceneCard key={`${row}-${col}`} style={{ width: "200px" }} scene={scenes[i]} roles={props.perms} />);
                        i++;
                    }
                }
                sceneCardElement.push(<CardDeck key={col} style={{ padding: "10px" }}>{cardDeck}</CardDeck>);
            }
        }

        const userProfile =
            <Container style={{ maxWidth: `${window.innerWidth}px` }}>
                <Row>
                    {!isMobile ?
                        <Col sm={2} style={{ backgroundColor: '#DEDBDB' }}>
                            <nav className="nav flex-column nav-pills" style={{ marginTop: '30px' }} >
                                <div className={`nav-link ${tab == TABS.ACCOUNT ? 'active' : ''}`} onClick={() => setTab(TABS.ACCOUNT)}>My Account</div>
                                {props.perms.some(e => e.role.id === 3) ? <div className={`nav-link ${tab == TABS.HISTORY_SCENE ? 'active' : ''}`} onClick={() => setTab(TABS.HISTORY_SCENE)}>Scene History</div> : null}
                                {props.perms.some(e => e.role.id === 3) ? <div className={`nav-link ${tab == TABS.LIKED_SCENE ? 'active' : ''}`} onClick={() => setTab(TABS.LIKED_SCENE)}>Liked Scenes</div> : null}
                                {props.perms.some(e => e.role.id === 3) ? <div className={`nav-link ${tab == TABS.FAVORITE_SCENE ? 'active' : ''}`} onClick={() => setTab(TABS.FAVORITE_SCENE)}>Favorite Scenes</div> : null}
                                {props.perms.some(e => e.role.id === 3) ? <div className={`nav-link ${tab == TABS.PLAYLIST_SCENE ? 'active' : ''}`} onClick={() => setTab(TABS.PLAYLIST_SCENE)}>My Playlist</div> : null}
                            </nav>
                        </Col> : null}
                    <Col>
                        {isMobile ?
                            <Row className="justify-content-center">
                                <ul className="nav nav-tabs">
                                    <li className="nav-item"><div className={`nav-link ${tab == TABS.ACCOUNT ? 'active' : ''}`} onClick={() => setTab(TABS.ACCOUNT)}>My Account</div></li>
                                    {props.perms.some(e => e.role.id === 3) ? <li className="nav-item"><div className={`nav-link ${tab == TABS.FAVORITE_SCENE ? 'active' : ''}`} onClick={() => setTab(TABS.FAVORITE_SCENE)}>Favorite</div></li> : null}
                                    {props.perms.some(e => e.role.id === 3) ? <li className="nav-item"><div className={`nav-link ${tab == TABS.LIKED_SCENE ? 'active' : ''}`} onClick={() => setTab(TABS.LIKED_SCENE)}>Liked</div></li> : null}
                                    {props.perms.some(e => e.role.id === 3) ? <li className="nav-item"><div className={`nav-link ${tab == TABS.HISTORY_SCENE ? 'active' : ''}`} onClick={() => setTab(TABS.HISTORY_SCENE)}>History</div></li> : null}
                                    {props.perms.some(e => e.role.id === 3) ? <li className="nav-item"><div className={`nav-link ${tab == TABS.PLAYLIST_SCENE ? 'active' : ''}`} onClick={() => setTab(TABS.PLAYLIST_SCENE)}>Playlists</div></li> : null}
                                </ul>
                            </Row> : null}
                        <Row style={{ outline: "1px solid lightgray" }}>
                            <Col className="p-3"><h2>
                                {tab == TABS.ACCOUNT ? `My Account` : ''}
                                {tab == TABS.FAVORITE_SCENE ? `Favorites: ${props.favoriteScenes.count} Scenes` : ''}
                                {tab == TABS.LIKED_SCENE ? `Liked: ${props.likedScenes.count} Scenes` : ''}
                                {tab == TABS.HISTORY_SCENE ? `History: ${props.historyScenes.count} Scenes` : ''}
                                {tab == TABS.PLAYLIST_SCENE ? `` : ''}
                            </h2></Col>
                        </Row>
                        <Row style={{ outline: "1px solid lightgray" }} className="justify-content-center">
                            {tab == TABS.FAVORITE_SCENE ? <>{props.favoriteScenes.isFetching? <SpinnerLoad/>:sceneCardElement}</> : ''}
                            {tab == TABS.LIKED_SCENE ? <>{props.likedScenes.isFetching? <SpinnerLoad/>:sceneCardElement}</> : ''}
                            {tab == TABS.HISTORY_SCENE ? <>{props.historyScenes.isFetching? <SpinnerLoad/>:sceneCardElement}</> : ''}
                            {tab == TABS.PLAYLIST_SCENE ? <Playlists/> : ''}
                            {tab == TABS.ACCOUNT ? <>
                                <Col className="">
                                    <Row className="justify-content-center m-2">email: {props.user.email}</Row>
                                    <Row className="justify-content-center m-2">username: {props.user.username}</Row>
                                    <Row className="justify-content-center m-2"><Link to='/reset'><Button>Change Password</Button></Link></Row>
                                    <Row className="justify-content-center m-2"><Link to='/logout'><Button onClick={props.logout}>Logout</Button></Link></Row>
                                </Col> </> : ''}
                            {tab == TABS.ADMIN_ACCESS ? <Admin link={'profile'}/> : ''}
                        </Row>

                    </Col>
                </Row>

            </Container>


        return (
            <div className="container" style={{ maxWidth: `${window.innerWidth}px` }}>
                {!props.authorized ?
                    <Redirect to={{ pathname: '/login' }} /> : userProfile}
            </div>
        );
    }
    else {
        return <div> </div>;
    }
}

const mapStateToProps = (state) => {
    return {
        isFetching: state.users.user.isFetching,
        authorized: state.users.user.authorized,
        user: state.users.user.user,
        perms: state.users.permissions.perms,

        favoriteScenes: state.users.favorites,

        likedScenes: state.users.likes,

        historyScenes: state.users.history,


    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getFavScenes: () => dispatch(userFavSceneActions.getScenes()),
        getLikeScenes: () => dispatch(userLikeSceneActions.getScenes()),
        getHistScenes: () => dispatch(userHistSceneActions.getScenes()),
        logout: () => dispatch(loginActions.logout())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Profile);
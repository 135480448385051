import { userHistSceneActions } from '../../actions/user/userHistSceneActions.js';

const INITIAL_STATE = {
    isFetching: false,
    error: '',
    scenes:[],
    count: 0,
      sites: {
        "available": [
        {
        "siteId": 25,
        "shortName": "ap",
        "name": "Ass Parade"
        }],
        "selected":  {
          "siteId": 25,
          "shortName": "ap",
          "name": "Ass Parade"
          }
      },
      models: {
        available: [],
        selected: []
      },
      tags: {
        available: [],
        selected: []
      }
}

const userHistSceneReducers = (state = INITIAL_STATE, action) =>{
    switch(action.type){
        case userHistSceneActions.SCENE_HIST_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case userHistSceneActions.SCENE_HIST_SUCCESS:
            return{
                ...state,
                isFetching: false,
                scenes: action.scenes,
                // sites: action.sites,
                // models: action.models,
                // tags: action.tags,
                count: action.count
            }
        case userHistSceneActions.SCENE_HIST_FAILURE:
            return{
                ...state,
                isFetching: false,
                error: action.error
            }
        default:
            return state;
    }
};

export default userHistSceneReducers;

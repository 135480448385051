import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Button, Table, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import constants from '../../const.js';
import Select from "react-dropdown-select";
import queryString from 'query-string';
import { helper } from '../common/helper.js';
import EditScene from './../scenes/edit-scene-info';
import PaginationComponent from '../common/pagination.js';

const Admin = (props) => {

    const history = useHistory();
    const [order, setOrder] = useState("asc");
    const [roles, setRoles] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [isVisible, setIsVisble] = useState(false);
    const [selectedRoles, setSelectedRoles] = useState([]);

    const [showHistory, setShowHistory] = useState([]);
    const [movieHistory, setMovieHistory] = useState([]);
    const [missing, setMissing] = useState([]);
    const [sceneHistory, setSceneHistory] = useState([]);
    const [searchHistory, setSearchHistory] = useState([]);
    const [siteLiveCount, setSiteLiveCount] = useState([]);
    const [totalItem, setTotalItem] = useState({
        "sceneHistory": 0,
        "sceneSearchHistory": 0,
    })

    /**
  * 0 - Users
  * 1 - Shows
  * 2 - Movies
  * 3 - Missing Shows
  * 4 - Scene History
  * 5 - Search History
  * 6 - Site Live Count
  */
    const [tab, setTab] = useState(0);
    var link = props.link != null ? props.link : 'admin'

    var params = queryString.parse(props.location?.search);
    if (params.tab == null) {
        params.tab = 0
    }
    if (params.page == null)
        params.page = 1;
    if (params.size == null)
        params.size = "50";

    let token = `?token=${localStorage.getItem('token').slice(7)}`;
    const fetchRoles = () => {
        var token = '';
        if (localStorage.getItem('token') != null) {
            token = localStorage.getItem('token');
            axios.get(constants.USER_API.ROLES_URL, {
                headers: { 'Authorization': `${token}` }
            }).then(
                res => {
                    setRoles(res.data);
                }
            ).catch(err => {
                console.log(err);
            });
        }
    }

    const fetchUsers = () => {
        var token = '';
        if (localStorage.getItem('token') != null) {
            token = localStorage.getItem('token');
            axios.get(constants.USER_API.USERS_URL, {
                headers: { 'Authorization': `${token}` }
            }).then(
                res => {
                    setUsers(res.data);
                }
            ).catch(err => {
                console.log(err);
            });
        }
    }

    const fetchUserShows = (sort, direction) => {
        var token = '';
        if (localStorage.getItem('token') != null) {
            token = localStorage.getItem('token');
            axios.get(`${constants.USER_API.USER_API_URL}/admin/history/shows/${sort}/${direction}`, {
                headers: { 'Authorization': `${token}` }
            }).then(
                res => {
                    setShowHistory(res.data);
                }
            ).catch(err => {
                console.log(err);
            });
        }
    }

    const fetchUserMovies = (sort, direction) => {
        var token = '';
        if (localStorage.getItem('token') != null) {
            token = localStorage.getItem('token');
            axios.get(`${constants.USER_API.USER_API_URL}/admin/history/movies/${sort}/${direction}/ungroup`, {
                headers: { 'Authorization': `${token}` }
            }).then(
                res => {
                    setMovieHistory(res.data);
                }
            ).catch(err => {
                console.log(err);
            });
        }
    }

    const fetchMissingEpisodes = () => {
        axios.get(`${constants.TRAKT_API_URL}/shows/missing/s`, {
        }).then(
            res => {
                setMissing(res.data);
            }
        ).catch(err => {
            console.log(err);
        });
    }

    const fetchScenes = (sort, direction, size, page) => {
        var token = '';
        if (localStorage.getItem('token') != null) {
            token = localStorage.getItem('token');
            axios.get(`${constants.USER_API.USER_API_URL}/admin/history/scenes/${sort}/${direction}?size=${size}&page=${page}`, {
                headers: { 'Authorization': `${token}` }
            }).then(
                res => {
                    setSceneHistory(res.data.data);
                    setTotalItem({...totalItem,
                        "sceneHistory": res.data.totalItem})
                }
            ).catch(err => {
                console.log(err);
            });
        }
    }

    const fetchSearchHistory = (sort, direction, size, page) => {
        var token = '';
        if (localStorage.getItem('token') != null) {
            token = localStorage.getItem('token');
            axios.get(`${constants.USER_API.USER_API_URL}/admin/history/scenes/${sort}/${direction}/search?size=${size}&page=${page}`, {
                headers: { 'Authorization': `${token}` }
            }).then(
                res => {
                    setSearchHistory(res.data.data);
                    setTotalItem({...totalItem,
                            "sceneSearchHistory": res.data.totalItem})
                }
            ).catch(err => {
                console.log(err);
            });
        }
    }

    const fetchSiteLiveCount = () =>{
        var token = '';
        if (localStorage.getItem('token') != null) {
            token = localStorage.getItem('token');
            axios.get(`${constants.USER_API.USER_API_URL}/admin/history/site-live-count`, {
                headers: { 'Authorization': `${token}` }
            }).then(
                res => {
                    setSiteLiveCount(res.data.data);
                }
            ).catch(err => {
                console.log(err);
            });
        }
    }


    const assignRole = () => {
        var token = '';
        if (localStorage.getItem('token') != null) {
            token = localStorage.getItem('token');
            axios.post(`${constants.USER_API.ADMIN_URL}/assign-roles/${selectedUser.UID}`, selectedRoles, {
                headers: { 'Authorization': `${token}` },
                body: selectedRoles
            }).then(
                res => {
                    console.log(res.data);
                }
            ).catch(err => {
                console.log(err);
            });
        }
    }
    const removeRole = (uid, role) => {
        var token = '';
        if (localStorage.getItem('token') != null) {
            token = localStorage.getItem('token');
            axios.delete(`${constants.USER_API.ADMIN_URL}/roles/${role}/${uid}`, {
                headers: { 'Authorization': `${token}` },
            }).then(
                res => {
                    console.log(res.data);
                }
            ).catch(err => {
                console.log(err);
            });
        }
    }

    const headerSort = (sort, direction) =>{
        if (direction == "asc")
            params.direction = "desc"
        else
            params.direction = "asc"

        history.push(`/${link}?tab=${params.tab}&sort=${sort}&direction=${params.direction}&size=${params.size}&page=${params.page}`)
        pageRefresh(params.page)
    }

    const sortter = (key, arr, setter) => {
        var tmpArr = arr;
        if (order == "asc") {
            setOrder("desc");
        }
        else {
            setOrder("asc");
        }
        setter(tmpArr.sort(helper.sortObjects(key, order)));
    }

    const openModal = (user) => {
        setIsVisble(true);
        setSelectedUser(user);
        var ro = [];
        user.PERM.map(perm => {
            ro.push(perm.role)
        })
        setSelectedRoles(ro);
    }
    const closeModal = () => {
        setIsVisble(false);
        setSelectedUser({});
    }


    useEffect(() => {
        fetchRoles();
        fetchUsers();
        setTab(params.tab)
    }, [])


    useEffect(() => {
        if (tab == 1) fetchUserShows(params.sort, params.direction);
        if (tab == 2) fetchUserMovies(params.sort, params.direction, params.group);
        if (tab == 3) fetchMissingEpisodes()
        if (tab == 4) fetchScenes(params.sort, params.direction, params.size, params.page)
        if (tab == 5) fetchSearchHistory(params.sort, params.direction, params.size, params.page)
        if (tab == 6) fetchSiteLiveCount()
    }, [tab])


    const changeTab = (num, sort, direction) => {
        setTab(num)
        history.push(`/${link}?tab=${num}&sort=${sort}&direction=${direction}`)
    }

    const paramURL = (site, model, tag, movie, studio, direction, size, sort, search, dateReleased, page) => {
        return `/scenes?site=${site}&movie=${movie}&studio=${studio}${model}${tag}&size=${size}&sort=${sort}&direction=${direction}&dateReleased=${dateReleased}&search=${search}&page=${page}`;
    }

    const adminParamURL = (sort, direction, size) => {
        return `/admin?tab=${tab}&sort=${params.sort}&direction=${params.direction}&size=${params.size}&page=`;
    }

    const pageRefresh = (pageNumber) => {
        params.page = pageNumber;
        if (tab == 1) fetchUserShows(params.sort, params.direction);
        else if (tab == 2) fetchUserMovies(params.sort, params.direction, params.group);
        else if (tab == 3) fetchMissingEpisodes()
        else if (tab == 4) fetchScenes(params.sort, params.direction, params.size, params.page)
        else if (tab == 5) fetchSearchHistory(params.sort, params.direction, params.size, params.page)
    };

    return <Container>
        <Row className="justify-content-center p-2">
            <h1>Admin Panel</h1>
        </Row>
        <Row>
            <Col>
                <Row className="justify-content-center">
                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <div className={`nav-link ${tab == 0 ? 'active' : ''}`} onClick={() => changeTab(0, "recent", "desc")}>Users</div>
                        </li>
                        <li className="nav-item">
                            <div className={`nav-link ${tab == 1 ? 'active' : ''}`} onClick={() => changeTab(1, "recent", "desc")}>Shows History</div>
                        </li>
                        <li className="nav-item">
                            <div className={`nav-link ${tab == 2 ? 'active' : ''}`} onClick={() => changeTab(2, "recent", "desc")}>Movies History</div>
                        </li>
                        <li className="nav-item">
                            <div className={`nav-link ${tab == 3 ? 'active' : ''}`} onClick={() => changeTab(3, "recent", "desc")}>Missing Episodes</div>
                        </li>
                        <li className="nav-item">
                            <div className={`nav-link ${tab == 4 ? 'active' : ''}`} onClick={() => changeTab(4, "recent", "desc")}>Scene History</div>
                        </li>
                        <li className="nav-item">
                            <div className={`nav-link ${tab == 5 ? 'active' : ''}`} onClick={() => changeTab(5, "recent", "desc")}>Search History</div>
                        </li>
                        <li className="nav-item">
                            <div className={`nav-link ${tab == 6 ? 'active' : ''}`} onClick={() => changeTab(6, "recent", "desc")}>Site Count</div>
                        </li>
                        <li className="nav-item">
                            <div className={`nav-link`}><Link to="/cms-shows">CMS-Shows</Link></div>
                        </li>
                        <li className="nav-item">
                            <div className={`nav-link`}><Link to="/cms-movies">CMS-Movies</Link></div>
                        </li>
                    </ul>
                </Row>
                {tab == 0 ?
                    <Row className="justify-content-center" style={{"overflow": "scroll"}}>
                        <Table striped bordered hover dark size="sm" className='table-responsive'>
                            <thead className='thead-dark'>
                                <tr>
                                    <th onClick={() => sortter("UID", users, setUsers)}>#</th>
                                    <th onClick={() => sortter("first_name", users, setUsers)}>Name</th>
                                    <th onClick={() => sortter("username", users, setUsers)}>Username</th>
                                    <th onClick={() => sortter("email", users, setUsers)}>Email</th>
                                    <th onClick={() => sortter("shows", users, setUsers)}>Shows</th>
                                    <th onClick={() => sortter("movies", users, setUsers)}>Movies</th>
                                    <th onClick={() => sortter("scenes", users, setUsers)}>Scenes</th>
                                    <th onClick={() => sortter("joined_date", users, setUsers)}>Joined</th>
                                    <th>Permission</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map(user => (
                                    <tr key={user.UID}>
                                        <td onClick={() => openModal(user)}>{user.UID}</td>
                                        <td onClick={() => openModal(user)}>{user.first_name} {user.last_name}</td>
                                        <td onClick={() => openModal(user)}>{user.username}</td>
                                        <td onClick={() => openModal(user)}>{user.email}</td>
                                        <td onClick={() => openModal(user)}>{user.shows}</td>
                                        <td onClick={() => openModal(user)}>{user.movies}</td>
                                        <td onClick={() => openModal(user)}>{user.scenes}</td>
                                        <td onClick={() => openModal(user)}>{user.joined_date}</td>
                                        <td>{user.PERM.map(perm => (
                                            <div className="m-1 p-1 badge badge-secondary" key={`${perm.role.id}-${perm.action.id}`} onClick={() => removeRole(user.UID, perm.role.id)}>
                                                <span> <a value={'asdf'} style={{ color: "white" }} >{perm.role.name}</a></span>
                                            </div>
                                        ))}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Row> : null}
                {tab == 1 ?
                    <Row className="justify-content-center">
                        <Table striped bordered hover size="sm" className='table-responsive'>
                            <thead>
                                <tr>
                                    <th onClick={() => sortter("username", showHistory, setShowHistory)}>Username</th>
                                    <th onClick={() => sortter("title", showHistory, setShowHistory)}>Title</th>
                                    <th onClick={() => sortter("season", showHistory, setShowHistory)}>Season</th>
                                    <th onClick={() => sortter("episode", showHistory, setShowHistory)}>Episode</th>
                                    <th onClick={() => sortter("time", showHistory, setShowHistory)}>Time</th>
                                    <th onClick={() => sortter("timestamp", showHistory, setShowHistory)}>Timestamp</th>
                                </tr>
                            </thead>
                            <tbody>
                                {showHistory.map(item => (
                                    <tr key={`${item.timestamp}`}>
                                        <td>{item.username}</td>
                                        <td>{item.title}</td>
                                        <td>{item.season}</td>
                                        <td>{item.episode}</td>
                                        <td >{item.time}</td>
                                        <td>{item.timestamp}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Row> : null}
                {tab == 2 ?
                    <Row className="justify-content-center">
                        <Table striped bordered hover size="sm" className='table-responsive'>
                            <thead>
                                <tr>
                                    <th>Username</th>
                                    <th>Title</th>
                                    <th>Time</th>
                                    <th>Timestamp</th>
                                </tr>
                            </thead>
                            <tbody>
                                {movieHistory.map(item => (
                                    <tr key={`${item.timestamp}`}>
                                        <td>{item.username}</td>
                                        <td>{item.title}</td>
                                        <td >{item.time}</td>
                                        <td>{item.timestamp}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Row> : null}

                {tab == 3 ?
                    <Row className="justify-content-center">
                        <Table striped bordered hover size="sm" className='table-responsive'>
                            <thead>
                                <tr>
                                    <th>Slug</th>
                                    <th>Title</th>
                                    <th>66Movies</th>
                                    <th>Total</th>
                                    <th>Seasons</th>
                                </tr>
                            </thead>
                            <tbody>
                                {missing.map(item => (
                                    <tr key={`${item.CID}`}>
                                        <td>{item.slug}</td>
                                        <td>{item.title}</td>
                                        <td>{item.total_mov}</td>
                                        <td >{item.total_episodes}</td>
                                        <td>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Season</th>
                                                        <th>Episodes</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {item.missing_episodes.map(ep => (
                                                        <tr>
                                                            <td>{ep.season}</td>
                                                            <td>{ep.number_of_episode}</td>
                                                            <td>{ep.total_episode}</td>
                                                        </tr>
                                                    ))
                                                    }
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Row> : null}

                {tab == 4 ?
                    <Row className="justify-content-center">
                        <h3>{totalItem.sceneHistory} Items </h3>
                        <PaginationComponent baseUrl={`/admin?tab=${tab}&sort=${params.sort}&direction=${params.direction}&size=${params.size}&page=`}
                            params={params} totalItem={totalItem.sceneHistory} paginate={pageRefresh}/>
                       
                          
                        <Table striped bordered hover size="sm" className='table-responsive'>
                            <thead>
                                <tr>
                                    <th onClick={() => headerSort("username", params.direction)}>Username</th>
                                    <th>Preview</th>
                                    <th onClick={() => headerSort("id", params.direction)}>ID</th>
                                    <th onClick={() => headerSort("title", params.direction)}>Title</th>
                                    <th onClick={() => headerSort("site", params.direction)} >Site</th>
                                    <th onClick={() => headerSort("time", params.direction)}>Time</th>
                                    <th onClick={() => headerSort("status", params.direction)}>Status</th>
                                    <th onClick={() => headerSort("download", params.direction)}>Download</th>
                                    <th onClick={() => headerSort("timestamp", params.direction)}>Timestamp</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sceneHistory.map(item => (
                                    <tr key={`${item.timestamp}`}>
                                        <td>{item.username}</td>
                                        <th><img src={`${constants.TRAILER_CDN}${item.posterURL.replaceAll("download/","")}`} style={{ height: "100px", width: "150px" }} /></th>
                                        <td><Link to={`/scene/${item.id}`}>{item.id}</Link> 
                                            <EditScene sceneView={false} sceneId={item.id}/></td>
                                        <td>{item.title}</td>
                                        <td>{item.Site}</td>
                                        <td><Button className="badge badge-pill">{item.totalTime}</Button> 
                                        {item.time.map(t =>(<Button key={t.time} variant="secondary" className="badge badge-pill">{t.min}:{t.sec}</Button> ))}</td>
                                        <td><Button className="badge badge-pill" variant="danger">{item.status}</Button></td>
                                        <td><Button className="badge badge-pill" variant="danger">{item.download}</Button></td>
                                        <td>{item.timestamp}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Row> : null}
                {tab == 5 ?
                <Row className="justify-content-center">
                    <h3>{totalItem.sceneSearchHistory} Items </h3>
                    <PaginationComponent baseUrl={`/admin?tab=${tab}&sort=${params.sort}&direction=${params.direction}&size=${params.size}&page=`}
                        params={params} totalItem={totalItem.sceneSearchHistory} paginate={pageRefresh}/>
                    <Table striped bordered hover size="sm" responsive>
                        <thead>
                            <tr>
                                <th onClick={() => headerSort("username", params.direction)}>Username</th>
                                <th onClick={() => headerSort("search_param", params.direction)}>Search</th>
                                <th style={{ width: "40%"}}>Filters</th>
                                <th onClick={() => headerSort("timestamp", params.direction)} >Timestamp</th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchHistory.map(item => (
                                <tr key={`${item.timestamp}`}>
                                    <td>{item.username}</td>
                                    <td>{item.search_param !="" ? `S:${item.search_param}`: `${item.Tag !=null? `T: ${item.Tag}`: `${item.Site != null? `S: ${item.Site}`: `${item.Actor != null? `M: ${item.Actor}`: ''}`}`}`} | {item.Criteria.page}</td>
                                    <td>{JSON.stringify(item.Criteria).replaceAll(",",", ")}...</td>
                                    <td><Link to={paramURL(item.Criteria.site, helper.query(item.Criteria.model, "model"), helper.query(item.Criteria.tag, "tag"), item.Criteria.movie, item.Criteria.studio, item.Criteria.direction, item.Criteria.size, item.Criteria.sort,
                                    item.Criteria.search, item.Criteria.dateReleased, item.Criteria.page)}>{item.rtimestamp}</Link></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <PaginationComponent baseUrl={`/admin?tab=${tab}&sort=${params.sort}&direction=${params.direction}&size=${params.size}&page=`}
                        params={params} totalItem={totalItem.sceneSearchHistory} paginate={pageRefresh}/>
                </Row> : null}
                {tab == 6 ?
                    <Row className="justify-content-center">
                        <Table striped bordered hover size="sm" className='table-responsive'>
                            <thead>
                                <tr>
                                <th onClick={() => sortter("network", siteLiveCount, setSiteLiveCount)}>Network</th>
                                    <th onClick={() => sortter("name", siteLiveCount, setSiteLiveCount)}>Site</th>
                                    <th onClick={() => sortter("alt_name", siteLiveCount, setSiteLiveCount)}>Alt Name</th>
                                    <th onClick={() => sortter("count", siteLiveCount, setSiteLiveCount)}>Count</th>
                                    <th onClick={() => sortter("liveCount", siteLiveCount, setSiteLiveCount)}>Live Count</th>
                                    <th onClick={() => sortter("completion", siteLiveCount, setSiteLiveCount)}>Completion</th>
                                    <th onClick={() => sortter("remaining", siteLiveCount, setSiteLiveCount)}>Remaining</th>
                                </tr>
                            </thead>
                            <tbody>
                                {siteLiveCount.map(item => (
                                    <tr key={`${item.name}`} style={{"backgroundColor":`${item.completion==100?"green":`${item.completion>100?"#9FE2BF":""}`}`, "color":`${item.completion==100?"white":""}`}}>
                                        <td>{item.network}</td>
                                        <td>{item.name}</td>
                                        <td>{item.alt_name}</td>
                                        <td>{item.count}</td>
                                        <td>{item.liveCount}</td>
                                        <td>{item.completion}</td>
                                        <td>{item.remaining}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Row> : null}
            </Col>
        </Row>
        <Modal show={isVisible} onHide={() => closeModal()}>
            <Modal.Header closeButton>
                <Modal.Title>Assing Permission</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>{selectedUser.username}</h4>
                <Select style={{ minWidth: "300px" }} multi required options={roles} placeholder="+ click to add actor" labelField="name" valueField="id"
                    color="#000000" searchable="true" sortBy="name" clearable="true" searchBy="name" dropdownHeight="250px" dropdownPosition="auto"
                    values={selectedRoles} onChange={e => setSelectedRoles(e)} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={assignRole}>Save</Button>
            </Modal.Footer>

        </Modal>
    </Container>
}

export default Admin

import { statusActions } from '../../actions/scene/statusActions.js';

const INITIAL_STATE ={
    isFetching: false,
    allStatus: [{
      id: 1,
      name: "IPP"}],
    postedScene: false,
    postedResult: {
      message: "",
      SceneID: 0
    },
    error: '',
    scene:{
      id:0
    }
  }

const statusReducers = (state = INITIAL_STATE, action) =>{
    switch(action.type){
    
      case statusActions.STATUS_REQ:
        return{
              ...state,
              isFetching: true,
              error: ''
          }
      case statusActions.ALL_STATUS_SUC:
        return{
              ...state,
              isFetching: false,
              allStatus:action.allStatus,
              error: ''
          }
      case statusActions.POST_SCENE:
        return{
              ...state,
              isFetching: false,
              scene:action.scene,
              postedScene: action.postedScene,
              postedResult: action.scene,
              error: ''
          }
      case statusActions.STATUS_ERR:
        return{
              ...state,
              isFetching: false,
              error: action.error
          }
        default:
            return state;
    }
};

export default statusReducers;
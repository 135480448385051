import React, { useState } from 'react';
import { connect } from 'react-redux';
import { signupActions } from '../../actions/user/signupActions.js';
import SpinnerLoad from '../common/spinner';
import { Redirect } from 'react-router-dom';

function Signup(props) {

    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const [firstTime, setFirstTime] = useState(true);
    const [email, setEmail] = useState({
        valid: false,
        email: ''
    });
    const [username, setUsername] = useState({
        valid: false,
        username: ''
    });
    const [firstname, setFirstname] = useState({
        valid: false,
        firstname: ''
    });
    const [lastname, setLastname] = useState({
        valid: false,
        lastname: ''
    });

    const [pass, setPass] = useState({
        valid: false,
        pass: ''
    })
    const [passConfirm, setPassConfirm] = useState({
        valid: false,
        passConfirm: ''
    })

    const clickSubmit = () => {
        if (email.valid && username.valid && firstname.valid && lastname.valid && passConfirm.valid) {
            props.signup(email.email, username.username, firstname.firstname, lastname.lastname, pass.pass);
        }
        else
            setFirstTime(false);
    }

    const InvalidStyle = {
        color: "red",
        marginLeft: "10px"
    }
    
    const singupHTML =
        <div className="col-5">
            <div className="form-group">
                {console.log(!email.valid && firstTime)}
                <input placeholder="Email" type="email" onChange={(e) => {
                    if (re.test(e.target.value)) setEmail({ valid: true, email: e.target.value });
                    else setEmail({ valid: false }) }} className="form-control" id="inputEmail" />
                {email.valid ? '' : firstTime ? <div/>:<div style={InvalidStyle}>*Not a valid email</div>}
            </div>

            <div className="form-group">
                <input placeholder="Username" type="text" onChange={(e) => { 
                    if(/^.{6,}$/.test(e.target.value)) setUsername({ valid: true, username: e.target.value})
                    else setUsername({valid: false}) }} className="form-control" id="inputUsername"/>
                 {username.valid ? '' :  firstTime ? <div/>:<div style={InvalidStyle}>*Minimum 6 characters</div>}
            </div>
            <div className="form-group">
                <input placeholder="Firstname" type="text" onChange={(e) => {
                    if(e.target.value !== '') setFirstname({ valid: true, firstname: e.target.value })
                    else setFirstname({valid: false}) }} className="form-control" id="inputFirstname" />
                     {firstname.valid ? '' :  firstTime ? <div/>:<div style={InvalidStyle}>*Field is Empty</div>}
            </div>
            <div className="form-group">
                <input placeholder="Lastname" type="text" onChange={(e) => { 
                    if(e.target.value) setLastname({ valid: true, lastname: e.target.value })
                    else setLastname({valid: false}) }} className="form-control" id="inputLastname" />
                    {lastname.valid ? '' :  firstTime ? <div/>:<div style={InvalidStyle}>*Field is Empty</div>}
            </div>
            <div className="form-group">
                <input placeholder="Password" type="password" onChange={(e) => { 
                    if(/^.(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(e.target.value)) setPass({ valid: true, pass: e.target.value })
                    else setPass({valid: false}) }} className="form-control" id="inputPassword" />
                    {pass.valid ? '' :  firstTime ? <div/>:<div style={InvalidStyle}>*Minimum 8 characters, at least 1 letter and 1 number</div>}
            </div>
            <div className="form-group">
                <input placeholder="PasswordConfirm" type="password" onChange={(e) => { 
                    if(e.target.value === pass.pass) setPassConfirm({ valid: true, passConfirm: e.target.value })
                    else setPassConfirm({...passConfirm, valid: false}) }} className="form-control" id="inputPassword" />
                    {passConfirm.valid ? '' :  firstTime ? <div/>:<div style={InvalidStyle}>*Password doesn't match</div>}
            </div>
            <div style={InvalidStyle}>{props.error}</div>
            <button onClick={clickSubmit} className={email.valid && username.valid && firstname.valid && lastname.valid && passConfirm.valid ?'btn btn-primary':"btn btn-secondary disabled" } style={{ margin: "10px" }}>Sign Up</button>
        </div>
    return (<>
        {props.authorized ? <Redirect to={{ pathname: '/profile' }} /> : ""}
        <div className="container">
            <div className="row justify-content-center" style={{ marginTop: "50px" }}>
                {props.isFetching ? (<SpinnerLoad />) : singupHTML}
            </div>
        </div>
    </>);
}

const mapStateToProps = (state) => {
    return {
        isFetching: state.users.user.signup.isFetching,
        authorized: state.users.user.authorized,
        error: state.users.user.signup.errorMsg,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signup: (email, username, firstname, lastname, pass) => dispatch(signupActions.signup(firstname, lastname, username, email, pass))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Signup);
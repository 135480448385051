import React, { useState, useCallback, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import ReactCrop from 'react-image-crop';
import "react-image-crop/dist/ReactCrop.css";
import FileBrowser from 'react-keyed-file-browser';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';

import constants from '../../const.js';
import SpinnerLoad from './spinner';
import { uploadFileActions } from '../../actions/scene/fileDirectoryActions';
import { getDirectory, uploadPreview } from '../../actions/fileController';

import '../../../node_modules/react-keyed-file-browser/dist/react-keyed-file-browser.css';


const pixelRatio = window.devicePixelRatio || 1;

// We resize the canvas down when saving on retina devices otherwise the image
// will be double or triple the preview size.
function getResizedCanvas(canvas, newWidth, newHeight) {
  const tmpCanvas = document.createElement("canvas");
  tmpCanvas.width = newWidth;
  tmpCanvas.height = newHeight;

  const ctx = tmpCanvas.getContext("2d");
  ctx.drawImage(
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    0,
    0,
    newWidth,
    newHeight
  );

  return tmpCanvas;
}

const getImageDimension = async (file) => {
  return new Promise(function (resolved, rejected) {
    var i = new Image()
    i.onload = function () {
      resolved({ w: i.width, h: i.height })
    };
    i.src = file
  })
}

function UploadFile(props) {

  const [show, setShow] = useState(false);
  const [upImg, setUpImg] = useState();
  const [upVid, setUpVid] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "px", width: 6000, aspect: 1.78 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [path, setPath] = useState('');
  const [fileView, setFileView] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  /**
* 0 - Preview
* 1 - Video
* 2 - Files
  3 - Edit Preview
*/
  const [tab, setTab] = useState(0);
  let token = `?token=${localStorage.getItem('token').slice(7)}`;

  useEffect(() => {

    if (tab == 3) {
      setUpImg(`${constants.CONTENT_DOMAIN}${props.poster}${token}`)
    }
  }, [tab])


  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", async () => {

        var dim = await getImageDimension(reader.result);
        var dist = (dim.h - dim.w) / 2;

        if (dim.w < dim.h) {
          var cvss = document.createElement("canvas");
          var cvs = cvss.getContext("2d");
          cvss.height = dim.h;
          cvss.width = dim.w + (2*dist);
          cvs.fillRect(0, 0, dist, dim.h);
          cvs.fillRect(dist + dim.w, 0, dist, dim.h);

          var image = new Image();
          image.src = reader.result;
          cvs.drawImage(image, dist, 0);
          setUpImg(cvss.toDataURL())
        }
        else{
          setUpImg(reader.result);
        }
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onSelectVideoFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpVid(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setUpVid(e.target.files[0])
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = Math.ceil(crop.width * pixelRatio);
    canvas.height = Math.ceil(crop.height * pixelRatio);

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  function dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    uploadPreview(croppedImage, props.sceneId, setIsUploading);
    setCompletedCrop(croppedImage);
  }


  function generateDownload(previewCanvas, crop) {
    if (!crop || !previewCanvas) {
      return;
    }
    setIsUploading(true)
    const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height);
    const reader = new FileReader();
    canvas.toBlob(blob => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        dataURLtoFile(reader.result, 'preview.jpg')
      }
    });
  }

  const handleCancel = () => {
    setShow(false);
  };
  const handleSave = () => {
    setShow(false);
    generateDownload(previewCanvasRef.current, completedCrop);
  };
  const handleConvert = () => {
    props.convertFile(props.sceneId)
  };
  const handleVideo = () => {
    props.uploadVideoFile(upVid, props.sceneId)
  }
  const handleShow = async () => {
    setShow(true)
    await getDirectory(path, props.sceneId, setFileView);
  };


  return <>
    {props.sceneView ? <Button variant="info" size="sm" onClick={handleShow}>Scene Files</Button> :
    <DriveFolderUploadIcon sx={{fontSize:"25px"}} onClick={handleShow}/>
      // <Button variant="dark" className="badge badge-pill" style={{borderRadius:"30px", fontWeight:"bold"}} onClick={handleShow}><DriveFolderUploadIcon sx={{fontSize:"20px"}}/></Button>
      }

    <Modal size="xl" show={show} onHide={handleCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Uploading Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <div className={`nav-link ${tab == 0 ? 'active' : ''}`} onClick={() => setTab(0)}>Preview</div>
          </li>
          <li className="nav-item">
            <div className={`nav-link ${tab == 2 ? 'active' : ''}`} onClick={() => setTab(2)}>View Files</div>
          </li>
          <li className="nav-item">
            <div className={`nav-link ${tab == 3 ? 'active' : ''}`} onClick={() => setTab(3)}>Edit Preview</div>
          </li>
        </ul>

        {tab == 0 ? <div>
          <div><input type="file" accept="image/*" onChange={onSelectFile} /></div>
          <ReactCrop src={upImg} onImageLoaded={onLoad} crop={crop}
            onChange={(c) => setCrop(c)} onComplete={(c) => setCompletedCrop(c)} />
          <div>
            <canvas ref={previewCanvasRef}
              style={{ width: Math.ceil(completedCrop?.width ?? 0), height: Math.ceil(completedCrop?.height ?? 0) }} />
          </div></div> : <div />}
        {tab == 2 ? <div>{fileView != null ? 
        <FileBrowser files={fileView} /> : <SpinnerLoad />}</div> : <div />}

        {tab == 3 ? <div>
          <ReactCrop src={upImg} onImageLoaded={onLoad} crop={crop}
            onChange={(c) => setCrop(c)} onComplete={(c) => setCompletedCrop(c)} />
          <div>
            <canvas ref={previewCanvasRef}
              style={{ width: Math.ceil(completedCrop?.width ?? 0), height: Math.ceil(completedCrop?.height ?? 0) }}/>
          </div></div> : <div />}

      </Modal.Body>
      <Modal.Footer>
        <div className="mr-auto" style={{ display: "flex" }}>
          <div style={{ marginRight: "10px" }}><Button variant="secondary" onClick={handleConvert}>Convert</Button></div>
          <div style={{ marginRight: "10px" }}><Button variant="secondary" onClick={handleVideo}>Upload Video</Button></div>
          <input type="file" accept="video/*" onChange={onSelectVideoFile} />

          {isUploading ? <SpinnerLoad /> : <div />}
        </div>

        <Button variant="secondary" onClick={handleCancel}>
          Close
        </Button>
        <Button variant="primary" type="button" onClick={handleSave}
          disabled={!completedCrop?.width || !completedCrop?.height} >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  </>;
}

const mapStateToProps = (state) => {
  return {
    isUploading: state.scenes.uploadFile.isUploading
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    uploadVideoFile: (file, id) => dispatch(uploadFileActions.uploadVideoFile(file, id)),
    convertFile: (id) => dispatch(uploadFileActions.convertVideo(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadFile);
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Col, InputGroup, Modal, Button, Row } from 'react-bootstrap';
import dayjs from 'dayjs';
import Select from "react-dropdown-select";

import { statusActions } from '../../actions/scene/statusActions';
import { actorActions } from '../../actions/actor/actorActions';
import { actorTagActions } from '../../actions/actor/actorTagActions';
import { getActorTags, getActotAlias } from '../../controller/sceneController';
import { helper } from '../common/helper';
import { TAGS } from '../../const';

function EditActor(props) {

    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);

    const [actor, setActor] = useState({
        id: props.actor.actor.id,
        name: props.actor.actor.name,
        sexe: props.actor.actor.sexe,
        birthday: new dayjs(props.actor.actor.birthday, "YYYY-MM'DD"),
        birthLocation: props.actor.actor.birthLocation,
        height: props.actor.actor.height,
        weight: props.actor.actor.weight,
        measurement: props.actor.actor.measurement,
        description: props.actor.actor.description,
        status: props.actor.actor.status,
        alias: props.actor.actor.alias
    })

    const [tags, setTags] = useState({ original: [], selectedTag: [] });
    const [alias, setAlias] = useState([])
    const [edit, setState] = useState({
        editTag: false,
        saveTag: false,
        selectedTag: []
    })

    const setTagsCall = (data) => {
        setTags({ ...tags, original: data, selectedTag: data })
    }

    const handleCancel = () => setShow(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        props.updateActor(actor);
    }
    const handleShow = () => {
        setShow(true)
    };

    const refreshData = () => {
        props.getAllStatus();
        props.getAllActorTags();
        props.getActorTagTypes();
        getActorTags(props.actor.actor.id, setTagsCall);
    }

    const refreshActorInfo = () =>{
        props.getActorInfo(props.actor.actor.id)
    }

    /**
     * Refresh Actor Tags
     */
    const refreshTag = () => {
        props.getActorTag(props.actor.actor.id).then(a => {
            setTagsCall(a.tags)
        })
    }

    const addTag = () => {
        var temp = edit.selectedTag;
        temp.forEach(element => {
            props.insertActorTag(props.actor.actor.id, element.tagId).then(() => {
                refreshTag();
            });
        });

        setState({ ...edit, selectedTag: [] })
    }

    const removeTag = (e) => {
        props.removeActorTag(props.actor.actor.id, e.target.getAttribute('value')).then(() => { refreshTag() }
        )
    }

    const addAlias = () =>{
        var temp = alias;
        temp.forEach(element =>{
            props.insertActorAlias(props.actor.actor.id, element.value).then((a)=>{
                refreshActorInfo();
            })
        })
    }

    const removeAlias = (e) => {
        props.removeActorAlias(props.actor.actor.id, e.target.getAttribute('value')).then(() => { refreshActorInfo() }
        )
    }

    const statusSelect = React.forwardRef(() => (
        <select className="custom-select custom-select-sm" value={actor.status} data-live-search="true" onChange={(e) => setActor({ ...actor, status: e.target.value })}>
            <option defaultValue={"DEFAULT"}>Select Status</option>
            {props.allStatus.map(status => <option key={status.id} value={status.id}>{status.name}</option>)}
        </select>));

    const sexeSelect = React.forwardRef(() => (
        <select className="custom-select custom-select-sm" value={actor.sexe} onChange={(e) => setActor({ ...actor, sexe: e.target.value })} data-live-search="true">
            <option key={"F"} value="F">Female</option>
            <option key={"M"} value="M">Male</option>
        </select>));

    return <>
        <Button onClick={handleShow} size="sm">Edit</Button>
        {console.log(props)}

        <Modal size="lg" show={show} onHide={handleCancel}>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Actor Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row>
                        <Form.Group as={Col} md="5" controlId="validationName">
                            <Form.Label srOnly>Name</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Name</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control required type="text" placeholder="Name" defaultValue={actor.name} onChange={(e) => setActor({ ...actor, name: e.target.value })} />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Please choose a valid name</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationSexe">
                            <Form.Label srOnly>Sexe</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Sexe</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control required as={sexeSelect} custom />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationMeasurement">
                            <Form.Label srOnly>Measurement</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Measurement</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="text" defaultValue={actor.measurement} onChange={(e) => setActor({ ...actor, measurement: e.target.value })} />
                                <Form.Control.Feedback type="invalid">Please choose a valid measurement</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="5" controlId="validationBirthday">
                            <Form.Label srOnly>Birthday</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Birthday</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="text" placeholder="YYYY-MM-DD" defaultValue={new dayjs(actor.birthday, "YYYY-MM-DD").format('YYYY-MM-DD')} onChange={(e) => setActor({ ...actor, birthday: e.target.value })} />
                                <Form.Control.Feedback type="invalid">Please choose a valid date</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationHeight">
                            <Form.Label srOnly>Height</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Height</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="text" defaultValue={actor.height} onChange={(e) => setActor({ ...actor, height: e.target.value })} />
                                <Form.Control.Feedback type="invalid">Please choose a valid height</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationWeight">
                            <Form.Label srOnly>Weight</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Weight</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="text" defaultValue={actor.weight} onChange={(e) => setActor({ ...actor, weight: e.target.value })} />
                                <Form.Control.Feedback type="invalid">Please choose a valid weight</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>


                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="5" controlId="validationBirthLocation">
                            <Form.Label srOnly>Birth Location</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Birth Location</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="text" defaultValue={actor.birthLocation} onChange={(e) => setActor({ ...actor, birthLocation: e.target.value })} />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationStatus">
                            <Form.Label srOnly>Status</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Status</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control as={statusSelect} required custom />
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="12" controlId="validationTags">
                            <Form.Label srOnly>Alias</Form.Label>
                            <Row style={{ padding: "10px", marginTop: "10px" }}>
                                <Select style={{ minWidth: "300px" }} multi options={actor.alias} placeholder="+ add alias" values={[]}
                                    color="#000000" searchable="true" sortBy="name" clearable="true" searchBy="name"
                                    dropdownHeight="250px" dropdownPosition="auto"
                                    dropdownHandleRenderer={({ state }) => (<span>{state.dropdown ? '–' : '+'}</span>)} 
                                    onChange={e=> setAlias(e)} create="true"/>
                                <button type="button" className={`btn btn-sm btn-light`} onClick={addAlias}>Add</button>
                            </Row>
                            <Row>
                                <Col>
                                    <>{actor.alias.map((alias, idx) => (
                                        <div key={alias} className="m-1 badge badge-secondary">
                                            <span> <a value={alias} style={{ color: "white" }} onClick={removeAlias}>{alias}</a> </span>
                                        </div>
                                    ))}</>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="12" controlId="validationTags">
                            <Form.Label srOnly>Tags</Form.Label>
                            <Row style={{ padding: "10px", marginTop: "10px" }}>
                                <Select style={{ minWidth: "300px" }} multi options={props.allActorTags} placeholder="+ click to add tag" values={edit.selectedTag} labelField="name" valueField="tagId"
                                    color="#000000" searchable="true" sortBy="name" clearable="true" searchBy="name"
                                    dropdownHeight="250px" dropdownPosition="auto"
                                    dropdownHandleRenderer={({ state }) => (<span>{state.dropdown ? '–' : '+'}</span>)}
                                    onChange={e => setState({ ...edit, selectedTag: e })} />
                                <button type="button" className={`btn btn-sm btn-light`} onClick={addTag}>Add</button>
                                <button type="button" className={`btn btn-sm btn-light`} onClick={refreshData} >Refresh</button>
                            </Row>
                            <>{props.allActorTagTypes.map((cat, idx) => (
                                <Row key={`${cat.id}${idx}`}>
                                    <Col xs={3} sm={3} md={2}>{cat.name}</Col>
                                    <Col>
                                        <Row>
                                            {helper.findTag(TAGS.ACTORTAG, cat.name, tags.selectedTag).map((tag) =>
                                            (<div key={tag.tagActorId} className="m-1 badge badge-secondary">
                                                <span> <a value={tag.tagActorId} style={{ color: "white" }} onClick={removeTag}>{tag.name}</a> </span>
                                            </div>))}
                                        </Row>
                                    </Col>
                                </Row>
                            ))}</>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row >
                        <Form.Group as={Col} md="12" controlId="validationDescription">
                            <Form.Label srOnly>Description</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Description</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control style={{ height: "100px" }} as="textarea" aria-label="With textarea" defaultValue={actor.description} onChange={(e) => setActor({ ...actor, description: e.target.value })} />
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>

                </Modal.Body>
                <Modal.Footer>
                    <div className="mr-auto"> <Button className="mr-auto" variant="secondary" onClick={refreshData}>Refresh</Button></div>
                    <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleSubmit}>Save</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    </>;
}

const mapStateToProps = (state) => {
    return {
        scene: state.scenes.status.scene,
        allStatus: state.scenes.status.allStatus,
        allActorTags: state.actors.actorTags.tags,
        allActorTagTypes: state.actors.actorTags.tagTypes
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllActorTags: () => dispatch(actorTagActions.getActorTags()),
        getActorTagTypes: () => dispatch(actorTagActions.getActorTagTypes()),
        getAllStatus: () => dispatch(statusActions.getAllStatus()),
        getActorInfo: (actorId) => dispatch(actorActions.getActorById(actorId)),
        updateActor: (actor) => dispatch(actorActions.updateActor(actor)),
        insertActorTag: (actorId, tagId) => dispatch(actorActions.insertActorTag(actorId, tagId)),
        removeActorTag: (actorId, tagId) => dispatch(actorActions.removeActorTag(actorId, tagId)),
        getActorTag: (actorId) => dispatch(actorActions.getActorTagByActor(actorId)),
        insertActorAlias: (actorId, alias) => dispatch(actorActions.insertActorAlias(actorId, alias)),
        removeActorAlias: (actorId, alias) => dispatch(actorActions.removeActorAlias(actorId, alias)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditActor);
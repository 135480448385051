import React from 'react';
// import { Container } from 'react-bootstrap';
// import './maps.css';
// import useScript from 'hooks/useScript';
// // import * as google from './google';

// // import 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCcX4tbbsf4b68pEiXuuBQ8s9P_Ud5Jaz8&callback=initAutocomplete&libraries=places&v=weekly'
// function MapHome(props) {

//     // useScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyCcX4tbbsf4b68pEiXuuBQ8s9P_Ud5Jaz8&callback=initAutocomplete&libraries=places&v=weekly');
//     let autocomplete;
//     let address1Field;
//     let address2Field;
//     let postalField;

//     function initAutocomplete() {
//         address1Field = document.querySelector("#ship-address");
//         address2Field = document.querySelector("#address2");
//         postalField = document.querySelector("#postcode");
//         // Create the autocomplete object, restricting the search predictions to
//         // addresses in the US and Canada.
//         // autocomplete = new google.maps.places.Autocomplete(address1Field, {
//         //     componentRestrictions: { country: ["us", "ca"] },
//         //     fields: ["address_components", "geometry"],
//         //     types: ["address"],
//         // });
//         address1Field.focus();
//         // When the user selects an address from the drop-down, populate the
//         // address fields in the form.
//         autocomplete.addListener("place_changed", fillInAddress);
//     }

//     function fillInAddress() {
//         // Get the place details from the autocomplete object.
//         const place = autocomplete.getPlace();
//         let address1 = "";
//         let postcode = "";

//         // Get each component of the address from the place details,
//         // and then fill-in the corresponding field on the form.
//         // place.address_components are google.maps.GeocoderAddressComponent objects
//         // which are documented at http://goo.gle/3l5i5Mr
//         for (const component of place.address_components) {
//             const componentType = component.types[0];

//             switch (componentType) {
//                 case "street_number": {
//                     address1 = `${component.long_name} ${address1}`;
//                     break;
//                 }

//                 case "route": {
//                     address1 += component.short_name;
//                     break;
//                 }

//                 case "postal_code": {
//                     postcode = `${component.long_name}${postcode}`;
//                     break;
//                 }

//                 case "postal_code_suffix": {
//                     postcode = `${postcode}-${component.long_name}`;
//                     break;
//                 }
//                 case "locality":
//                     document.querySelector("#locality").value = component.long_name;
//                     break;
//                 case "administrative_area_level_1": {
//                     document.querySelector("#state").value = component.short_name;
//                     break;
//                 }
//                 case "country":
//                     document.querySelector("#country").value = component.long_name;
//                     break;
//             }
//         }

//         address1Field.value = address1;
//         postalField.value = postcode;
//         // After filling the form with address components from the Autocomplete
//         // prediction, set cursor focus on the second address line to encourage
//         // entry of subpremise information such as apartment, unit, or floor number.
//         address2Field.focus();
//     }

//     return (
//         <>
//             <form id="address-form" action="" method="get" autoComplete="off">
//                 <p className="title">Sample address form for North America</p>
//                 <p className="note"><em>* = required field</em></p>
//                 <label className="full-field">
//                     <span className="form-label">Deliver to*</span>
//                     <input
//                         id="ship-address"
//                         name="ship-address"
//                         required
//                         autoComplete="off"
//                     />
//                 </label>
//                 <label className="full-field">
//                     <span className="form-label">Apartment, unit, suite, or floor #</span>
//                     <input id="address2" name="address2" />
//                 </label>
//                 <label className="full-field">
//                     <span className="form-label">City*</span>
//                     <input id="locality" name="locality" required />
//                 </label>
//                 <label className="slim-field-left">
//                     <span className="form-label">State/Province*</span>
//                     <input id="state" name="state" required />
//                 </label>
//                 <label className="slim-field-right" htmlFor="postal_code">
//                     <span className="form-label">Postal code*</span>
//                     <input id="postcode" name="postcode" required />
//                 </label>
//                 <label className="full-field">
//                     <span className="form-label">Country/Region*</span>
//                     <input id="country" name="country" required />
//                 </label>
//                 <button type="button" className="my-button">Save address</button>

//                 <input type="reset" value="Clear form" />
//             </form>

//             {/* <script
//                 src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCcX4tbbsf4b68pEiXuuBQ8s9P_Ud5Jaz8&callback=initAutocomplete&libraries=places&v=weekly"
//                 async
//             ></script> */}
//         </>)
// }
function MapHome(props) {
    return (<div>Maps</div>)
}

export default MapHome;

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';
import Select from "react-dropdown-select";

import { sceneActions } from '../../actions/scene/sceneActions';

function TagEditor(props) {

    const [tags, setTags] = useState({ original: [], selectedTag: [] });
    const [edit, setState] = useState({
        editTag: false,
        saveTag: false,
        selectedTag: []
    })
    const [msg, setMsg] = useState([''])

    const token = `?token=${localStorage.getItem('token').slice(7)}`;

    const setTagsCall = (data) => {
        setTags({ ...tags, original: data, selectedTag: data })
    }

    const addTag = () => {
        var temp = edit.selectedTag;
        var scenes = props.selectedScene
        temp.forEach(element => {
            scenes.forEach(scene =>{
            props.insertSceneTag(scene, element.id).then(() => {
                var mes = []
                mes = msg
                mes.push(`++ ${scene} => ${element.id} ${element.name}`)
                setMsg(mes)
            });
            })
    
        });

        setState({ ...edit, selectedTag: [] })
    }
    useEffect(()=>{

    },[msg])

    const refreshData = () =>{
        props.getAllTags()
        setMsg([""])
    }

    const removeTag = () => {
        var temp = edit.selectedTag;
        var scenes = props.selectedScene
        temp.forEach(element => {
            scenes.forEach(scene =>{
                props.deleteSceneTag(scene, element.id).then(() => {
                    var mes = []
                    mes = msg
                    mes.push(`-- ${scene} => ${element.id} ${element.name}`)
                    setMsg(mes)
                  }
                    )
            })
    
        });

        setState({ ...edit, selectedTag: [] })
    }


    return <>
        <Row style={{ marginLeft: "10px", marginTop: "10px" }}>
            <h4>Tag Editor</h4>
            <Select multi options={props.allTag} placeholder="+ click to add tag" values={edit.selectedTag} labelField="name" valueField="id"
                color="#000000" searchable="true" sortBy="name" clearable="true" searchBy="name"
                dropdownHeight="250px" dropdownPosition="auto"
                dropdownHandleRenderer={({ state }) => (<span>{state.dropdown ? '–' : '+'}</span>)}
                onChange={e => setState({ ...edit, selectedTag: e })} />
            <button type="button" className={`btn btn-sm btn-light`} onClick={addTag}>Add</button>
            <button type="button" className={`btn btn-sm btn-light`} onClick={refreshData} >Refresh</button>
            <button type="button" className={`btn btn-sm btn-light`} onClick={removeTag}>Remove</button>
            {msg.map((m)=>{
                return <Row><h4 style={{fontSize:"10px"}}>{m}</h4></Row>
            })}
       
        </Row>
        </>
}

const mapStateToProps = (state) =>{
    return {
        allTag: state.sceneReducers.tags,
        selectedScene: state.sceneCardReducers.selectedScene
    }
}

const mapDispatchToProps = (dispatch) =>{
    return{
        getAllTags: () => dispatch(sceneActions.getSceneTags()),
        insertSceneTag: (sceneId, tagId) => dispatch(sceneActions.insertSceneTag(sceneId, tagId)),
        deleteSceneTag: (sceneId, tagId) => dispatch(sceneActions.deleteSceneTag(sceneId, tagId)),
    }
}
export default  connect(mapStateToProps, mapDispatchToProps)(TagEditor)
import { uploadFileActions as actions} from '../../actions/scene/fileDirectoryActions';

const INITIAL_STATE = {
    isUploading: false,
    file: [],
    error: '',
    files: []
}

const uploadFileReducers = (state = INITIAL_STATE, action) =>{
    switch(action.type){
        case actions.UPLOAD_REQ:
            return {
                ...state,
                isUploading: true
            };
        case actions.UPLOAD_SUC:
            return{
                ...state,
                isUploading: false,
                file: action.file
            }
        case actions.SCENE_FILE_SUC:
            return{
                ...state,
                isUploading: false,
                files: action.files
            }
        case actions.UPLOAD_ERR:
            return{
                ...state,
                isUploading: false,
                error: action.error
            }
        default:
            return state;
    }
};

export default uploadFileReducers;
import { actorCardActions } from '../../actions/actor/actorCardActions';
const INITIAL_STATE = {
    isFetching: {
        actor: false,
        count: false,
        filter: false
    },
    error: '',
    actors: [
        {
            "actor": {
                "id": 98,
                "name": "Priya Anjali Rai",
                "sexe": "F",
                "lastActive": "2019-05-19T00:00:00",
                "rating": 91,
                "age": 43,
                "sceneCount": 146,
                "lgURL": "KJayMedia/pornstars/info/0098/model-large.jpg",
                "smURL": "KJayMedia/pornstars/info/0098/model-medium.jpg"
            },
            "tags": [{
                "actorId": 98,
                "tagId": 1,
                "name": "Big Tits",
                "tagType": "TitSize"
            }]
        }],
    count: 0,
    tags: {
        available: [],
        selected: []
    }
}

const actorCardReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actorCardActions.ACTOR_CARD_REQUEST:
            return {
                ...state,
                isFetching: { ...state.isFetching, actor: true }
            };
        case actorCardActions.ACTOR_CARD_SUCCESS:
            return {
                ...state,
                isFetching: { ...state.isFetching, actor: false },
                actors: action.actors,
                tags: action.tags
            }
        case actorCardActions.ACTOR_CARD_FAILURE:
            return {
                ...state,
                isFetching: { ...state.isFetching, actor: false },
                error: action.error
            }
        case actorCardActions.ACTOR_COUNT_REQUEST:
            return {
                ...state,
                isFetching: { ...state.isFetching, count: true }
            }
        case actorCardActions.ACTOR_COUNT_SUCCESS:
            return {
                ...state,
                isFetching: { ...state.isFetching, count: false },
                count: action.count.total
            }
        case actorCardActions.ACTOR_COUNT_FAILURE:
            return {
                ...state,
                isFetching: { ...state.isFetching, count: false },
                error: action.error
            }
        case actorCardActions.ACTOR_FILTER_REQUEST:
            return {
                ...state,
                isFetching: { ...state.isFetching, filter: true }
            }
        case actorCardActions.ACTOR_FILTER_SUCCESS:
            return {
                ...state,
                isFetching: { ...state.isFetching, filter: false },
                tags: action.filters.tags
            }
        default:
            return state;
    }
};

export default actorCardReducers;
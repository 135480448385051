import React, { useState, useEffect, useContext } from "react";
import "./Row.css";
import axios from "axios";
import constants from '../../const.js';
import ShowInfo from './ShowInfo.js';
import { TvContext } from "../../context/TvContext";
import useWindowSize from '../common/useWindowSize';

function Card({ show, openInfo }) {

    return <div style={{ width: "200px" }}>
        <img key={show.CID}
            onClick={() => openInfo(show)}
            className={`row__poster row__posterLarge"}`}
            src={`${constants.POSTER_BASE}${show.info.poster_path}`}
            alt={show.info.name} />
        <div>
            <h1 style={{ fontSize: "14px" }}>{show.info.name}</h1>
        </div>
    </div>

}

function ShowRow({ title, isLargeRow }) {

    const { tvState, setTvState } = useContext(TvContext);
    const [shows, setShows] = useState([]);
    const [viewInfo, setViewInfo] = useState(false);
    const [selected, setSelected] = useState({});

    const size = useWindowSize();
    const WIDTH = 200;

    async function fetchData() {
        const request = await axios.get(`${constants.TRAKT_API_URL}/shows`);
        setShows(request.data);
        return request;
    }

    useEffect(() => {
        fetchData();
    }, []);

    const openInfo = (data) => {
        setViewInfo(true);
        setSelected(data);
        setTvState({
            ...tvState,
            show: {
                ...tvState.show,
                selectedShowId: data.slug,
                selectedLocation: data.location
            }
        })
    };

    const closeInfo = () => {
        setViewInfo(false);
        setTvState({
            ...tvState,
            show: {
                ...tvState.show,
                showSeason: false,
                selectedSeason: {}
            }
        });
    }


    var showRow = [];
    if (shows.length > 0) {
        var shws = shows;
        var i = 0;
        var colSize = (size.width - 250) / WIDTH;
        for (var col = 0; col < Math.ceil(shows.length / colSize); col++) {
            var show = [];
            for (var row = 0; row < colSize; row++) {
                if (i < shows.length) {
                    var a = shws[i];
                    show.push(<Card show={shws[i]} openInfo={openInfo} key={i} />);
                    i++;
                }
            }
            showRow.push(<div className="row__posters" key={col}>{show}</div>);
        }
    }

    return (
        <div className="row_show">
            <h2>{title}</h2>

            {shows.length > 0 ? showRow : null}

{/*             
            <div className="row__posters">
                {shows.map((show) => (
                    <img key={show.CID}
                        onClick={() => openInfo(show)}
                        className={`row__poster ${isLargeRow && "row__posterLarge"}`}
                        src={`${constants.POSTER_BASE}${isLargeRow ? show.info.poster_path : show.info.backdrop_path}`}
                        alt={show.info.name} />
                ))}
            </div> */}
            {viewInfo ? <ShowInfo show={viewInfo} close={closeInfo} data={selected} /> : <></>}

        </div>
    );
}

export default ShowRow;

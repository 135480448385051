import React, { useState, useEffect, useDebugValue } from 'react';
import { useHistory, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { helper } from '../common/helper.js';
import Select from "react-dropdown-select";
import { sceneCardActions } from '../../actions/scene/sceneCardActions.js';
import '../App.css';
import { render } from '@testing-library/react';
import SpinnerLoad from './spinner.js';

function FilterScene(props) {

    const history = useHistory();
    const [direc, setDirec] = useState(props.params.direction);
    const [html, setHtml] = useState(null);

    const url = (key, item) => {
        if (key === "sort")
            return `${props.baseUrl}&sort=${item}&direction=${direc}`;
        if (key === "direction")
            return `${props.baseUrl}&sort=${props.params.sort}&direction=${item.key}`;
    }

    const paramURL = (site, model, tag, movie, studio, company, direction, modelsort, sexe, status, positionCount) => {
        return `/scenes?site=${site}&movie=${movie}&studio=${studio}&company=${company}${modelsort!=null?`&modelsort=${modelsort}`:""}${sexe!=null?`&sexe=${sexe}`:""}${status!=null?`&status=${status}`:""}${model}${tag}&size=${props.params.size}&sort=${props.params.sort}&direction=${direction == null ? props.params.direction : direction}&dateReleased=${props.params.dateReleased}&search=${props.scenes.search}&positionCount=${props.params.positionCount}`;
    }

    const openFilterFunc = () => {
        if (props.openFilter === false)
            props.setOpenFilter(true);
        else
            props.setOpenFilter(false);
    }

    const onSearch = () => {
        history.push(paramURL(props.params.site, helper.query(props.params.model, "model"), helper.query(props.params.tag, "tag"), props.params.movie, props.params.studio, props.params.company, props.params.direction, props.params.modelsort, props.params.sexe, props.params.status, props.params.positionCount));
    }

    const sceneRefresh = (value) => {
        onSearch();
        props.sceneRefresh(value);
    }

    const directionChange = (key, item) => {
        if (direc == 'asc') {
            setDirec('desc')
        }
        else {
            setDirec('asc')
        }
        props.filterRefresh(key, item, direc)
        renderHtml();
    }
    const renderHtml = () => {
        setHtml(
            <div key={props.filters[0].key} className="list-group list-group-horizontal-sm col">
                <div className="list-group-item list-group-item-action list-group-item-dark active">{props.filters[0].title}</div>
                {props.filters[0].items.map(item =>
                    <>
                        {item.key === props.filters[0].selected ?
                            <div style={{ backgroundColor: '#e65649', color: 'white' }} className={`list-group-item list-group-item-action sorter`}>
                                <Link to={url(props.filters[0].key, item.key)} onClick={(e) => directionChange(props.filters[0].key, item.key)}><img src={direc !== 'asc' ? "/icons8-numeric-50.png" : "/icons8-reversed-numerical-sorting-50.png"} width='25' height='25' /></Link>
                                <Link key={item.key} to={url(props.filters[0].key, item.key)} style={{ textDecoration: 'none', color: 'white' }} onClick={(e) => directionChange(props.filters[0].key, item.key)}>{item.value}</Link>
                            </div> : <Link className={`list-group-item list-group-item-action list-group-item-dark`} key={item.key} to={url(props.filters[0].key, item.key)} onClick={(e) => { props.filterRefresh(props.filters[0].key, item.key, direc === 'asc' ? 'desc' : 'asc'); onSearch() }}>{item.value}</Link>
                        }</>
                )}
            </div>);
    }
    useEffect(() => {
        renderHtml();
        if (direc == 'asc') {
            setDirec('desc')
        }
        else {
            setDirec('asc')
        }
    }, [])
    useEffect(() => {
        renderHtml();
    }, [props.params, direc])

    return (<div className="row p-3">
        <div className="col-9">
            <input className="form-control" value={props.scenes.search} onChange={(e) => {
                props.setSceneFilter('SEARCH', e.target.value);
                onSearch();
            }} onKeyDown={(e) => e.key === "Enter" ? sceneRefresh(e.target.value) : ""} />
        </div>
        <div className="col">
            <button className="btn btn-secondary" onClick={(e) => sceneRefresh(props.scenes.search)}>Search</button>
        </div>
        <div className="col">
            {!props.fetching.filter && !props.fetching.scene && !props.fetching.count ?<button className="btn btn-secondary dropdown-toggle" type="button" onClick={openFilterFunc}>Filters</button>:<SpinnerLoad/>}
        </div>

        {props.openFilter && !props.fetching.filter && !props.fetching.scene && !props.fetching.count ?

            <div className="col">
                {/* <div className="row p-1">
                    {props.filters.map(filter =>
                        <div key={filter.key} className="list-group list-group-horizontal-sm col">
                            <div className="list-group-item list-group-item-action list-group-item-dark active">{filter.title}</div>
                            {filter.items.map(item =>
                                <Link key={item.key} to={url(filter.key, item)} onClick={(e) => props.filterRefresh(filter.key, item)}
                                    style={{ backgroundColor: `${item.key === filter.selected ? '#e65649' : ''}`, color: `${item.key == filter.selected ? 'white' : 'black'}` }}
                                    className={`list-group-item list-group-item-action ${item.key === filter.selected ? "sorter" : "list-group-item-dark"}`}><img src="/icons8-numeric-50.png" width='25' height='25' />{item.value}</Link>
                            )}
                        </div>
                    )}

                </div> */}
                {/* <div className="row p-1"> */}
                    {/* {html} */}
                    {/* <div key={props.filters[0].key} className="list-group list-group-horizontal-sm col">
                            <div className="list-group-item list-group-item-action list-group-item-dark active">{props.filters[0].title}</div>
                            {props.filters[0].items.map(item =>
                            <>
                            {item.key === props.filters[0].selected?
                                <div style={{ backgroundColor: '#e65649', color: 'white'}} className={`list-group-item list-group-item-action sorter`}>
                                <Link to={link} onClick={(e)=>{props.filterRefresh(props.filters[0].key, item.key, direc === 'asc'?'desc':'asc'); directionChange(); }}><img src={direc === 'asc'?"/icons8-numeric-50.png":"/icons8-reversed-numerical-sorting-50.png"} width='25' height='25' /></Link>
                                <Link key={item.key} to={url(props.filters[0].key, item.key)} style={{textDecoration: 'none', color: 'white'}} onClick={(e) => { props.filterRefresh(props.filters[0].key, item.key, direc === 'asc'?'desc':'asc');}}>{item.value}</Link>
                                </div>:<Link className={`list-group-item list-group-item-action list-group-item-dark`} key={item.key} to={url(props.filters[0].key, item.key)} onClick={(e) => { props.filterRefresh(props.filters[0].key, item.key, direc === 'asc'?'desc':'asc');onSearch()}}>{item.value}</Link>
                            }</>
                            )}
                        </div> */}
                {/* </div> */}

                <div className="row p-1">
                    {!props.isFetching ?
                        <div className="col">
                            <label>Studio</label>
                            <Select options={props.studio.available} placeholder="Select Studio" labelField="nameScene" valueField="id"
                                color="#000000" searchable="true" clearable="true" searchBy="name" sortBy="name" dropdownHeight="450px" dropdownPosition="auto"
                                values={props.studio.selected == null ? [] : [props.studio.selected]}
                                onChange={(e) => {
                                    if (Array.isArray(e)) {
                                        if (e.length > 0) {
                                            history.push(paramURL(props.params.site, helper.query(props.params.model, "model"), helper.query(props.params.tag, "tag"), props.params.movie, e[0].id, props.params.company ));
                                            // props.studioRefresh(e[0].id)
                                        }
                                        else {
                                            history.push(paramURL(props.params.site, helper.query(props.params.model, "model"), helper.query(props.params.tag, "tag"), props.params.movie, -1, props.params.company))
                                            props.studioRefresh("-1")
                                        }
                                    }
                                }}
                            />

                            {/* {props.studio.selected != null ?
                                <div className="p-2">
                                    <Link to={paramURL(props.params.site, helper.query(props.params.model, "model"), helper.query(props.params.tag, "tag"), props.params.movie, -1)}
                                        onClick={(e) => {
                                            // history.push(paramURL(props.params.site, helper.query(props.params.model, "model"), helper.query(props.params.tag, "tag"), props.params.movie, -1))
                                            props.studioRefresh("-1")
                                        }
                                        }
                                        key={props.studio.selected.id} className="badge badge-dark m-1" >{props.studio.selected.name}</Link>
                                </div> : ""} */}
                        </div> : <div className="col-3"> <Spinner animation="border" variant="danger" size="bg" />
                        </div>}

                    {!props.isFetching ?
                        <div className="col">
                            <label>Sites</label>
                            <Select options={props.sites.available} placeholder="Select Site" labelField="nameScene" valueField="siteId"
                                color="#000000" searchable="true" clearable="true" searchBy="name" sortBy="name" dropdownHeight="450px" dropdownPosition="auto"
                                values={props.sites.selected == null ? [] : [props.sites.selected]}
                                onChange={(e) => {
                                    if (Array.isArray(e)) {
                                        if (e.length > 0) {
                                            history.push(paramURL(e[0].siteId, helper.query(props.params.model, "model"), helper.query(props.params.tag, "tag"), props.params.movie, props.params.studio, props.params.company, props.params.direction, props.params.modelsort, props.params.sexe, props.params.status));
                                            // props.site(e[0].siteId)
                                        }
                                        else {
                                            history.push(paramURL(-1, helper.query(props.params.model, "model"), helper.query(props.params.tag, "tag"), props.params.movie, props.params.studio, props.params.company, props.params.direction, props.params.modelsort, props.params.sexe, props.params.status));
                                            props.site("-1")
                                        }
                                    }
                                }}
                            />

                            {/* {props.sites.selected != null ?
                                <div className="p-2">
                                    <Link to={paramURL(-1, helper.query(props.params.model, "model"), helper.query(props.params.tag, "tag"), props.params.movie, props.params.studio)}
                                        onClick={(e) => props.site("-1")}
                                        key={props.sites.selected.siteId} className="badge badge-dark m-1" >{props.sites.selected.name}</Link>
                                </div> : ""} */}
                        </div> : <div className="col-3"> <Spinner animation="border" variant="danger" size="bg" /></div>}

                    {!props.isFetching ?
                        <div className="col">
                            <label>Movies</label>
                            <Select options={props.movies.available} placeholder="Select Movie" labelField="name" valueField="id"
                                color="#000000" searchable="true" clearable="true" searchBy="name" sortBy="name" dropdownHeight="450px" dropdownPosition="auto"
                                values={props.movies.selected == null ? [] : [props.movies.selected]}
                                onChange={(e) => {
                                    if (Array.isArray(e)) {
                                        if (e.length > 0) {
                                            history.push(paramURL(props.params.site, helper.query(props.params.model, "model"), helper.query(props.params.tag, "tag"), e[0].id, props.params.studio, props.params.company, props.params.direction, props.params.modelsort, props.params.sexe, props.params.status));
                                            // props.movie(e[0].id)
                                        }
                                        else {
                                            history.push(paramURL(props.params.site, helper.query(props.params.model, "model"), helper.query(props.params.tag, "tag"), -1, props.params.studio, props.params.company, props.params.direction, props.params.modelsort, props.params.sexe, props.params.status))
                                            props.movie("-1")
                                        }
                                    }
                                }}
                            />
                            {/* {props.movies.selected != null ?
                                <div className="p-2">
                                    <Link to={paramURL(props.params.site, helper.query(props.params.model, "model"), helper.query(props.params.tag, "tag"), -1, props.params.studio)}
                                        onClick={(e) => {
                                            history.push(paramURL(props.params.site, helper.query(props.params.model, "model"), helper.query(props.params.tag, "tag"), -1, props.params.studio))
                                            props.movie("-1")
                                        }}
                                        key={props.movies.selected.id} className="badge badge-dark m-1" >{props.movies.selected.name}</Link>
                                </div> : ""} */}
                        </div> : <div className="col-3"> <Spinner animation="border" variant="danger" size="bg" /></div>}

                    {!props.isFetching ?
                        <div className="col">
                            <label>Models</label>
                            <Select multi options={props.models.available} values={props.models.selected}
                                placeholder="Select a model..." labelField="nameScene" valueField="id"
                                color="#333" searchable="true" clearable="true" searchBy="name"
                                dropdownHandleRenderer={({ state }) => (<span>{state.dropdown ? '–' : '+'}</span>)}
                                onChange={(e) => {
                                    var temp = e.map(a => a.id).sort();
                                    history.push(paramURL(props.params.site, helper.query(temp, "model"), helper.query(props.params.tag, "tag"), props.params.movie, props.params.studio, props.params.company, props.params.direction, props.params.modelsort, props.params.sexe, props.params.status));
                                }} />
                        </div> : <div className="col-3"> <Spinner animation="border" variant="danger" size="bg" /></div>}
                    {!props.isFetching ?
                        <div className="col">
                            <label>Tags</label>
                            <Select multi options={props.tags.available} values={props.tags.selected}
                                placeholder="Select a tag..." labelField="name" valueField="id"
                                color="#333" searchable="true" clearable="true" searchBy="fullName"
                                dropdownHandleRenderer={({ state }) => (<span>{state.dropdown ? '–' : '+'}</span>)}
                                onChange={(e) => {
                                    var temp = e.map(a => a.id);
                                    history.push(paramURL(props.params.site, helper.query(props.params.model, "model"), helper.query(temp, "tag"), props.params.movie, props.params.studio, props.params.company, props.params.direction, props.params.modelsort, props.params.sexe, props.params.status));
                                }} />
                        </div> : <div className="col-3"> <Spinner animation="border" variant="danger" size="bg" /></div>}
                </div>
            </div> : <></>}
    </div>
    );
}

const mapStateToProps = (state) => {
    return {
        propp: state.sceneCardReducers,
        fetching: state.sceneCardReducers.fetching,
        isFetching: state.sceneCardReducers.fetching.filter,
        sites: state.sceneCardReducers.sites,
        movies: state.sceneCardReducers.movies,
        models: state.sceneCardReducers.models,
        studio: state.sceneCardReducers.studio,
        tags: state.sceneCardReducers.tags,
        openFilter: state.sceneCardReducers.openFilter,
        scenes: state.sceneCardReducers.sceneFilters
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOpenFilter: (val) => dispatch(sceneCardActions.setOpenFilter(val)),
        setSceneFilter: (param, val) => dispatch(sceneCardActions.setSceneFilter(param, val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterScene);
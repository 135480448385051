import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';

import { loginActions } from '../../actions/user/loginActions.js';
import SpinnerLoad from '../common/spinner';

function Login(props) {
    /**local email input state */
    const [email, setEmail] = useState({
        valid: true,
        email: ''
    });
    /**local password state */
    const [pass, setPass] = useState({
        valid: true,
        pass: ''
    })
    /**empty field error */
    const [fieldEmpty, setFieldEmpty] = useState(false);

    /**submit credentials to userAPI */
    const clickSubmit = () => {
        if (email.email !== '' && pass.pass !== '') {
            setFieldEmpty(false);
            props.login(email.email, pass.pass);
        }
        else
            setFieldEmpty(true);
    }
    /**styling for invalid message */
    const InvalidStyle = {
        color: "red",
        marginLeft: "10px"
    }
    /**error validation  */
    const errMsgHTML = <>
        {fieldEmpty ? (<div style={InvalidStyle}>*Some fields are empty!</div>) : ''}
        {props.loginError && !fieldEmpty ? (<div style={InvalidStyle}>*Invalid Credentials</div>) : ''}
    </>

    return (<>
        {props.authorized ?
            <>{props.location.state == null ? <Redirect to='/profile/account' /> :
                <Redirect to={{ pathname: props.location.state.from.pathname, search: props.location.state.from.search, state: { from: props.location.state } }} />}</>
            : <></>}
        <div className="container">
            <div className="row justify-content-center" style={{ marginTop: "50px" }}>

                {props.isFetching ? <SpinnerLoad /> : <div className="col-5">
                    <div className="form-group">
                        <input placeholder="Email" type="email" onChange={(e) => { setEmail({ ...email, email: e.target.value }) }} className="form-control" id="inputEmail" />
                    </div>
                    <div className="form-group">
                        <input placeholder="Password" type="password" onChange={(e) => { setPass({ ...pass, pass: e.target.value }) }} className="form-control" id="inputPassword" />
                    </div>
                    <Link to={'forgot'} className="scene-card-link auto">
                        <div className="mt-auto card-title" style={{ fontSize: "14px" }}>Forgot Password</div>
                    </Link>
                    {errMsgHTML}
                    <button onClick={clickSubmit} className="btn btn-primary" style={{ margin: "10px" }}>Login</button>
                    <a className="btn btn-primary" href="/signup">Sign Up</a>
                </div>
                }

            </div>
        </div>
    </>);
}

const mapStateToProps = (state) => {
    return {
        isFetching: state.users.user.login.isFetching,
        authorized: state.users.user.authorized,
        loginError: state.users.user.login.error
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (email, pass) => dispatch(loginActions.login(email, pass))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
import axios from 'axios';
import constants from '../../const.js';

const SCENE_CARD_REQUEST = "SCENE_CARD_REQUEST";
const SCENE_CARD_SUCCESS = "SCENE_CARD_SUCCESS";
const SCENE_CARD_FAILURE = "SCENE_CARD_FAILURE";

const SCENE_FILTER_REQ = "SCENE_FILTER_REQ";
const SCENE_FILTER_SUC = "SCENE_FILTER_SUC";

const SCENE_COUNT_REQUEST = "SCENE_COUNT_REQUEST";
const SCENE_COUNT_SUCCESS = "SCENE_COUNT_SUCCESS";
const SCENE_COUNT_FAILURE = "SCENE_COUNT_FAILURE";

const sceneCardRequest = () => {
    return {
        type: SCENE_CARD_REQUEST
    };
};

const sceneCardSuccess = (scenes) => {
    return {
        type: SCENE_CARD_SUCCESS,
        scenes: scenes.scenes,
    };
};

const sceneCardFailure = (error) => {
    return {
        type: SCENE_CARD_FAILURE,
        error
    };
};

const setOpenFilter = (val) => {
    return {
        type: "OPEN_FILTER",
        open: val
    }
}

const setSceneFilter = (type, val) => {
    return {
        type: type,
        value: val
    }
}

const filterReq = () => {
    return {
        type: SCENE_FILTER_REQ
    };
}

const filterSuc = (val) => {
    return {
        type: SCENE_FILTER_SUC,
        filters: val
    }
}

const getFilters = (da) => {
    return dispatch => {
        dispatch(filterReq())
        return axios.post(`${constants.DATA1_API_URL}/scenes/filter`, da)
            .then(res => {
                dispatch(filterSuc(res.data))
            })
    };
}



const sceneCountRequest = () => {
    return {
        type: SCENE_COUNT_REQUEST
    }
}
const sceneCountSuccess = (count) => {
    return {
        type: SCENE_COUNT_SUCCESS,
        count: count
    }

}
const sceneCountFailure = (error) => {
    console.log(error);
    return {
        type: SCENE_COUNT_FAILURE
    }
}
export const getScenesCount = (site, tag, model, search, movie, studio, company, dateReleased, modelsort, sexe, status, positionCount, dispReq, dispSucc, dispFail) => {
    const params = {
        site: site,
        movie: movie,
        search: search,
        model: model,
        tag: tag,
        studio: studio,
        company: company,
        dateReleased: dateReleased,
        modelSort: modelsort,
        sexe: sexe,
        status: status,
        positionCount: positionCount
    }
    return dispatch => {
        dispatch(dispReq());
        return axios.get(`${constants.DATA1_API_URL}/scenes/count`, { params })
            .then(res => {
                dispatch(dispSucc(res.data));
                params.sceneIds = res.data.sceneIds
                dispatch(getFilters(params))

            })
            .catch(error => dispatch(dispFail(error)))
    };
};

const getSceneCard = (size, page, site, sort, direction, tag, model, search, movie, studio, company, dateReleased, modelsort, sexe, status, positionCount) => {
    return dispatch => {
        dispatch(getScenes(size, page, site, sort, direction, tag, model, search, movie, studio, company, dateReleased, modelsort, sexe, status, positionCount, sceneCardRequest, sceneCardSuccess, sceneCardFailure, false));
    }
}

const insertHistory = (filter) => {
    return axios.post(`${constants.USER_API.SCENE_SEARCH_HISTORY}`, filter, {
        headers: { 'Authorization': `${localStorage.getItem('token')}` },
    })
}

export const getScenes = (size, page, site, sort, direction, tag, model, search, movie, studio,company, dateReleased, modelsort, sexe, status, positionCount, dispReq, dispSucc, dispFail, t, m) => {
    const params = {
        size: size,
        page: page,
        site: site,
        movie: movie,
        search: search,
        model: model,
        tag: tag,
        studio: studio,
        parent: company,
        dateReleased: dateReleased,
        total: t ? 1 : 0,
        modelsort: modelsort,
        sexe: sexe,
        status: status,
        positionCount: positionCount
    }
    const searchId = {
        search: params.search,
        filter: params
    }
    searchId.filter.sort = sort
    searchId.filter.direction = direction

    if (m === 1)
        params.model = [model[0].id]
    // params.model =  m===1 ? model:[model.id]

    return dispatch => {
        dispatch(dispReq());
        if (!t)
            dispatch(getScenesCount(site, tag, model, search, movie, studio, company, dateReleased, modelsort, sexe, status, positionCount, sceneCountRequest, sceneCountSuccess, sceneCountFailure));
        return axios.get(`${constants.DATA1_API_URL}/scenes/${sort}/${direction}`, { params })
            .then(res => {
                insertHistory(searchId)
                if (m == 1)
                    dispatch(dispSucc(res.data, model));
                else
                    dispatch(dispSucc(res.data));
            })
            .catch(error => dispatch(dispFail(error)))
    };
};


const SCENE_EDIT_SUCCESS = "SCENE_EDIT_SUCCESS";
const SCENE_SELECTION = "SCENE_SELECTION";
const CLEAR_SELECTED_SCENE = "CLEAR_SELECTED_SCENE";
const setSceneEditRequest = (edit) => {
    return {
        type: SCENE_EDIT_SUCCESS,
        isEditing: edit
    };
};

const sceneSelection = (scenes) =>{
    return{
        type: SCENE_SELECTION,
        selectedScene: scenes
    }
}


const clearAllSceneSelection = () =>{
    return{
        type: CLEAR_SELECTED_SCENE,
    }
}


export const sceneCardActions = {
    SCENE_CARD_REQUEST,
    SCENE_CARD_SUCCESS,
    SCENE_CARD_FAILURE,
    SCENE_FILTER_REQ,
    SCENE_FILTER_SUC,

    SCENE_COUNT_REQUEST,
    SCENE_COUNT_SUCCESS,
    SCENE_COUNT_FAILURE,

    getSceneCard,
    setOpenFilter,
    setSceneFilter,
    insertHistory,

    SCENE_EDIT_SUCCESS,
    setSceneEditRequest,

    SCENE_SELECTION,
    sceneSelection,

    CLEAR_SELECTED_SCENE,
    clearAllSceneSelection
};


import { actorActions } from '../../actions/actor/actorActions.js';

const INITIAL_STATE = {
    isFetching: false,
    isFetchingScenes: false,
    creatingActor: false,
    error: '',
    actor: {
        "actor": {
            "id": 1,
            "name": "Model",
            "sexe": "F",
            "sceneCount": 2,
            "lgURL": "http://192.168.2.64/KJayMedia/pornstars/info/0001/model-large.jpg",
            "smURL": "http://192.168.2.64/KJayMedia/pornstars/info/0001/model-medium.jpg",
            "locationId": 119,
            "city": "Chicago",
            "state": "Illanois",
            "country": "America",
            "birthday": "1978-06-30T00:00:00",
            "measurement": "36C-24-38",
            "height": "5'8",
            "weight": "128",
            "description": "description",
            "alias": []
        },
        "scenes": [
            {
                "scene": {
                    "id": 22,
                    "sceneId": "0022",
                    "title": "Title",
                    "date": "2013-06-16T00:00:00",
                    "rating": 89,
                    "siteId": 1
                },
                "site": {
                    "siteId": 1,
                    "shortName": "site",
                    "name": "site"
                },
                "actors": [
                    {
                        "id": 1,
                        "name": "Model"
                    }]
            }],
        "tags": null
    },
    scenes: [],
    sceneCount: 0,
}

const actorReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actorActions.ACTOR_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case actorActions.ACTOR_SUCCESS:
            return {
                ...state,
                isFetching: false,
                actor: action.actor
            }
        case actorActions.ACTOR_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }



        case actorActions.ACTOR_SCENE_REQUEST:
            return {
                ...state,
                isFetchingScenes: true
            };
        case actorActions.ACTOR_SCENE_SUCCESS:
            return {
                ...state,
                isFetchingScenes: false,
                scenes: action.scenes,
                sceneCount: action.sceneCount
            }
        case actorActions.ACTOR_SCENE_FAILURE:
            return {
                ...state,
                isFetchingScenes: false,
                error: action.error
            }



        case actorActions.ACTOR_CREATE_REQUEST:
            return {
                ...state,
                creatingActor: true
            }
        case actorActions.ACTOR_CREATE_SUCCESS:
            return {
                ...state,
                actor: action.actor,
                creatingActor: false
            }
        case actorActions.ACTOR_CREATE_FAILURE:
            return {
                ...state,
                creatingActor: false
            }



        case actorActions.ONE_ACTOR_TAG_REQUEST:
            return {
                ...state
            }
        case actorActions.ONE_ACTOR_TAG_SUCCESS:
            return {
                ...state,
                actor: {
                    ...state.actor,
                    tags: action.tags
                }
            }
        case actorActions.ONE_ACTOR_TAG_FAILURE:
            return {
                ...state,
            }




        default:
            return state;
    }
};

export default actorReducers;
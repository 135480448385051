const PERM_SUC = "PERM_SUC";

const fetchRole = () =>{
    return{
        type: -10
    }
}

const updatePermission = (perm) =>{
    return {
        type: PERM_SUC,
        perm: perm
    }
}
const updateRole = (role) =>{
    return {
        type: role,
    };
};

export const permActions = {
    PERM_SUC,
    updateRole,
    fetchRole,
    updatePermission
}
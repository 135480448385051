
import { movieActions } from '../../actions/movie/movieActions.js';

const INITIAL_STATE = {
    isFetching: false,
    movies: [{
        id: 7,
        title: "High School Reunion",
        releaseDate: "2018-06-18",
        description: "string",
        site: 65,
        scenesNumber: 5
    }],
    error: '',
    movie:{}
}

const movieReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case movieActions.MOVIE_REQ:
            return {
                ...state,
                isFetching: true,
                error: ''
            }
        case movieActions.ALL_MOVIE_SUC:
            return {
                ...state,
                isFetching: false,
                movies: action.movies,
                error: ''
            }
        case movieActions.POST_MOVIE:
            return {
                ...state,
                isFetching: false,
                movie: action.movie,
                error: ''
            }
        case movieActions.MOVIE_ERR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        default:
            return state;
    }
};

export default movieReducers;
import axios from 'axios';
import constants from '../../const.js';
import { helper}  from '../../components/common/helper.js';
const ACTOR_CARD_REQUEST = "ACTOR_CARD_REQUEST";
const ACTOR_CARD_SUCCESS = "ACTOR_CARD_SUCCESS";
const ACTOR_CARD_FAILURE = "ACTOR_CARD_FAILURE";

const ACTOR_COUNT_REQUEST = "ACTOR_COUNT_REQUEST";
const ACTOR_COUNT_SUCCESS = "ACTOR_COUNT_SUCCESS";
const ACTOR_COUNT_FAILURE = "ACTOR_COUNT_FAILURE";

const ACTOR_FILTER_REQUEST = "ACTOR_FILTER_REQUEST";
const ACTOR_FILTER_SUCCESS = "ACTOR_FILTER_SUCCESS";
const ACTOR_FILTER_FAILURE = "ACTOR_FILTER_FAILURE";

const actorCardRequest = () =>{
    return{
        type: ACTOR_CARD_REQUEST
    };
};

const actorCardSuccess = (actors) =>{
    return{
        type: ACTOR_CARD_SUCCESS,
        actors: actors,
        count: actors.count,
        tags: actors.tags
    };
};

const actorCardFailure = (error) =>{
    return{
        type: ACTOR_CARD_FAILURE,
        error
    }
};

const actorCountRequest = () =>{
    return{
        type: ACTOR_COUNT_REQUEST
    }
}

const actorCountSuccess = (count) =>{
    return{
        type: ACTOR_COUNT_SUCCESS,
        count: count
    }
}

const actorCountFailure = () =>{
    return{
        type: ACTOR_COUNT_FAILURE
    }
}

const actorFilterRequest = () =>{
    return{
        type: ACTOR_FILTER_REQUEST
    }
}
const actorFilterSuccess = (val) =>{
    return{
        type: ACTOR_FILTER_SUCCESS,
        filters: val
    }
}

const getActorsFilter = (params, sexe) =>{
    return dispatch =>{
        dispatch(actorFilterRequest)
        return axios.post(`${constants.DATA1_API_URL}/actors/${sexe}/filter`, params)
        .then(res => {
            dispatch(actorFilterSuccess(res.data))
        })
    }
}

const getActorsCount = (sexe, tag, dispReq, dispSucc, dispFail) =>{
    const params = {
        tag: tag,
    };

    return dispatch =>{
        dispatch(dispReq());
        return axios.get(`${constants.DATA1_API_URL}/actors/${sexe}/count${helper.tagQuery(tag,"tag[]")}`).then(res =>{
            dispatch(dispSucc(res.data));
            params.actorsIds = res.data.actorsIds;
            dispatch(getActorsFilter(params, sexe))
        }).catch(err => dispFail(err));
    }
}

const insertHistory = (filter) => {
    return axios.post(`${constants.USER_API.ACTOR_SEARCH_HISTORY}`, filter, {
        headers: { 'Authorization': `${localStorage.getItem('token')}` },
    })
}


const getActorCards = (sexe, sort, direction, size, page, tag) =>{ 
    const params = {
        size: size,
        page: page,
        sort: sort,
        direction: direction,
        tag: tag,
        search: "",
        sexe: sexe
    }   
    return dispatch =>{
        dispatch(actorCardRequest());
        return axios.get(`${constants.DATA1_API_URL}/actors/${sexe}/${sort}/${direction}${helper.tagQuery(tag,"tag[]")}&size=${size}&page=${page}`)
        .then((res) =>{
            insertHistory({filter:params, search:""})
            dispatch(actorCardSuccess(res.data))
            dispatch(getActorsCount(sexe, tag,actorCountRequest,actorCountSuccess, actorCountFailure ))
        })
        .catch(error=> dispatch(actorCardFailure(error)))
    };
};

export const actorCardActions = {
    ACTOR_CARD_REQUEST,
    ACTOR_CARD_SUCCESS,
    ACTOR_CARD_FAILURE,

    ACTOR_COUNT_REQUEST,
    ACTOR_COUNT_SUCCESS,
    ACTOR_COUNT_FAILURE,

    ACTOR_FILTER_REQUEST,
    ACTOR_FILTER_SUCCESS,
    ACTOR_FILTER_FAILURE,

    getActorCards
};

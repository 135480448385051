import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Col, InputGroup, Modal, Button, Row, Container } from 'react-bootstrap';
import styled from "styled-components";
import Select from "react-dropdown-select";

import NewActor from '../actors/new-actor-popup';
import NewSceneTag from '../scenes/new-scene-tag';
import NewMovie from '../scenes/new-movie-popup';
import NewSite from '../scenes/new-site-popup';
import { sceneActions } from '../../actions/scene/sceneActions';
import { statusActions } from '../../actions/scene/statusActions';
import { siteActions } from '../../actions/scene/siteActions';
import { actorActions } from '../../actions/scene/actorActions';
import { movieActions } from '../../actions/movie/movieActions';

import UploadPreview from '../common/upload-preview';
import constants from '../../const';
import NewScenePosition from './new-scene-position';

function NewScene(props) {

    var isMobile = window.innerWidth <= constants.mobileSize;
    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [scene, setScene] = useState({
        site: 0,
        title: "",
        date: "2019-01-01",
        description: "",
        minutes: 0,
        seconds: 0,
        status: 0,
        movie: 0
    })
    const [file, setFile] = useState("");

    const [firstTime, setFirstTime] = useState(true);
    const [actors, setActors] = useState({ original: [], selectedActor: [] });
    const [tags, setTags] = useState({ original: [], selectedTag: [] });

    useEffect(() => {
        if (actors.original != actors.selectedActor) {
            if (actors.selectedActor.length > 0)
                props.updateActor(props.postResponse.postedResult.SceneID, actors.selectedActor);
        }
        if (tags.original != tags.selectedTag) {
            var temp = tags.selectedTag;
            temp.forEach(element => {
                props.insertSceneTag(props.postResponse.postedResult.SceneID, element.id);
            });
        }
    }, [props.postResponse.postedResult.SceneID]);


    const refreshData = () => {
        props.getAllSite();
        props.getAllActor();
        props.getAllMovies();
        props.getAllStatus();
        props.getAllTags();
    }
    const handleCancel = () => setShow(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        props.postScene(scene, file);
    }

    const handleShow = () => {
        setShow(true)
        if (firstTime) {
            refreshData();
            setFirstTime(false);
        }

    };
    const token = `?token=${localStorage.getItem('token').slice(7)}`;

    const customActorRenderer = ({ item, props, state, methods }) => (
        <StyledOption>
            <img src={`${constants.CONTENT_DOMAIN}download/sus-media/actors/${String(item.id).padStart(4,'0')}/model-medium.webp${token}`} style={{ width: "30px", height: "41px" }}/>
            {" "}{item.name}
          <span onClick={() => methods.removeItem(null, item)}>&times;</span>
        </StyledOption>
      );

    const statusSelect = React.forwardRef(() => (
        <select className="custom-select custom-select-sm" value={scene.status} onChange={(e) => setScene({ ...scene, status: e.target.value })} data-live-search="true">
            <option defaultValue={"DEFAULT"}>Select Status</option>
            {props.allStatus.map(status => <option key={status.id} value={status.id}>{status.name}</option>)}
        </select>));

    const siteSelector = React.forwardRef(() => (
        <select className="custom-select custom-select-sm" value={scene.site} onChange={(e) => setScene({ ...scene, site: e.target.value })} data-live-search="true">
            <option defaultValue={"DEFAULT"}>Select Site</option>
            {props.allSite.map(site => <option key={site.id} value={site.id}>{site.name}</option>)}
        </select>));

    const siteSelect = <Select style={{ minWidth: "300px" }} options={props.allSite} placeholder="+ click to select Site" labelField="name" valueField="id"
        color="#000000" searchable="true" sortBy="name" clearable="true" searchBy="name" dropdownHeight="250px" dropdownPosition="auto"
        values={[0]} onChange={e => setScene({ ...scene, site: e[0].id })} />;


    const actorSelect =
        <Select style={{ minWidth: "300px" }} multi required options={props.allActor} optionRenderer={customActorRenderer} placeholder="+ click to add actor" labelField="name" valueField="id"
            color="#000000" searchable="true" sortBy="name" clearable="true" searchBy="name" dropdownHeight="250px" dropdownPosition="auto"
            values={actors.selectedActor} onChange={e => setActors({ ...actors, selectedActor: e })} />;

    const tagSelect =
        <Select style={{ minWidth: "300px" }} multi options={props.allTag} placeholder="+ click to add tag" labelField="name" valueField="id"
            color="#000000" searchable="true" sortBy="name" clearable="true" searchBy="name" dropdownHeight="250px" dropdownPosition="auto"
            values={tags.selectedTag} onChange={e => setTags({ ...tags, selectedTag: e })} />;

    const movieSelect =
        <Select style={{ minWidth: "300px" }} options={props.allMovie} placeholder="select Movie" labelField="title" valueField="id"
            color="#000000" searchable="true" clearable="true" searchBy="title" sortBy="title" dropdownHeight="250px" dropdownPosition="auto"
            values={[0]}
            onChange={(e) => {
                setScene({ ...scene, movie: e[0].id })
            }}
        />;

    return <div style={{ position:"fixed", right: "10px", bottom:"10px", zIndex:30 }}>
        <Button style={{borderRadius:"25px", fontWeight:"bold", color:"white"}} size={`${isMobile ? 'sm' : 'md'}`} onClick={handleShow}>+</Button>
        <Modal size="lg" show={show} onHide={handleCancel} style={{ backgroundColor: 'hsla(0, 0%, 0%, 0.9)' }}>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>New Scene Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row>
                        <Form.Group as={Col} md="6" controlId="validationTitle">
                            <Form.Label htmlFor="inlineFormInputGroup" srOnly>Title</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Title</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control required type="text" placeholder="Title" defaultValue={scene.title} onChange={(e) => setScene({ ...scene, title: e.target.value })} />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Please choose a valid Title</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationMinute">
                            <Form.Label htmlFor="inlineFormInputGroup" srOnly>Length</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Length</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="text" placeholder="Minutes" defaultValue={scene.minutes} onChange={(e) => setScene({ ...scene, minutes: e.target.value })} />
                                <Form.Control type="text" placeholder="Seconds" defaultValue={scene.seconds} onChange={(e) => setScene({ ...scene, seconds: e.target.value })} />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="validationStatus">
                            <Form.Label htmlFor="inlineFormInputGroup" srOnly>Status</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Status</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control as={statusSelect} required custom />
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="6" controlId="validationSite">
                            <Form.Label htmlFor="inlineFormInputGroup" srOnly>Site</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Site</InputGroup.Text>
                                </InputGroup.Prepend>
                                {!props.isFetchingActor ? siteSelect : <div></div>}
                                {/* <Form.Control required as={siteSelect} custom /> */}
                                {/* <Form.Control.Feedback type="invalid">Please choose a valid Title</Form.Control.Feedback> */}
                            </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} md="6" controlId="validationDate">
                            <Form.Label htmlFor="inlineFormInputGroup" srOnly>Date</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Date (YYYY-MM-DD)</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="text" placeholder="date" defaultValue={scene.date} onChange={(e) => setScene({ ...scene, date: e.target.value })} />
                            </InputGroup>
                        </Form.Group>

                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="12" controlId="validationMovie">
                            <Form.Label htmlFor="inlineFormInputGroup" srOnly>Movie</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Movie</InputGroup.Text>
                                </InputGroup.Prepend>
                                {!props.isFetchingActor ? movieSelect : <div></div>}
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} md="12" controlId="validationActor">
                            <Form.Label htmlFor="inlineFormInputGroup" srOnly>Actors</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Actors</InputGroup.Text>
                                </InputGroup.Prepend>
                                {!props.isFetchingActor ? actorSelect : <div></div>}
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="12" controlId="validationTag">
                            <Form.Label htmlFor="inlineFormInputGroup" srOnly>Tags</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Tags</InputGroup.Text>
                                </InputGroup.Prepend>
                                {tagSelect}
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row >
                        <Form.Group as={Col} md="12" controlId="validationDescription">
                            <Form.Label htmlFor="inlineFormInputGroup" srOnly>Description</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Description</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control style={{ height: "100px" }} as="textarea" aria-label="With textarea" defaultValue={scene.description} onChange={(e) => setScene({ ...scene, description: e.target.value })} />
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Col xs={5} md={3}></Col>

                </Modal.Body>
                <Modal.Footer>
                    <Container>

                  
                    <Row>
                    <div className="mr-auto" style={{ display: "flex" }}>
                        <div style={{ marginRight: "5px" }}><NewActor /></div>
                        <div style={{ marginRight: "5px" }}><NewSceneTag /></div>
                        <div style={{ marginRight: "5px" }}><NewMovie /></div>
                        <div style={{ marginRight: "5px" }}><NewSite /></div>
                        <div style={{ marginRight: "5px" }}><NewScenePosition/></div>
                        <div style={{ marginRight: "5px" }}><UploadPreview setFile={setFile} /></div>
                    </div>
                    <div className="mr-auto">
                        <Button className="mr-auto" variant="secondary" onClick={refreshData}>Refresh</Button>
                    </div>
                    <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleSubmit}>Save</Button>
                    </Row>
                    {props.postResponse.postedScene? <Row>
                        <p style={{color:"green"}}>{props.postResponse.postedResult.message}</p>
                    </Row>: null}
                    
                    </Container>
                </Modal.Footer>
            </Form>

        </Modal>
    </div>;
}

const StyledOption = styled.span`
padding: 3px 10px;
color: #555;
border-radius: 3px;
margin: 3px;
cursor: pointer;
display: inline-flex;
flex-direction: row;
border: 1px solid #555;
transition: all 1s ease-in;

span {
  display: none;
  transition: all 1s ease-in;
}

a {
  margin: 0 5px;
}

:hover {
  background: #f2f2f2;

  span {
    display: inline;
    margin: 0 0 0 5px;
    color: red;
  }
}
`;

const mapStateToProps = (state) => {
    return {
        // scene: state.scenes.status.scene,
        allStatus: state.scenes.status.allStatus,
        allSite: state.scenes.site.allSite,
        allTag: state.sceneReducers.tags,
        allMovie: state.movies.movies.movies,

        isFetchingActor: state.scenes.actor.isFetching,
        allActor: state.scenes.actor.allActor,
        actors: state.scenes.actor.actors,
        postResponse: state.scenes.status,
        uploadedScene: state.scenes.status.scene.id
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllSite: () => dispatch(siteActions.getAllSite()),
        getAllActor: () => dispatch(actorActions.getAllActor()),
        getAllTags: () => dispatch(sceneActions.getSceneTags()),
        getAllStatus: () => dispatch(statusActions.getAllStatus()),
        getAllMovies: () => dispatch(movieActions.getAllMovies()),

        postScene: (scene, file) => dispatch(statusActions.postSceneAndFile(scene, file)),
        updateActor: (scene, actor) => dispatch(actorActions.updateActor(scene, actor)),
        insertSceneTag: (sceneId, tagId) => dispatch(sceneActions.insertSceneTag(sceneId, tagId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewScene);
import { PERM } from '../../const.js';
import { permActions } from '../../actions/user/permActions.js';

const INITIAL_STATE = {
    isFetching: false,
    content_domain: '',
    perms: [],
    scenes: PERM.NONE,
    scenesView: PERM.NONE,
    actors: PERM.NONE,
    actorsView: PERM.NONE,
    movies: PERM.NONE,
    shows: PERM.NONE
}

const permissionReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case permActions.PERM_SUC:
            return{
                ...state,
                isFetching: false,
                perms: action.perm
            }
        case -10:
            return {
                ...state,
                isFetching: true
            }
        case -1:
            return {
                ...state,
                content_domain: action.domain
            };
        case 1:
            return {
                ...state,
                isFetching: false,
                scenes: PERM.ADMIN,
                scenesView: PERM.ADMIN,
                actors: PERM.ADMIN,
                actorsView: PERM.ADMIN,
                movies: PERM.ADMIN,
                shows: PERM.ADMIN
            };
        case 9:
            return {
                ...state,
                isFetching: false,
                scenes: PERM.NONE,
                scenesView: PERM.NONE,
                actors: PERM.NONE,
                actorsView: PERM.NONE,
                movies: PERM.NONE,
                shows: PERM.NONE
            };
        case 10:
            return {
                ...state,
                isFetching: false,
                scenes: PERM.READ,
                scenesView: PERM.READ,
                actors: PERM.READ,
                actorsView: PERM.READ,
                movies: PERM.NONE,
                shows: PERM.NONE
            };

        default:
            return state;
    }
};

export default permissionReducers;
import { siteActions } from '../../actions/scene/siteActions.js';

const INITIAL_STATE = {
  isFetching: false,
  allSite: [{
    id: 1,
    name: ''
  }],
  allMainSite:{},
  error: ''
}

const siteReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case siteActions.SITE_REQ:
      return {
        ...state,
        isFetching: true
      }
    case siteActions.ALL_SITE_SUC:
      return {
        ...state,
        isFetching: false,
        allSite: action.allSite
      }
    case siteActions.ALL_MAINSITE_SUC:
      return {
        ...state,
        isFetching: false,
        allMainSite: action.allMainSite
      }
    case siteActions.SITE_ERR:
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    default:
      return state;
  }
};

export default siteReducers;
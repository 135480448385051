import axios from 'axios';
import React, { useState } from 'react';
import constants from '../../const.js'
import queryString from 'query-string';

const ResetPassword = (props) => {

    var params = queryString.parse(props.location.search);
    /**local email input state */
    const [email, setEmail] = useState({
        valid: true,
        email: ''
    });
    /**local password state */
    const [pass, setPass] = useState({
        valid: true,
        pass: ''
    })
    /**local password state */
    const [passConfirm, setPassConfirm] = useState({
        valid: true,
        passConfirm: ''
    })

    const resetPassword = async () => {
        var data = JSON.stringify({
            "email": email.email,
            "password": pass.pass
          });
        var config = {
            method: 'post',
            url: `${constants.USER_API_URL}/auth/resetPwd`,
            headers: { 
              'Authorization': `Bearer ${params.token}`, 
              'Content-Type': 'application/json'
            },
            data : data
          };

        if (pass.pass === passConfirm.passConfirm && pass.pass !== '') {
            await axios(config).then(res => {
                console.log(res);
            });

        }
        else {

        }
    }

    const submit = () => {
        resetPassword();
    }

    return <div className="container">
        <div className="row justify-content-center" style={{ marginTop: "50px" }}>
            <div className="col-5">
                Reset Password
                <div className="form-group">
                    <input placeholder="Email" type="email" onChange={(e) => { setEmail({ ...email, email: e.target.value }) }} className="form-control" id="inputEmail" />
                </div>
                <div className="form-group">
                    <input placeholder="Password" type="password" onChange={(e) => { setPass({ ...pass, pass: e.target.value }) }} className="form-control" id="inputPassword" />
                </div>
                <div className="form-group">
                    <input placeholder="Confirm Password" type="password" onChange={(e) => { setPassConfirm({ ...passConfirm, passConfirm: e.target.value }) }} className="form-control" id="inputPasswordConfirm" />
                </div>
                {/* {errMsgHTML} */}
                <button className="btn btn-primary" style={{ margin: "10px" }} onClick={submit}>Reset Password</button>
            </div>
        </div>
    </div>
}

export default ResetPassword;
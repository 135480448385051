import axios from 'axios';
import constants from '../../const.js';
import { getScenes } from './sceneCardActions';

const SCENE_REQUEST = "SCENE_REQUEST";
const SCENE_SUCCESS = "SCENE_SUCCESS";
const SCENE_FAILURE = "SCENE_FAILURE";
const sceneRequest = () => {
    return {
        type: SCENE_REQUEST
    };
};
const sceneSuccess = (scene) => {
    return {
        type: SCENE_SUCCESS,
        scene: scene
    };
};
const sceneFailure = (error) => {
    return {
        type: SCENE_FAILURE,
        error
    }
};
const getScene = (id) => {
    return dispatch => {
        dispatch(sceneRequest());
        return axios.get(`${constants.DATA1_API_URL}/scene/${id}`)
            .then(res => {
                if (res.status != 200) {
                    dispatch(sceneFailure('Scene does not exist'));
                }
                else {
                    dispatch(relatedSceneReset());
                    res.data.actors.forEach(actor => {
                        if (actor.sexe === 'F') {
                            dispatch(getRelatedSceneCard(15, 1, -1, 'date', 'desc', [actor], '', -1));
                        }
                    });

                    dispatch(getRelatedSceneCard(15, 1, res.data.site.siteId, 'date', 'desc', [], '', -1));
                    if (res.data.movie.id > 0)
                        dispatch(getRelatedSceneCard(15, 1, -1, 'date', 'desc', [], '', res.data.movie.id));

                    dispatch(relatedSceneSuccess());
                    dispatch(sceneSuccess(res.data))
                }
            })
            .catch(error => {
                console.log(error);
                dispatch(sceneFailure(error));
            })
    }
};

const getOnlyScene = (id) => {
    return dispatch => {
        dispatch(sceneRequest());
        return axios.get(`${constants.DATA1_API_URL}/scene/${id}`)
            .then(res => {
                if (res.status != 200) {
                    dispatch(sceneFailure('Scene does not exist'));
                }
                else {
                    dispatch(sceneSuccess(res.data))
                }
            })
            .catch(error => {
                console.log(error);
                dispatch(sceneFailure(error));
            })
    }
};

const SCENETAG_REQUEST = "SCENETAG_REQUEST";
const SCENETAG_SUCCESS = "SCENETAG_SUCCESS";
const SCENETAG_FAILURE = "SCENETAG_FAILURE";
const sceneTagsRequest = () => {
    return {
        type: SCENETAG_REQUEST
    };
};

const sceneTagsSuccess = (tags) => {
    return {
        type: SCENETAG_SUCCESS,
        tags: tags
    };
};
const sceneTagsFailure = (error) => {
    return {
        type: SCENETAG_FAILURE,
        error
    };
};
const getSceneTags = () => {
    return dispatch => {
        dispatch(sceneTagsRequest());
        return axios.get(`${constants.DATA1_API_URL}/scenes/tags`)
            .then(res => dispatch(sceneTagsSuccess(res.data)))
            .catch(error => dispatch(sceneTagsFailure(error)));
    };
};
const createSceneTags = (body) => {
    return dispatch => {
        dispatch(sceneTagsRequest());
        return axios.post(`${constants.DATA1_API_URL}/scenes/tags`,body,{
            headers:{ 'Authorization' : `${localStorage.getItem('token')}`
           }
        });
    };
};
const updateSceneTags = (id, body) => {
    return dispatch => {
        dispatch(sceneTagsRequest());
        return axios.post(`${constants.DATA1_API_URL}/scenes/tags/${id}`,body,{
            headers:{ 'Authorization' : `${localStorage.getItem('token')}`
           }
        });
    };
};
const insertSceneTag = (sceneId, tagId) => {
    return dispatch => {
        dispatch(sceneTagRequest());
        return axios.post(`${constants.DATA1_API_URL}/scene/${sceneId}/tags/${tagId}`,{},{
            headers:{ 'Authorization' : `${localStorage.getItem('token')}` }
        });
    };
};

const deleteSceneTag = (sceneId, tagId) => {
    return dispatch => {
        return axios.delete(`${constants.DATA1_API_URL}/scene/${sceneId}/tags/${tagId}`,{
            headers:{ 'Authorization' : `${localStorage.getItem('token')}` }
        });
    }
}

const deleteAllSceneTag = (sceneId) => {
    return dispatch => {
        return axios.delete(`${constants.DATA1_API_URL}/scene/${sceneId}/allTags`,{
            headers:{ 'Authorization' : `${localStorage.getItem('token')}` }
        });
    }
}

const COLOR_REQUEST = "COLOR_REQUEST";
const COLOR_SUCCESS = "COLOR_SUCCESS";
const COLOR_FAILURE = "COLOR_FAILURE";
const colorRequest = () => {
    return {
        type: COLOR_REQUEST
    };
};

const colorSuccess = (colors) => {
    return {
        type: COLOR_SUCCESS,
        colors: colors
    };
};
const colorFailure = (error) => {
    return {
        type: COLOR_FAILURE,
        error
    };
};
const getColors = () =>{
    return dispatch => {
        dispatch(colorRequest());
        return axios.get(`${constants.DATA1_API_URL}/scenes/colors`)
            .then(res => dispatch(colorSuccess(res.data)))
            .catch(error => dispatch(colorFailure(error)));
    };
}

const downloadSceneHistory = (sceneId) => {
    return dispatch => {
        dispatch(sceneTagRequest());
        return axios.post(`${constants.DATA1_API_URL}/scene/${sceneId}/download`,{},{
            headers:{ 'Authorization' : `${localStorage.getItem('token')}` }
        });
    };
};


const flagSceneError = (sceneId) => {
    return dispatch => {
        dispatch(sceneTagRequest());
        return axios.post(`${constants.DATA1_API_URL}/scene/${sceneId}/error`,{},{
            headers:{ 'Authorization' : `${localStorage.getItem('token')}` }
        });
    };
};

const ONESCENETAG_REQUEST = "ONESCENETAG_REQUEST";
const ONESCENETAG_SUCCESS = "ONESCENETAG_SUCCESS";
const ONESCENETAG_FAILURE = "ONESCENETAG_FAILURE";
const sceneTagRequest = () => {
    return {
        type: ONESCENETAG_REQUEST
    };
};

const sceneTagSuccess = (tags) => {
    return {
        type: ONESCENETAG_SUCCESS,
        tags: tags
    };
};
const sceneTagFailure = (error) => {
    return {
        type: ONESCENETAG_FAILURE,
        error
    };
};

const REL_SCENE_CARD_REQUEST = "REL_SCENE_CARD_REQUEST";
const REL_SCENE_CARD_SUCCESS = "REL_SCENE_CARD_SUCCESS";
const REL_SITE_SCENE_CARD_SUCCESS = "REL_SITE_SCENE_CARD_SUCCESS";
const REL_MOVIE_SCENE_CARD_SUCCESS = "REL_MOVIE_SCENE_CARD_SUCCESS";

const REL_SCENE_CARD_RESET = "REL_SCENE_CARD_RESET";
const REL_SCENE_CARD_FAILURE = "REL_SCENE_CARD_FAILURE";
const relatedSceneCardRequest = () => {
    return {
        type: REL_SCENE_CARD_REQUEST
    };
};

const relatedSceneSuccess = () => {
    return {
        type: "REL_SCENE_SUCCESS"
    };
};

const relatedSceneCardSuccess = (scenes, model) => {
    return {
        type: REL_SCENE_CARD_SUCCESS,
        models: {
            models: model,
            scenes: scenes.scenes,
            count: scenes.count
        }
    };
};


const relatedSiteSceneCardSuccess = (scenes) => {
    return {
        type: REL_SITE_SCENE_CARD_SUCCESS,
        site: {
            scenes: scenes.scenes,
            count: scenes.count
        }
    };
};
const relatedMovieSceneCardSuccess = (scenes) => {
    return {
        type: REL_MOVIE_SCENE_CARD_SUCCESS,
        movie: {
            scenes: scenes.scenes,
            count: scenes.count
        }
    };
};

const relatedSceneReset = () => {
    return {
        type: REL_SCENE_CARD_RESET
    };
};
const relatedSceneCardFailure = (error) => {
    return {
        type: REL_SCENE_CARD_FAILURE,
        error
    };
};
const getRelatedSceneCard = (size, page, site, sort, direction, model, search, mov) => {


    if ((mov != -1 || mov != 0) && site == -1 && model.length == 0) {
        return dispatch => dispatch(getScenes(size, page, site, sort, direction, [], model, '', mov, -1, -1,'',null,null,null, relatedSceneCardRequest, relatedMovieSceneCardSuccess, relatedSceneCardFailure, true));
    }
    else if (model.length != 0) {
        return dispatch => dispatch(getScenes(size, page, -1, sort, direction, [], model, '', -1, -1, -1,'', null,null,null,relatedSceneCardRequest, relatedSceneCardSuccess, relatedSceneCardFailure, true, 1));
    }
    else if (site != -1) {
        return dispatch => dispatch(getScenes(size, page, site, sort, direction, [], model, '', -1, -1, -1,'', null,null,null,relatedSceneCardRequest, relatedSiteSceneCardSuccess, relatedSceneCardFailure, true));
    }

    else {
        return null;
    }

};

const getSceneTag = (id) => {
    return dispatch => {
        dispatch(sceneTagRequest());
        return axios.get(`${constants.DATA1_API_URL}/scene/${id}/tags`)
            .then(res => dispatch(sceneTagSuccess(res.data)))
            .catch(error => dispatch(sceneTagFailure(error)))
    }
}

const POSITION_TAG_REQUEST = "POSITION_TAG_REQUEST";
const POSITION_TAG_SUCCESS = "POSITION_TAG_SUCCESS";
const POSITION_TAG_FAILURE = "POSITION_TAG_FAILURE";
const positionTagRequest = () => {
    return {
        type: POSITION_TAG_REQUEST
    };
};

const positionTagSuccess = (posTag) => {
    return {
        type: POSITION_TAG_SUCCESS,
        positionTags: posTag
    };
};
const positionTagFailure = (error) => {
    return {
        type: POSITION_TAG_FAILURE,
        error
    };
};
const getPositionTags = () =>{
    return dispatch =>{
        dispatch(positionTagRequest())
        return axios.get(`${constants.DATA1_API_URL}/scenes/tag-position`)
        .then(res => dispatch(positionTagSuccess(res.data)))
        .catch(error => dispatch(positionTagFailure(error)));
    };
}

const POSITION_REQUEST = "POSITION_REQUEST";
const POSITION_SUCCESS = "POSITION_SUCCESS";
const POSITION_FAILURE = "POSITION_FAILURE";
const positionRequest = () => {
    return {
        type: POSITION_REQUEST
    };
};

const positionSuccess = (pos) => {
    return {
        type: POSITION_SUCCESS,
        positions: pos
    };
};
const positionFailure = (error) => {
    return {
        type: POSITION_FAILURE,
        error
    };
};
const getPositions = () =>{
    return dispatch =>{
        dispatch(positionRequest())
        return axios.get(`${constants.DATA1_API_URL}/scenes/positions`)
        .then(res => dispatch(positionSuccess(res.data)))
        .catch(error => dispatch(positionFailure(error)));
    };
}

const insertPosition = (body) => {
    return dispatch => {
        return axios.post(`${constants.DATA1_API_URL}/scenes/positions`,body,{
            headers:{ 'Authorization' : `${localStorage.getItem('token')}`
           }
        });
    };
};
const updatePosition = (id, body) => {
    return dispatch => {
        return axios.post(`${constants.DATA1_API_URL}/scenes/positions/${id}`,body,{
            headers:{ 'Authorization' : `${localStorage.getItem('token')}`
           }
        });
    };
};
const insertPositionTag = (posId, tagId) => {
    return dispatch => {
        return axios.post(`${constants.DATA1_API_URL}/scenes/positions/${posId}/tags/${tagId}`,{},{
            headers:{ 'Authorization' : `${localStorage.getItem('token')}` }
        });
    };
};

const deletePositionTag = (posId, tagId) => {
    return dispatch => {
        return axios.delete(`${constants.DATA1_API_URL}/scenes/positions/${posId}/tags/${tagId}`,{
            headers:{ 'Authorization' : `${localStorage.getItem('token')}` }
        });
    }
}

export const sceneActions = {
    SCENE_REQUEST,
    SCENE_SUCCESS,
    SCENE_FAILURE,
    getScene,
    getOnlyScene,

    SCENETAG_REQUEST,
    SCENETAG_SUCCESS,
    SCENETAG_FAILURE,
    getSceneTags,
    updateSceneTags,
    createSceneTags,
    insertSceneTag,
    deleteSceneTag,
    deleteAllSceneTag,

    COLOR_REQUEST,
    COLOR_SUCCESS,
    COLOR_FAILURE,
    getColors,

    POSITION_TAG_REQUEST,
    POSITION_TAG_SUCCESS,
    POSITION_TAG_FAILURE,
    getPositions,
    insertPosition,
    updatePosition,
    insertPositionTag,
    deletePositionTag,

    POSITION_REQUEST,
    POSITION_SUCCESS,
    POSITION_FAILURE,
    getPositionTags,

    ONESCENETAG_REQUEST,
    ONESCENETAG_SUCCESS,
    ONESCENETAG_FAILURE,
    getSceneTag,

    REL_SCENE_CARD_REQUEST,
    REL_SCENE_CARD_SUCCESS,
    REL_SITE_SCENE_CARD_SUCCESS,
    REL_MOVIE_SCENE_CARD_SUCCESS,
    REL_SCENE_CARD_RESET,
    REL_SCENE_CARD_FAILURE,
    getRelatedSceneCard,
    relatedSceneReset,

    downloadSceneHistory,
    flagSceneError
    
};

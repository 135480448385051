import React, { useState, useEffect } from 'react';
import './Banner.css';
import axios from 'axios';
import constants from '../../const.js';
import { Link } from 'react-router-dom';

function Banner() {

  const [lastWatched, setLastWatched] = useState(null);
  const [lastEpisode, setLastEpisode] = useState(null);
  const base_url = "https://image.tmdb.org/t/p/original/";

  var token = ''
  if (localStorage.getItem('token') != null) {
    token = `?token=${localStorage.getItem('token').slice(7)}`;
  }

  const fetchLatest = async () => {
    await axios.get(`${constants.USER_API.USER_API_URL}/shows/latest`,
      {
        headers: { 'Authorization': `${localStorage.getItem('token')}` }
      }).then(data => {
        setLastWatched(data.data);
        fetchEpisode(data.data.slug, data.data.season, data.data.episode)
      }).catch(err => console.log(err));
  }

  const fetchEpisode = async (tv_id, season_number, episode_number) => {
    const request = await axios.get(`${constants.MOVIE_DB_API}/tv/${tv_id}/season/${season_number}/episode/${episode_number}${constants.MOVIE_DB_KEY}`)
      .then(data => setLastEpisode(data.data)).catch(err => console.log(err))
    return request;
  }

  function truncate(str, n) {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  }

  useEffect(() => {
    fetchLatest();
  }, [])

  return (
    <header
      className="banner"
      style={{
        backgroundSize: "cover",
        backgroundImage: `url("${base_url}/${lastEpisode?.still_path}")`,
        backgroundPosition: "center center",
      }}>
      {lastWatched!==null?
      <div className="banner__contents">
        <h1 className="banner__title">{lastWatched?.title} </h1>
        <h3>Continue Watching Season: {lastWatched?.season}, Episode: {lastWatched?.episode}</h3>
        <div>
         
            <Link style={{ color: 'inherit', textDecoration: 'inherit' }} to={{
              pathname: '/player',
              search: `?type=s&show=${lastWatched?.location}&season=${lastWatched?.season}&episode=${lastWatched?.episode}&tid=${lastWatched?.slug}`,
            }}> <button className="banner__button">PLAY</button></Link>
          {/* <button className="banner__button">My list</button> */}
        </div>
        <h1 className="banner__description">
          {truncate(lastEpisode?.overview, 150)}
        </h1>
      </div>:null}
      <div className="banner__fadeBottom"></div>
    </header>
  )

}


export default Banner;
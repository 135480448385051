import axios from 'axios';
import constants from '../../const.js';
import { uploadFileActions} from './fileDirectoryActions.js';

const STATUS_REQ = "STATUS_REQ";
const ALL_STATUS_SUC = "ALL_STATUS_SUC";
const STATUS_ERR = "STATUS_ERR";
const POST_SCENE = "POST_SCENE";

const status_REQ = () =>{
    return{
        type: STATUS_REQ
    };
};
const allStatus_SUC = (allStatus) =>{
    return{
        type: ALL_STATUS_SUC,
        allStatus: allStatus
    };
}
const postScene_SUC = (scene) =>{
    return{
        type: POST_SCENE,
        scene: scene, 
        postedScene: true
    };
}
const status_ERR = (error) => {
    return{
        type: STATUS_ERR,
        error: error
    };
};
const getAllStatus = () => {
    return dispatch =>{
        dispatch(status_REQ());
        return axios.get(`${constants.DATA_API.STATUS_URL}`)
        .then(res =>dispatch(allStatus_SUC(res.data)))
        .catch(error=> dispatch(status_ERR(error)))
    }
}
const updateScene = (sceneId, scene) =>{
    return dispatch =>{
        dispatch(status_REQ());
        return axios.put(`${constants.DATA1_API_URL}/scene/${sceneId}`,scene,{
            headers:{ 'Authorization' : `${localStorage.getItem('token')}` }
        })
        .catch(err=>dispatch(status_ERR(err)))
        .finally(a=> dispatch(uploadFileActions.renameVideo(sceneId)));
    };
};

const postScene = (scene) =>{
    return dispatch =>{
        dispatch(status_REQ());
        return axios.post(`${constants.DATA1_API_URL}/scene`,scene)
        .then(res=>{
            dispatch(postScene_SUC(res.data))
        })
        .catch(err=>dispatch(status_ERR(err)));
    };
};

const postSceneAndFile = (scene, file) =>{
    return dispatch =>{
        dispatch(status_REQ());
        return axios.post(`${constants.DATA1_API_URL}/scene`,scene)
        .then(res=>{
            dispatch(postScene_SUC(res.data))
            dispatch(uploadFileActions.uploadFile(file, res.data.SceneID))
        })
        .catch(err=>dispatch(status_ERR(err)));
    };
};

export const statusActions = {
    STATUS_REQ,
    ALL_STATUS_SUC,
    POST_SCENE,
    STATUS_ERR,
    getAllStatus,
    updateScene,
    postScene,
    postSceneAndFile
};

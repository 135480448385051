import React, { useEffect, useState } from 'react';
import { CardDeck, Container, Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import queryString from 'query-string';

import SpinnerLoad from '../common/spinner';
import SceneCard from '../scenes/scene-card';
import PaginationComponent from '../common/pagination';
import ActorViewFeature from './actor-view-feature';
import EditActor from './edit-actor-popup';

import { actorActions } from '../../actions/actor/actorActions.js'
import { helper } from '../common/helper.js';
import constants, { PERM } from '../../const';
import UploadActorFile from '../common/upload-actor-file';
import ContentRequest from '../tv/ContentRequest';
import ActorGallery from './actor-gallery';

function ActorView(props) {

    const [editMode, setEditMode] = useState({
        edit: false,
        save: false,
    });
    const { getActor, getActorScenes } = props;
    const [tab, setTab] = useState(0);

    let params = queryString.parse(props.location.search);

    if (params.size == null)
        params.size = 50;
    if (params.page == null)
        params.page = 1;
    if (params.sort == null)
        params.sort = "date";
    if (params.direction == null)
        params.direction = "desc";
    if (params.model == null)
        params.model = props.match.params.id

    const refresh = () =>{
        getActor(parseInt(props.match.params.id));
        getActorScenes(params.size, params.page, -1, params.sort, params.direction, [], [parseInt(props.match.params.id)]);
    }
    useEffect(() => {
      refresh()
        // eslint-disable-next-line
    }, [props.match.params.id]);

    const changeTab = (num) =>{
        setTab(num)
    }

    const paginate = (pageNumber) => props.getActorScenes(params.size, pageNumber, params.site, params.sort, params.direction, params.tag, [parseInt(params.model)])
    if (!props.isFetching) {
        var token = `?token=${localStorage.getItem('token').slice(7)}`;
        var scenes = props.scenes;
        var sceneCardElement = [];
        var i = 0;

        if (scenes != null) {
            var colSize = 1;
            for (var col = 0; col < Math.ceil(scenes.length / colSize); col++) {
                var cardDeck = [];
                for (var row = 0; row < colSize; row++) {
                    if (i < scenes.length) {
                        cardDeck.push(<SceneCard style={{ width: "200px" }} key={i} scene={scenes[i]} roles={props.perms}/>);
                        i++;
                    }
                }
                sceneCardElement.push(<CardDeck key={col} style={{ padding: "10px" }}>{cardDeck}</CardDeck>);
            }
        }
        return (
            <>
            <ContentRequest/>
                <Container style={{ maxWidth: `${window.innerWidth}px` }}>
                    <Container>
                        <Row className="justify-content-center" style={{ paddingTop: "50px" }}>
                            <Col><img src={`${constants.CONTENT_DOMAIN}${props.actor.actor.lgURL}${token}`} alt="Actor" style={{ width: "386px", height: "520px" }}></img></Col>
                            {!props.isFetching ?
                                <Col>
                                    <Row>
                                        <Col><h1>{props.actor.actor.name} </h1></Col>
                                        {props.perms.some(e => e.role.id === 1) ? <Col>
                                            <Row>
                                                <Col style={{ padding: "5px" }}><Button size="sm" onClick={refresh}>Refresh</Button></Col>
                                                <Col style={{ padding: "5px" }}><EditActor actor={props.actor}/></Col>
                                                <Col style={{ padding: "5px" }}><UploadActorFile actorId={props.actor.actor.actorId} poster={props.actor.actor.lgURL} /></Col>
                                            </Row>
                                        </Col> : <></>}
                                    </Row>
                                    <Row>
                                        <Col>
                                            <ActorViewFeature title="Rank" value={helper.findActorTag("ActorType", props.actor.tags)} isArray={true}/>
                                            <ActorViewFeature title="Birth Location" value={props.actor.actor.birthLocation === null ? 'Unknown' : props.actor.actor.birthLocation} />
                                            <ActorViewFeature title="Birthday" value={props.actor.actor.birthday} />
                                            <ActorViewFeature title="Hair Color" value={helper.findActorTag("HairColor", props.actor.tags)} isArray={true}/>
                                            <ActorViewFeature title="Eye Color" value={helper.findActorTag("EyeColor", props.actor.tags)} isArray={true}/>
                                            {props.actor.actor.measurement !== "U" ? <ActorViewFeature title="Measurement" value={props.actor.actor.measurement === null ? 'Unknown' : props.actor.actor.measurement} /> : <></>}
                                            <ActorViewFeature title="Weight" value={props.actor.actor.weight === null ? 'Unknown' : `${props.actor.actor.weight} lb`} />
                                            <ActorViewFeature title="Height" value={props.actor.actor.height === null ? 'Unknown' : props.actor.actor.height} />
                                        </Col>
                                        <Col>
                                            <ActorViewFeature title="Ethnicity" value={helper.findActorTag("Ethnicity", props.actor.tags)} isArray={true}/>
                                            <ActorViewFeature title="Skin Complexion" value={helper.findActorTag("SkinComplexion", props.actor.tags)} isArray={true}/>
                                            <ActorViewFeature title="Body Art" value={helper.findActorTag("BodyArt", props.actor.tags)} isArray={true}/>
                                            <ActorViewFeature title="Body Type" value={helper.findActorTag("BodyType", props.actor.tags)} isArray={true}/>
                                            {helper.findActorTag("Male", props.actor.tags)[0].name == 'Unknown' ? (<>
                                                <ActorViewFeature title="Tit Size" value={helper.findActorTag("TitSize", props.actor.tags)} isArray={true}/>
                                                <ActorViewFeature title="Tit Type" value={helper.findActorTag("TitType", props.actor.tags)} isArray={true}/>
                                                <ActorViewFeature title="Ass Type" value={helper.findActorTag("AssType", props.actor.tags)} isArray={true}/>
                                                <ActorViewFeature title="Pussy Type" value={helper.findActorTag("PussyType", props.actor.tags)} isArray={true}/>
                                            </>) : <></>}
                                        </Col>
                                    </Row>
                                    <Row><h4>About</h4></Row>
                                    <Row><p>{props.actor.actor.description}</p></Row>
                                </Col>
                                : <SpinnerLoad></SpinnerLoad>}
                        </Row>
                    </Container>
                    <Container style={{ maxWidth: `${window.innerWidth}px` }}>
                        <Row className="justify-content-center">
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <div className={`nav-link ${tab == 0 ? 'active' : ''}`}  onClick={() => changeTab(0)}>Scenes</div>
                                </li>
                                <li className="nav-item">
                                    <div className={`nav-link ${tab == 1 ? 'active' : ''}`}  onClick={() => changeTab(1)}>Gallery</div>
                                </li>
                                <li className="nav-item">
                                    <div className={`nav-link ${tab == 2 ? 'active' : ''}`}  onClick={() => changeTab(2)}>Unsorted</div>
                                </li>
    
                            </ul>
                        </Row>
                        {tab == 0? <>
                        {!props.isFetchingScenes ?
                            (<div>
                                <Row className="justify-content-center" style={{ paddingTop: "50px" }}>
                                    <h2>{props.sceneCount} Scenes</h2>
                                </Row>
                                <Row className="justify-content-center">
                                    <PaginationComponent
                                        baseUrl={`/actor/${props.match.params.id}?sort=${params.sort}&direction=${params.direction}&size=${params.size}&model=${params.model}&page=`}
                                        params={params} totalItem={props.sceneCount} paginate={paginate} />
                                </Row>
                                <Row className="justify-content-center">
                                    {sceneCardElement}
                                </Row></div>) : <SpinnerLoad></SpinnerLoad>}</>:
                                <ActorGallery></ActorGallery>}
                    </Container>
                </Container>
            </>
        )
    }
    else {
        return (<SpinnerLoad></SpinnerLoad>);
    }
}
const mapStateToProps = (state) => {
    return {
        perms: state.users.permissions.perms,
        isFetching: state.actors.actorInfo.isFetching,
        isFetchingScenes: state.actors.actorInfo.isFetchingScenes,
        actor: state.actors.actorInfo.actor,
        scenes: state.actors.actorInfo.scenes,
        sceneCount: state.actors.actorInfo.sceneCount,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getActor: (id) => dispatch(actorActions.getActorById(id)),
        getActorScenes: (size, page, site, sort, direction, tag, model) => dispatch(actorActions.getScenesByActor(size, page, site, sort, direction, tag, model))

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ActorView);
import axios from 'axios';
import constants from '../../const.js';
import { permActions } from './permActions.js';

const LOGIN_REQUEST = "LOGIN_REQUEST";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_FAILURE = "LOGIN_FAILURE";

const contentDomain = (domain) => {
    return {
        type: -1,
        domain: domain
    };
};

const loginRequest = () => {
    return {
        type: LOGIN_REQUEST
    };
};

const loginSuccess = (token, user) => {
    return {
        type: LOGIN_SUCCESS,
        token: token,
        user: user
    }
}

const loginFailure = (error) => {
    return {
        type: LOGIN_FAILURE,
        error
    }
}



const updateUserIp = () => {
    return dispatch => {
        return axios.get(`${constants.USER_API_URL}/users/ip`).then(res=>{
            var local_ip = res.data.ip;
            axios.get('https://api.ipify.org/')
            .then(res => {
                if (res.data === local_ip)
                    constants.CONTENT_DOMAIN = constants.CONTENT_DOMAIN;
                else
                    constants.CONTENT_DOMAIN = constants.CONTENT_DOMAIN;
                dispatch(contentDomain(res.data));
            });

        });
    };
};

const login = (email, password) => {
    return dispatch => {
        dispatch(loginRequest());
        return axios.post(constants.USER_API.LOGIN_URL, {
            email: email,
            password: password
        })
            .then(res => {
                localStorage.removeItem('token');
                localStorage.setItem('token', res.data.token)
                dispatch(loginSuccess(res.data.token, res.data));
                // dispatch(permActions.updateRole(res.data.role));
                dispatch(permActions.updatePermission(res.data.perm));
            })
            .catch(error => {
                dispatch(loginFailure(error))
            });
    };
};

const logout = () => {
    return dispatch => {
        localStorage.removeItem('token');
        dispatch(authenticate());
    };
};

const AUTH_REQ = "AUTH_REQ";
const AUTH_SUC = "AUTH_SUC";
const AUTH_ERR = "AUTH_ERR";

const auth_REQ = () => {
    return {
        type: AUTH_REQ
    }
}
const auth_SUC = (token, user) => {
    return {
        type: AUTH_SUC,
        token: token,
        user: user
    };
}
const auth_ERR = () => {
    return {
        type: AUTH_ERR
    }
}

const authenticate = () => {
    return dispatch => {
        dispatch(auth_REQ);
        dispatch(permActions.fetchRole());
        var localToken = localStorage.getItem('token');
        if (localToken === null) dispatch(auth_ERR());
        else
            dispatch(validateToken(localToken));
    }
}

const validateToken = (token) => {
    return dispatch => {

        return axios.get(constants.USER_API.AUTH_URL, {
            headers: { 'Authorization': `${token}` }
        }).then(
            res => {
                localStorage.removeItem('token');
                localStorage.setItem('token', res.data.token);
                // dispatch(permActions.updateRole(res.data.role));
                dispatch(permActions.updatePermission(res.data.perm));
                dispatch(auth_SUC(res.data.token, res.data));
            }
        ).catch(error => {
            dispatch(auth_ERR());
            localStorage.removeItem('token');
        });
    };
};

export const loginActions = {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    login,
    logout,
    AUTH_REQ,
    AUTH_SUC,
    AUTH_ERR,
    authenticate,
    updateUserIp
}
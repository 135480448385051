import React, { useEffect }  from 'react';
import { Link } from "react-router-dom";
import Pagination from 'react-bootstrap/Pagination'

function PaginationComponent(props){

    const pageNumbers =[];
    for(let i =1; i <= Math.ceil(props.totalItem/props.params.size); i++){
        pageNumbers.push(i);
    }
    useEffect(()=>{}, []);

    // eslint-disable-next-line
    return (
        <Pagination>
             <li className="page-item">
                <Link className="page-link" to={`${props.baseUrl}${pageNumbers[0]}`} onClick={(e)=>{ props.paginate(pageNumbers[0])}} aria-label="First">
                    <span aria-hidden="true">&laquo;</span>
                </Link>
            </li>
            <li className={`page-item ${parseInt(props.params.page) - 1 <= 0 ? "d-none": ""}`}>
                <Link className="page-link" to={`${props.baseUrl}${parseInt(props.params.page) - 1}`}
                onClick={(e)=>{ props.paginate(parseInt(props.params.page) - 1)}}>
                    <span aria-hidden="true">‹</span>
                </Link>
            </li>
            <Pagination>
                {pageNumbers.map(number => 
                (number>parseInt(props.params.page) - 4) &(number<=parseInt(props.params.page) + 3) ?
                (<li key={number} className={`page-item ${// eslint-disable-next-line
                    props.params.page == number? "active":"" }`}>
                        <Link to={`${props.baseUrl}${number}`} onClick={(e)=>{ props.paginate(number)}} className="page-link">{number}</Link>
                    </li>) :''
                )}
            </Pagination>
            <li className={`page-item ${parseInt(props.params.page) + 1 <= pageNumbers.length ? "": "d-none"}`}>
                <Link className="page-link" to={`${props.baseUrl}${parseInt(props.params.page) + 1}`}
                onClick={(e)=>{ props.paginate(parseInt(props.params.page) + 1)}}>
                    <span aria-hidden="true">›</span>
                </Link>
            </li>
            <li className="page-item">
                <Link className="page-link" to={`${props.baseUrl}${pageNumbers[pageNumbers.length-1]}`}
                onClick={(e)=>{ props.paginate(pageNumbers[pageNumbers.length-1])}} aria-label="Last">
                    <span aria-hidden="true">&raquo;</span>
                </Link>
            </li>
        </Pagination>
    );
}

export default PaginationComponent;
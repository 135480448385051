import { loginActions } from '../../actions/user/loginActions.js';
import { signupActions } from '../../actions/user/signupActions.js';

const INITIAL_STATE = {
    authorized: false,
    token:{},
    user: {
        username: 'username'
    },
    authenticate:{
        isFetching: false
    },
    login:{
        isFetching: false,
        error: false
    },
    signup:{
        isFetching: false,
        error: false,
        errorMsg: ''
    },
    error: ''
}

const userReducers = (state = INITIAL_STATE, action) =>{
    switch(action.type){
        case loginActions.LOGIN_REQUEST:
            return{
                ...state,
                login:{...state.login,
                    isFetching: true,
                    error: false
                },
                authorized: false,
            };
        case loginActions.LOGIN_SUCCESS:
            return {
                ...state,
                login:{...state.login,
                    isFetching: false,
                    error: false 
                },
                authorized: true,
                token: action.token,
                user: action.user
            };
        case loginActions.LOGIN_FAILURE:
            return{
                ...state,
                login:{...state.login,
                    isFetching: false,
                    error: true
                },
                authorized: false,
                error: action.error
            }

        case signupActions.SIGNUP_REQUEST:
            return{
                ...state,
                signup:{...state.signup,
                    isFetching: true,
                    error: false,
                },
                authorized: false
            };
        case signupActions.SIGNUP_SUCCESS:
            return{
                ...state,
                signup:{...state.signup,
                    isFetching: false,
                    error: false,
                },
                authorized: true,
                token: action.token,
                user: action.user
            };
        case signupActions.SIGNUP_FAILURE:
            return{
                ...state,
                signup:{...state.signup,
                    isFetching: false,
                    error: true,
                    errorMsg: action.error
                },
                authorized: false,
                error: action.error
            };

        case loginActions.AUTH_REQ:
            return{
                ...state,
                authenticate:{isFetching: true},
                authorized: false,
                token: '',
                user: {
                    username: 'username'
                }
            }; 
        case loginActions.AUTH_SUC:
            return{
                ...state,
                authenticate:{isFetching: false},
                authorized: true,
                token: action.token,
                user: action.user
            };
        case loginActions.AUTH_ERR:
            return{
                ...state,
                authenticate:{isFetching: false},
                authorized: false,
                token: '',
                user: {
                    username: 'username'
                }
            };      
        default:
            return state;
    }
};

export default userReducers;
import React, { useState, useEffect, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { Button } from 'react-bootstrap';

import "./Row.css";
import constants from '../../const.js';
import MovieInfo from './MovieInfo.js';
import { TvContext } from "../../context/TvContext";
import useWindowSize from '../common/useWindowSize';
import { helper } from '../common/helper.js';

function Card({ movie, openInfo }) {

    return <div style={{ width: "200px" }}>
        <img key={movie.CID}
            onClick={() => openInfo(movie)}
            className={`row__poster row__posterLarge`}
            src={`${constants.POSTER_BASE}${movie.info.poster_path}`}
            alt={movie.info.name} />
        <div>
            <h1 style={{ fontSize: "15px", textAlign: "center" }}>{movie.info.title}</h1>
            <h3 style={{ fontSize: "12px", textAlign: "center" }}>{movie.year}</h3>
        </div>
    </div>

}

function MovieRow({ scrollable, title, isLargeRow, data }) {

    const history = useHistory();
    const { tvState, setTvState } = useContext(TvContext);
    const [movies, setMovies] = useState([]);
    const [viewInfo, setViewInfo] = useState(false);
    const [selected, setSelected] = useState({});
    const [movieCards, setMovieCards] = useState([]);

    const [yearSort, setYearSort] = useState("New");
    const [titleSort, setTitleSort] = useState("Alphabetical ASC");
    const [movSort, setMovSort] = useState("New to 66Movies");


    const size = useWindowSize();
    const WIDTH = 200;

    useEffect(() => {
        setMovies(data);
    }, [])

    useEffect(() => {
        if (scrollable) renderLatest()
        else renderCards();
    }, [movies, yearSort, titleSort, movSort])

    const openInfo = (data) => {
        setSelected(data);

        setTvState({
            ...tvState,
            movie: {
                ...tvState.show,
                selectedMovie: data.slug,
                selectedLocation: data.location
            }
        })
        setViewInfo(true);
    };

    const closeInfo = () => {
        setViewInfo(false);
        setTvState({
            ...tvState,
            show: {
                ...tvState.show,
                showSeason: false,
                selectedMovie: {}
            }
        });
    }

    const renderCards = () => {
        var movieRow = [];
        if (movies.length > 0) {
            var i = 0;
            var colSize = (size.width - 250) / WIDTH;
            for (var col = 0; col < Math.ceil(movies.length / colSize); col++) {
                var movie = [];
                for (var row = 0; row < colSize; row++) {
                    if (i < movies.length) {
                        movie.push(<Card movie={movies[i]} openInfo={openInfo} key={i} />);
                        i++;
                    }
                }
                movieRow.push(<div className="row__posters" key={col}>{movie}</div>);
            }
            setMovieCards(movieRow);
        }
    }

    const renderLatest = () => {
        var movie = [];
        if (movies.length > 0) {
            var movs = movies;
            for (var i = 0; i < 6; i++) {
                movie.push(<Card movie={movs[i]} openInfo={openInfo} key={`a-${i}`} />)
            }
            setMovieCards(<div className="row__posters" key={"00"}>{movie}</div>)
        }
    }
    const sortByAdded = () => {
        var tmp = data;
        if (movSort == "New to 66Movies") {
            tmp.sort(helper.sortObjects("addedDate", "asc"))
            setMovies(tmp);
            setMovSort("Oldest to 66Movies");
        }
        else {
            tmp.sort(helper.sortObjects("addedDate", "desc"))
            setMovies(tmp);
            setMovSort("New to 66Movies");
        }
        console.log(movies)
    }

    const sortByYear = () => {
        var tmp = data;
        if (yearSort == "New") {
            tmp.sort(helper.sortObjects("releaseDate", "asc"))
            setMovies(tmp);
            setYearSort("Oldest");
        }
        else {
            tmp.sort(helper.sortObjects("releaseDate", "desc"))
            setMovies(tmp);
            setYearSort("New");
        }
    }

    const sortByTitle = () => {
        var tmp = data;
        if (titleSort == "Alphabetical ASC") {
            tmp.sort(helper.sortObjects("title", "desc"))
            setMovies(tmp);
            setTitleSort("Alphabetical DESC")
        }
        else {
            tmp.sort(helper.sortObjects("title", "asc"))
            setMovies(tmp);
            setTitleSort("Alphabetical ASC")
        }
    }

    return (
        <div className="row_show">
            <h2>{title}  &nbsp;
                {!scrollable ? <Button variant="secondary" size="sm" onClick={sortByAdded} id="66Movies">{movSort}</Button> : null}
                &nbsp;
                {!scrollable ? <Button variant="secondary" size="sm" onClick={sortByYear} id="recent">{yearSort}</Button> : null}
                &nbsp;
                {!scrollable ? <Button variant="secondary" size="sm" onClick={sortByTitle}>{titleSort}</Button> : null}
            </h2>
            {movies.length > 0 ? movieCards : null}
            {viewInfo ? <MovieInfo show={viewInfo} close={closeInfo} data={selected} /> : <></>}
        </div>
    );
}

export default MovieRow;

const simpleRequest = (request) => {
    return {
        type: request
    };
};

const simpleSuccess = (request_success) => {
    return {
        type: request_success,
    };
};

const simpleFailure = (request, error) => {
    return {
        type: request,
        error
    }
};


export const commonReq ={
    simpleRequest,
    simpleSuccess,
    simpleFailure
}
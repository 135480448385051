
const tagQuery= (selectedTag, param) =>{
    var qry="";
    if(selectedTag==null)
        return "?";
    if(selectedTag.length === 0)
        return "?";
    for(var i=0; i < selectedTag.length; i++){
        if(i === 0){
            qry=`?${param}=${selectedTag[i]}`;
        }
        else{
            qry=`${qry}&${param}=${selectedTag[i]}`;
        }
    }
    return qry;
}

const query= (selectedTag, param) =>{
    var qry="";
    if(selectedTag==null || selectedTag.length === 0)
        return "";
    else{
        for(var i=0; i < selectedTag.length; i++)
            qry=`${qry}&${param}=${selectedTag[i]}`;
        return qry;
    }
}

const findActorTag = (tagType, arr) =>{
    var tag = [];
    if(Array.isArray(arr) && arr.length !==1 && arr[0] !== null){
    for (var i = 0; i < arr.length; i++) {
        if (arr[i].tagType === tagType) tag.push(arr[i]);
    }
    if (tag.length == 0){
        tag.push({actorId: 0,
            name: "Unknown",
            tagId: 0,
            tagType: "Unknown"})
    }}
    else{
        return [{actorId: 0,
            name: "Unknown",
            tagId: 0,
            tagType: "Unknown"}]
    }
    return tag;
}

const findTag = (type, tagType, arr) => {
    var tag = [];
    for (var i = 0; i < arr.length; i++) {
        if (arr[i][type] === tagType) tag.push(arr[i]);
    }
    return tag;
}

const sortObjects = (property, order)=> {
    return function (a, b) {
        if(a[property] === null){
            return 1;
        }
        else if(b[property] === null){
            return -1;
        }
        if (a[property] < b[property]) {
            if (order == "asc")
                return -1;
            return 1;
        }
        if (a[property] > b[property]) {
            if (order == "asc")
                return 1;
            return -1;
        }
        return 0;
    }
}

const sortSubObjects = (property, sub, order)=> {
    return function (a, b) {
        if(a[property][sub] === null){
            return 1;
        }
        else if(b[property][sub] === null){
            return -1;
        }
        if (a[property][sub] < b[property][sub]) {
            if (order == "asc")
                return -1;
            return 1;
        }
        if (a[property][sub] > b[property][sub]) {
            if (order == "asc")
                return 1;
            return -1;
        }
        return 0;
    }
}

const pad = (n, width, z) => {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

const isAFile = (path) =>{
    var lastFive = path.substr(path.length-5)
    return lastFive.includes(".")
}

const  loadContentImg = (images, imageURL) =>{
    for (var i = 0; i < images.length; i++){ 
        if (isAFile(images[i].key)) {
            images[i] = loadImage(images[i], imageURL)
        }
        else{
            images[i].isFile = false
        }
    }
    return images
}

const loadImage = (image, imageURL) =>{
    const img = new Image();
    image.src = imageURL(image.key);
    image.isFile = true;

    img.onload = () =>{
        image.height = img.height;
        image.width = img.width;
        image.src = img.src;
        image.isFile = true;
    };
    img.onerror = (err) => {
        console.log("img error");
        console.error(err);
      };
    return image
}

const loadImageURL = (image, imageURL) =>{
    image.src = imageURL(image.key);
    image.isFile = true;
    return image
}

const loadChildren = (children, imageURL) =>{
    Object.entries(children).forEach(([k,v])=>{
        loadContent(children[k].contents, imageURL)
        if(children[k].children != {}){
            loadChildren(children[k].children, imageURL)
        }
    })
    return children
}

const  loadContent = (images, imageURL) =>{
    for (var i = 0; i < images.length; i++){ 
        if (isAFile(images[i].key)) {
            images[i] = loadImageURL(images[i], imageURL)
        }
        else{
            images[i].isFile = false
        }
    }
    return images
}

const  loadGalleryTree =  (fileTree, imageURL) =>{
    loadContent(fileTree.contents, imageURL)
    loadChildren(fileTree.children, imageURL)
}

export const helper = {
    tagQuery,
    query,
    findTag,
    findActorTag,
    sortObjects,
    sortSubObjects, 
    pad, 
    loadImage,
    loadImageURL,
    loadContentImg,
    loadContent,
    loadGalleryTree
};
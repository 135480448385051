import React, { useRef} from 'react';
import { connect } from 'react-redux';
import Session from '../chat/Session.js';
import Banner from './Banner.js';
import ContentRequest from './ContentRequest.js';
import ShowRow from './ShowRow.js';
import './Shows.css';

function Shows(props) {

    const lib = useRef(null);

    return (
        <div>
            <ContentRequest/>
            {/* <Session player={false} video={null}/> */}
            <div ref={lib} className="shows fill-screen">
                {props.authorized ? <Banner /> : null}
                <ShowRow title="Shows" isLargeRow />
            </div>
        </div>
    );
}

const mapStateToProps = function (state) {
    return {
        authorized: state.users.user.authorized,
    }
}

export default connect(mapStateToProps)(Shows);
import axios from 'axios';
import constants from '../../const.js';

const ACTOR_REQ = "SCENE_ACTOR_REQ";
const ACTOR_SUC = "SCENE_ACTOR_SUC";
const ALL_ACTOR_SUC = "ALL_SCENE_ACTOR_SUC";
const ACTOR_ERR = "SCENE_ACTOR_ERR";

const actor_REQ = () =>{
    return{
        type: ACTOR_REQ
    };
};
const actor_SUC = (actors) =>{
    return{
        type: ACTOR_SUC,
        actors: actors
    };
}
const allActor_SUC = (allActor) =>{
    return{
        type: ALL_ACTOR_SUC,
        allActor: allActor
    };
}
const actor_ERR = (error) => {
    return{
        type: ACTOR_ERR,
        error: error
    };
};
const getAllActor = () => {
    return dispatch =>{
        dispatch(actor_REQ());
        return axios.get(`${constants.DATA1_API_URL}/actors`)
        .then(res =>dispatch(allActor_SUC(res.data)))
        .catch(error=> dispatch(actor_ERR(error)))
    }
}
const getActors = (sceneId) => {
    return dispatch =>{
        dispatch(actor_REQ());
        return axios.get(`${constants.DATA1_API_URL}/scene/${sceneId}/actors`)
        .then(res =>dispatch(actor_SUC(res.data)))
        .catch(error=> dispatch(actor_ERR(error)))
    }
}

const updateActor = (sceneId, actors) =>{
    return dispatch =>{
        dispatch(actor_REQ());
        var params='';
        actors.forEach(a => params = `${params}&actor[]=${a.id}`);
        return axios.post(`${constants.DATA1_API_URL}/scene/${sceneId}/actors?${params}`,{},{
            headers:{ 'Authorization' : `${localStorage.getItem('token')}` }
        }).then(
            dispatch(updateSceneFile(sceneId))
        )
        .catch(err=>dispatch(actor_ERR(err)));
    };
};

const updateSceneFile = (id) =>{
    return distpatch =>{
        var token = localStorage.getItem('token').slice(7);
        return  axios.get(`${constants.CONTENT_DOMAIN}update/scene/${id}?token=${token}`)
    }
}

export const actorActions = {
    ACTOR_REQ,
    ACTOR_SUC,
    ALL_ACTOR_SUC,
    ACTOR_ERR,
    getAllActor,
    getActors,
    updateActor
};

import React from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";

function ImageAlbumCover(props) {
    var sizes = {
        'large': {width: '386px', height: '520px'},
        'medium' : {width: '200px', height: '300px'}
    }

	return (
		<Card className="m-2 d-flex" style={{ width: sizes[props.size].width}}>
            <Link to={props.link} style={{ color: "black", textDecoration: "none" }}>
                    <img
                        src={props.src}
                        className="shadow-1-strong rounded mb-3"
                        alt={props.alt}
                        style={sizes[props.size]}
                    />
            </Link>
            <Link to={props.link} style={{ color: "black", textDecoration: "none" }}>
                <div className="card-title h5 ml-2">{props.gal}</div>
            </Link>
		</Card>
	);
}

export default ImageAlbumCover;

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Col, InputGroup, Modal, Button, Table } from 'react-bootstrap';

import { sceneActions } from '../../actions/scene/sceneActions';
import { actorActions } from '../../actions/actor/actorActions';
import constants from '../../const';
import { helper } from '../common/helper.js'
import UploadTagImage from '../common/upload-tag-image';


function NewSceneTag(props) {

    var isMobile = window.innerWidth <= constants.mobileSize;
    const [showTagImage, setShowTagImage] = useState(false);
    const [tagImg, setTagImg] = useState("")
    
    const [showTagList, setShowTagList] = useState(false);
    const [showTagEditor, setShowTagEditor] = useState(false);
    const [colors, setColors] = useState([]);
    const [tags, setTags] = useState([]);
    const [table, setTable] = useState(null);
    const [order, setOrder] = useState("asc");
    // const [isVisible, setIsVisble] = useState(false);
    const [tagMode, setTagMode] = useState("Edit");
    const [tagInfo, setTagInfo] = useState({
        name: ''
    });

    var token = `?token=${localStorage.getItem('token').slice(7)}`;

    const bodyLocation = {
        "Accessories": 9,
        "Bottom": 2,
        "Face": 4,
        "Feet": 5,
        "Hand": 7,
        "Head": 3,
        "Leg": 8,
        "NA": 10,
        "OnePiece": 6,
        "Top": 1,
    };
    const category = {

        "Location": 1,
        "Movie": 6,
        "Object": 4,
        "Outfit": 3,
        "Role": 2,
        "Scene": 5
    }
    
    const showTagFunc = (tag)=>{
        setShowTagImage(true);
        setTagImg(`${constants.CONTENT_DOMAIN}download/sus-media/tags/${tag}.webp${token}`)

    }

    const unshowTagFunc = () =>{
        setShowTagImage(false);
    }

    const refresh = () => {
        props.getAllColors().then((data)=>{
            setColors(data.colors)
        })
        props.getAllTags().then((data) => {
            setTags(data.tags)
            var tab = <tbody>
                {data.tags.map(tag => (
                    <tr key={tag.id}>
                        <td onClick={() => openModal(tag)}>{tag.id}</td>
                        <td onClick={() => openModal(tag)}>{tag.name}</td>
                        <td onClick={() => openModal(tag)}>{tag.category}</td>
                        <td onClick={() => openModal(tag)}>{tag.bodyLocation}</td>
                        <td onClick={() => openModal(tag)}>{tag.color}</td>
                        <td onClick={() => openModal(tag)}>{tag.model}</td>
                    </tr>
                ))}
            </tbody>
            setTable(tab)
        });
    }
    useEffect(() => {
        if(showTagList)
            refresh()
        // eslint-disable-next-line
    }, [showTagList]);

    const handleCloseTag = () => {
        setShowTagList(false);
        setShowTagEditor(false);
    }

    const handleCancel = () => {
        setShowTagEditor(false)
        setTagInfo({});
    }

    const handleShow = () => {
        setShowTagList(true)
    };

    const updateTag = () => {
        if (tagMode == "Edit") {
            props.updateSceneTags(tagInfo.id, { name: tagInfo.name, category: tagInfo.category, bodyLocation: tagInfo.bodyLocation, color: tagInfo.color, model: parseInt(tagInfo.model) })
        }
        else {
            props.createSceneTags({ name: tagInfo.name, category: tagInfo.category, bodyLocation: tagInfo.bodyLocation, color: tagInfo.color, model: parseInt(tagInfo.model) })
        }
        setShowTagEditor(false)
        refresh()

    }

    const openModal = (tag) => {
        setTagMode("Edit")
        tag.category = category[tag.category]
        tag.bodyLocation = bodyLocation[tag.bodyLocation]
        setTagInfo(tag)
        setShowTagEditor(true)
    }

    const sortTags = (key) => {
        var tmpArr = tags;
        if (order == "asc") {
            setOrder("desc")
        }
        else { setOrder("asc") }
        var arr = tmpArr.sort(helper.sortObjects(key, order));
        setTags(arr);
        var tab = <tbody>
            {arr.map(tag => (
                <tr key={tag.id} onMouseEnter={() => showTagFunc(tag.id)} onMouseLeave={()=>unshowTagFunc()} >
                    <td onClick={() => openModal(tag)}>{tag.id}</td>
                    <td onClick={() => openModal(tag)}>{tag.name}</td>
                    <td onClick={() => openModal(tag)}>{tag.category}</td>
                    <td onClick={() => openModal(tag)}>{tag.bodyLocation}</td>
                    <td onClick={() => openModal(tag)}>{tag.color_name}</td>
                    <td onClick={() => openModal(tag)}>{tag.model_name}</td>
                </tr>
            ))}
        </tbody>
        setTable(tab)
    }

    const addTag = () => {
        setTagMode("Add");
        setShowTagEditor(true)
    }

    var categorySelect = React.forwardRef(() => (
        <select className="custom-select custom-select-sm" value={tagInfo.category} onChange={(e) => setTagInfo({ ...tagInfo, category: parseInt(e.target.value) })} data-live-search="true">
            <option defaultValue={"DEFAULT"}>Select Category</option>
            {Object.keys(category).map(cat => <option key={cat} value={category[cat]}>{cat}</option>)}
        </select>));

    var bodySelect = React.forwardRef(() => (
        <select className="custom-select custom-select-sm" value={tagInfo.bodyLocation} onChange={(e) => setTagInfo({ ...tagInfo, bodyLocation: parseInt(e.target.value) })} data-live-search="true">
            <option defaultValue={"DEFAULT"}>Select Category</option>
            {Object.keys(bodyLocation).map(cat => <option key={cat} value={bodyLocation[cat]}>{cat}</option>)}
        </select>));

    var colorSelect = React.forwardRef(() => (
        <select className="custom-select custom-select-sm" value={tagInfo.color} onChange={(e) => setTagInfo({ ...tagInfo, color: parseInt(e.target.value) })} data-live-search="true">
            <option defaultValue={"DEFAULT"}>Select Color</option>
            {colors.map(color => <option key={color} value={color.id}>{color.color_name}</option>)}
        </select>));

    var ownerSelect = React.forwardRef(() => (
        <select className="custom-select custom-select-sm" value={tagInfo.model} onChange={(e) => setTagInfo({ ...tagInfo, model: parseInt(e.target.value) })} data-live-search="true">
            <option defaultValue={"DEFAULT"}>Select Owner</option>
            {props.allActor.map(actor => <option key={actor.id} value={parseInt(actor.id)}>{actor.name}</option>)}
        </select>));

    return <>
        <Button variant="info" size={`${isMobile ? 'sm' : 'md'}`} onClick={handleShow}>Tag</Button>
        <Modal size="lg" show={showTagList} onHide={e => setShowTagList(false)} style={{ backgroundColor: 'hsla(0, 0%, 0%, 0.9)' }}>   
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>View Tags</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                
                    <Form.Row>
                        <Form.Group as={Col} xs="3" md="4">
                            <Button className="mr-auto" variant="secondary" size={`${isMobile ? 'sm' : 'md'}`} onClick={addTag}>Add Tag</Button>
                        </Form.Group>
                        <Form.Group as={Col} xs="3" md="4" className="d-flex justify-content-center">
                            <Button variant="secondary" size={`${isMobile ? 'sm' : 'md'}`} onClick={()=>refresh()}>Refresh</Button>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row >

                        <Table striped bordered hover size="sm">
                        {showTagImage ?
                <div style={{ position: "fixed", display: "block", zIndex: '200', left: `40%`, top: `5%` }}>
                    <img src={tagImg} alt="Actor" style={{ width: "200px", height: "200px" }}></img>
                </div> : null}
                            <thead>
                                <tr>
                                    <th onClick={() => sortTags("id")}>#</th>
                                    <th onClick={() => sortTags("name")}>Name</th>
                                    <th onClick={() => sortTags("category")}>Category</th>
                                    <th onClick={() => sortTags("bodyLocation")}>Body Location</th>
                                    <th onClick={() => sortTags("color_name")}>Color</th>
                                    <th onClick={() => sortTags("model_name")}>Owner</th>
                                </tr>
                            </thead>
                            <tbody>
            {tags.map(tag => (
                <tr key={tag.id} onMouseEnter={() => showTagFunc(tag.id)} onMouseLeave={()=>unshowTagFunc()}>
                    <td onClick={() => openModal(tag)}>{tag.id}</td>
                    <td onClick={() => openModal(tag)}>{tag.name}</td>
                    <td onClick={() => openModal(tag)}>{tag.category}</td>
                    <td onClick={() => openModal(tag)}>{tag.bodyLocation}</td>
                    <td onClick={() => openModal(tag)}>{tag.color_name}</td>
                    <td onClick={() => openModal(tag)}>{tag.model_name}</td>
                </tr>
            ))}
        </tbody>
                            {/* {table} */}
                           
                        </Table>

                    </Form.Row>
                </Modal.Body>
            </Form>
            <Modal size="md" show={showTagList && showTagEditor} onHide={handleCancel} style={{ backgroundColor: 'hsla(0, 0%, 0%, 0.9)' }}>
                <Modal.Header closeButton>
                    <Modal.Title>{tagMode} Tags</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row>
                        <Form.Group as={Col} controlId="validationTitle">
                            <Form.Label srOnly>Title</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Title</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control required type="text" placeholder="Title" defaultValue={tagInfo.name} onChange={(e) => setTagInfo({ ...tagInfo, name: e.target.value })} />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Please choose a valid Title</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="validationCategory">
                            <Form.Label srOnly>Category</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Category</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control as={categorySelect} required custom />
                            </InputGroup>
                        </Form.Group>
                        </Form.Row>
                        {tagInfo.category == 3 ? 
                        <Form.Row>
                        <Form.Group as={Col} controlId="validationBody">
                            <Form.Label srOnly>Body</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Body</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control as={bodySelect} required custom />
                            </InputGroup>
                        </Form.Group> 
                        </Form.Row>: null}
                        {tagInfo.category == 3 ? 
                        <Form.Row>
                        <Form.Group as={Col} controlId="validationBody">
                            <Form.Label srOnly>Color</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Color</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control as={colorSelect} required custom />
                            </InputGroup>
                        </Form.Group> 
                        </Form.Row>: null}
                        {tagInfo.category == 3 ? 
                        <Form.Row>
                        <Form.Group as={Col} controlId="validationBody">
                            <Form.Label srOnly>Owner</Form.Label>
                            <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Owner</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control as={ownerSelect} required custom />
                            </InputGroup>
                        </Form.Group> 
                        </Form.Row>: null}
                        <Form.Row>
                            <UploadTagImage tagId={tagInfo.id} />
                        </Form.Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={updateTag}>Save</Button>
                </Modal.Footer>
            </Modal>
        </Modal>
    </>;
}

const mapStateToProps = (state) => {
    return {
        allTag: state.sceneReducers.tags,
        allColors: state.sceneReducers.colors,
        allActor: state.scenes.actor.allActor,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllColors: () =>dispatch(sceneActions.getColors()),
        getAllTags: () => dispatch(sceneActions.getSceneTags()),
        getAllActor: () => dispatch(actorActions.getAllActor()),
        updateSceneTags: (id, body) => dispatch(sceneActions.updateSceneTags(id, body)),
        createSceneTags: (body) => dispatch(sceneActions.createSceneTags(body))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewSceneTag);